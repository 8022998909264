import React from 'react';

import ajax from "../../data/ajax"
import Loading from "../components/Loading";

class TrafficSources extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
            items: []
        }
    }

    componentDidMount() {
        // Promise.all([
        //     fetch("http://localhost:3000/items/get1"),
        //     fetch("http://localhost:3000/items/get2"),
        //     fetch("http://localhost:3000/items/get3")
        // ]).then(allResponses => {
        //     const response1 = allResponses[0]
        //     const response2 = allResponses[1]
        //     const response3 = allResponses[2]
        //
        // ...
        //
        // })

        const url = window.trackerBaseUrl + "traffic-sources"; //?fields=id,name&expand=groupName"

        fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            // method: 'POST', // *GET, POST, PUT, DELETE, etc.
            // mode: 'cors', // no-cors, *cors, same-origin
            // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: 'same-origin', // include, *same-origin, omit
            headers: ajax.getHeaders(),
            // redirect: 'follow', // manual, *follow, error
            // referrerPolicy: 'no-referrer', // no-referrer, *client
            // body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
            .then(res => res.json())
            .then(
                result => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    })
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                error => {
                    this.setState({
                        isLoaded: true,
                        error
                    })
                }
            )
    }

    render() {
        const {error, isLoaded, items} = this.state
        if (error) {
            return <div>Ошибка: {error.message}</div>
        } else if (!isLoaded) {
            return <Loading />
        } else {
            return (
                <div className="container-fluid p-0">
                    <a href="/traffic-sources/add" className="btn btn-primary float-right mt-n1">
                        <i className="fas fa-plus"></i> New Traffic Source
                    </a>

                    <h1 className="h3 mb-3">Traffic Sources</h1>

                    <div className="card">
                        <div className="card-body">
                            <table id="datatables-column-search-text-inputs" className="table table-striped">
                                   {/*style="width:100%">*/}
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Comments</th>
                                    <th>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {items.map(item => (
                                    <tr>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>Type ????</td>
                                        <td>{item.description}</td>
                                        <td> ??
                                            <span className="badge badge-success">Active</span>
                                            <span className="badge badge-danger">Stopped</span>
                                            <span className="badge badge-warning">Waiting</span>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default TrafficSources;
