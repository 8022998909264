import React, {useState} from 'react';
import {Link} from "react-router-dom";

import {useInput} from "../../components/input-hook";
import GuestContainer from "../components/GuestContainer";
import ButtonSubmit from "../components/ButtonSubmit";
import {consoleLogError, consoleLogResponse} from "../../components/development";
import TokenStorage from "../../components/TokenStorage";

export default function Login() {
    const {value: email, bind: bindEmail} = useInput('');
    const {value: password, bind: bindPassword} = useInput('');

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true)

        const url = window.trackerBaseUrl + "auth";
        fetch(url, {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                password: password,
            })
        })
            .then(res => res.json())
            .then(
                result => {
                    consoleLogResponse(result)
                    if (result.token) {
                        TokenStorage.set(result.token)
                        document.location.reload();
                    } else {
                        alert("wrong auth")
                    }
                    setIsLoading(false);
                },
                err => {
                    consoleLogError(err)
                    setError(err)
                    consoleLogError(error)
                    setIsLoading(false);
                }
            )
    }

    const header = process.env.REACT_APP_TYPE === "lss"
        ? <>Sign in to your account to continue or <Link to='/signup'>register</Link></>
        : <></>;

    return (
        <GuestContainer header={header}>
            <>
                {
                    false && process.env.REACT_APP_TYPE === "cabinet" &&
                    <div className="text-center">
                        <img src="img/avatars/avatar.jpg" alt="Charles Hall"
                             className="img-fluid rounded-circle" width="132" height="132"/>
                    </div>
                }

                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="inputLoginEmail">Email</label>
                        <input
                            id="inputLoginEmail"
                            type="email"
                            className="form-control form-control-lg"
                            placeholder="Enter your email"
                            {...bindEmail}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputLoginPassword">Password</label>
                        <input
                            id="inputLoginPassword"
                            type="password"
                            className="form-control form-control-lg"
                            placeholder="Enter your password"
                            {...bindPassword}
                        />

                        {
                            false && process.env.REACT_APP_TYPE === "cabinet" &&
                            <small>
                                <a href="pages-reset-password.html">Forgot password?</a>
                            </small>
                        }
                    </div>

                    {
                        false && process.env.REACT_APP_TYPE === "cabinet" &&
                        <div>
                            <div className="custom-control custom-checkbox align-items-center">
                                <input type="checkbox" className="custom-control-input"
                                       value="remember-me" name="remember-me" checked/>
                                <label className="custom-control-label text-small">Remember
                                    me next time</label>
                            </div>
                        </div>
                    }

                    <div className="text-center mt-3">
                        <ButtonSubmit isLoading={isLoading} className="btn-lg btn-primary" text="Sign in"/>
                    </div>
                </form>
            </>
        </GuestContainer>
    )
}
