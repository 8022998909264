import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import axios from "axios";
import RangeSlider from "react-bootstrap-range-slider";

import Container from "../../components/Container";
import Card from "../../components/Сard";
import ajax from "../../../data/ajax";
import {SelectDataFormatter, UrlCreator} from "../../../components/select_data";
import {isDebug, Dump, consoleLog, consoleLogResponse} from "../../../components/development";
import LoadingWrapper from "../../components/LoadingWrapper";
import AlertSuccess from "../../components/AlertSuccess";
import ButtonSubmit from "../../components/ButtonSubmit";
import {useGetRequest, usePutRequest} from "../../../data/RequestData";
import SelectSingle from "../../components/select/SelectSingle";
import {useCheckboxInput, useInput, useSelect} from "../../../components/input-hook";
import PreviewTable from "./PreviewTable";
import {round} from "../../../components/Math";
import {CustomSettingsTemplate, MaxNegativeMargin, PhpToJsonInfo, PostbackPlaceholdersInfo} from "./elements";
import DivError from "../../components/form/DivError";
import JsonValidate from "../../components/JsonValidate";

const PostbackSetupCustomFormEdit = ({user}) => {
    let {id} = useParams();

    const [minMarginPercentage, setMinMarginPercentage] = useState(0);

    const [{data, isLoading, error}] = useGetRequest('ex-stat/zone-conversion/' + id);
    const [{
        data: dataUpdate,
        isLoading: isLoadingUpdate,
        error: errorUpdate,
        dataErrors
    }, setRequestData] = usePutRequest('ex-stat/zone-conversion/' + id);

    const inputAccountId = useSelect('');
    const inputPostbackUrl = useInput('');
    const inputName = useInput('');
    const inputForwardPercentage = useInput('50');
    const inputMarginPercentage = useInput('50');
    const inputPrecision = useInput('-1');
    const inputRates = useInput('');
    const inputMinValue = useInput('');
    const inputCustomSettings = useInput('');
    const inputNegativeMargin = useCheckboxInput(false);

    const setFromResponse = (result) => {
        consoleLog(result);

        if (result.margin_percentage < 0) {
            inputNegativeMargin.setValue(true);
        }
        inputName.setValue(result.name ? result.name : '')
        inputForwardPercentage.setValue(result.forward_percentage ? result.forward_percentage : 0)
        inputMarginPercentage.setValue(result.margin_percentage ? result.margin_percentage : 0)
        inputAccountId.setValue(result.account_id ? result.account_id + '' : '')
        inputPostbackUrl.setValue(result.url ? result.url : '')
        inputPrecision.setValue(result.precision ? result.precision : -1)
        inputRates.setValue(result.rate_table ? JSON.stringify(result.rate_table) : '')
        inputMinValue.setValue(result.min_value ? result.min_value : '')
        inputCustomSettings.setValue(result.custom_settings ? JSON.stringify(result.custom_settings) : '')
    }

    useEffect(() => {
        if (inputAccountId.value === null) {
            inputAccountId.setValue('');
        }
    }, [inputAccountId.value]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            const promises = [];
            Promise.all(promises).then(function (results) {
                consoleLogResponse(results);

                inputAccountId.setOptions(SelectDataFormatter.accountsForPostback(user.getTrafficSourceAccounts()));
            });

            setFromResponse(data);
        }
    }, [data]);


    useEffect(() => {
        if (dataUpdate) {
            setFromResponse(dataUpdate);
            window.scrollTo(0, 0);
        }
    }, [dataUpdate]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            zone_id: null,
            name: inputName.value,
            forward_percentage: parseInt(inputForwardPercentage.value),
            margin_percentage: parseInt(inputMarginPercentage.value),
            account_id: inputAccountId.value + '',
            url: inputAccountId.value === '' ? inputPostbackUrl.value : '',
            precision: inputPrecision.value < 0 ? null : parseInt(inputPrecision.value),
            rate_table: inputRates.value === '' ? null : JSON.parse(inputRates.value),
            min_value: inputMinValue.value === '' || Number(inputMinValue.value) === 0 ? null : inputMinValue.value.replace(/,/g, '.'),
            custom_settings: inputCustomSettings.value === '' ? null : JSON.parse(inputCustomSettings.value),
        };
        setRequestData(data);
    }

    useEffect(() => {
        setMinMarginPercentage(inputNegativeMargin.value ? MaxNegativeMargin : 0);
        if (!inputNegativeMargin.value && (inputMarginPercentage.value + 0 < 0)) {
            inputMarginPercentage.setValue('0');
        }
    }, [inputNegativeMargin.value]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container title={"Edit custom conversion setup"}>
            <Card>
                <LoadingWrapper isLoaded={!isLoading} error={error}>
                    <form onSubmit={handleSubmit}>
                        {
                            dataUpdate && <AlertSuccess message="Data updated successfully"/>
                        }

                        <div className="form-group">
                            <label htmlFor="inputName">Name</label>
                            <input
                                type="text"
                                id="inputName"
                                placeholder="Name"
                                className={"form-control " + (inputName.error.length > 0 ? "is-invalid" : '')}
                                {...inputName.bind}
                            />
                            <DivError error={inputName.error}/>
                        </div>

                        <div className="form-group">
                            <label>Advertiser account</label>
                            <SelectSingle {...inputAccountId.bind} />
                            <DivError error={inputAccountId.error}/>
                        </div>

                        {inputAccountId.value === '' && <div className="form-group">
                            <label htmlFor="inputPostbackUrl">Postback Url</label>
                            <input
                                type="text"
                                id="inputPostbackUrl"
                                placeholder="Postback url"
                                className={"form-control " + (inputPostbackUrl.error.length > 0 ? "is-invalid" : '')}
                                {...inputPostbackUrl.bind}
                            />
                            <PostbackPlaceholdersInfo url={inputPostbackUrl.value}/>
                            <DivError error={inputPostbackUrl.error}/>
                        </div>}

                        <div className="form-group">
                            <label>Round precision</label>
                            <RangeSlider
                                size='lg'
                                // tooltipLabel={currentValue => `${currentValue}%`}
                                tooltipLabel={currentValue => {
                                    const v = 0.55555555555;
                                    if (currentValue === -1) {
                                        return v;
                                    }
                                    return 'round(' + v + ', ' + currentValue + ') = ' + round(v, currentValue)
                                }}
                                tooltip='on'
                                min={-1}
                                max={8}
                                value={inputPrecision.value}
                                onChange={changeEvent => inputPrecision.setValue(changeEvent.target.value)}
                            />
                            <DivError error={inputPrecision.error}/>
                        </div>

                        <div className="form-group">
                            <label>Forward Conversion Percentage</label>
                            <RangeSlider
                                size='lg'
                                tooltipLabel={currentValue => `${currentValue}%`}
                                tooltip='on'
                                min={0}
                                max={100}
                                value={inputForwardPercentage.value}
                                onChange={changeEvent => inputForwardPercentage.setValue(changeEvent.target.value)}
                            />
                            <DivError error={inputForwardPercentage.error}/>
                        </div>

                        <div className="form-group">
                            <label>Margin Percentage</label>
                            <div>
                                <label className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" {...inputNegativeMargin.bind} />
                                    <span className="custom-control-label">I want to pay more than I get!</span>
                                </label>
                            </div>
                            <RangeSlider
                                size='lg'
                                tooltipLabel={currentValue => `${currentValue}%`}
                                tooltip='on'
                                min={minMarginPercentage}
                                max={100}
                                value={inputMarginPercentage.value}
                                onChange={changeEvent => inputMarginPercentage.setValue(changeEvent.target.value)}
                                variant={inputMarginPercentage.value < 0 ? 'warning' : 'primary'}
                            />
                            <DivError error={inputMarginPercentage.error}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="inputRates">Rates</label>
                            <textarea
                                id="inputRates"
                                placeholder="Rates"
                                className={"form-control " + (inputRates.error.length > 0 ? "is-invalid" : '')}
                                {...inputRates.bind}>
                            </textarea>
                            <PhpToJsonInfo/>
                            <JsonValidate value={inputRates.value} />
                            <DivError error={inputRates.error}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="inputMinValue">Min postback value</label>
                            <input
                                type="text"
                                id="inputMinValue"
                                placeholder="min postback value"
                                className={"form-control " + (inputMinValue.error.length > 0 ? "is-invalid" : '')}
                                {...inputMinValue.bind}
                            />
                            <DivError error={inputMinValue.error}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="inputCustomSettings">Custom Settings</label>
                            <textarea
                                id="inputCustomSettings"
                                placeholder="Custom Settings"
                                className={"form-control " + (inputCustomSettings.error.length > 0 ? "is-invalid" : '')}
                                {...inputCustomSettings.bind}>
                            </textarea>
                            <CustomSettingsTemplate/>
                            <JsonValidate value={inputCustomSettings.value} />
                            <DivError error={inputCustomSettings.error}/>
                        </div>

                        <div className="form-group">
                            <ButtonSubmit isLoading={false} text="Save changes"/>
                        </div>
                    </form>

                    <PreviewTable
                        forwardPercentage={parseInt(inputForwardPercentage.value)}
                        marginPercentage={parseInt(inputMarginPercentage.value)}
                        precision={parseInt(inputPrecision.value)}
                    />

                </LoadingWrapper>
            </Card>

            {isDebug() && <Card><Dump value={data}/></Card>}
        </Container>
    );
}

export default PostbackSetupCustomFormEdit;
