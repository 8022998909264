import React from 'react';
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

import Register from "./Register";

export default function RegisterRecaptcha() {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
            <Register />
        </GoogleReCaptchaProvider>
    )
}
