import React from 'react';
import PropTypes from 'prop-types';

import DivError from "./DivError";

export default function DivFormGroupRowCheckBox({bind = {}, error = ''}) {
    return <div className="form-check">
        <label className="form-check-label">
            <input
                type="checkbox"
                className={"form-check-input " + (error.length > 0 ? "is-invalid" : '')}
                {...bind}
            /> is excluded
        </label>
        <DivError error={error}/>
    </div>
}

DivFormGroupRowCheckBox.propTypes = {
    bind: PropTypes.object,
    error: PropTypes.string,
}

DivFormGroupRowCheckBox.defaultProps = {
    bind: "",
    error: "",
}
