import './scss/app.scss';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import App from './app/App';
import * as serviceWorker from './app/serviceWorker';
import reportWebVitals from './reportWebVitals';

window.trackerBaseUrl = process.env.REACT_APP_API_URL;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <App/>
    </BrowserRouter>
    // </React.StrictMode>
);


// console.log("ENV")
// console.log(process.env)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
