import React from "react";
import {Link} from "react-router-dom";
import * as Icon from "react-feather";

import ZoneData from "../../../components/ZoneData";
import ExternalLink from "../../components/ExternalLink";
import {resolvePhpPostbackDomain} from "../../../components/postback_url";

export default function PostbackSetupZonesListTableParams(user) {
    const keyId = 'id';
    const keyOriginZoneId = 'origin_zone_id';
    const postbackDomain = resolvePhpPostbackDomain( user);

    const columns = React.useMemo(
        () => [
/*
            {
                Header: 'ID',
                accessor: keyId,
            },
*/
            {
                Header: 'Zone ID',
                accessor: keyOriginZoneId,
            },
            {
                Header: 'Direction',
                Cell: ({row}) => ZoneData.directionName(row.original['direction_id']),
                accessor: 'direction_id',
            },
            {
                Header: 'Publisher',
                accessor: 'pub_account_name',
            },
            {
                Header: 'Advertiser',
                accessor: 'account_name',
            },
            {
                Header: 'Forward Percentage',
                accessor: 'forward_percentage',
            },
            {
                Header: 'Margin Percentage',
                accessor: 'margin_percentage',
            },
            {
                Header: 'Rates',
                accessor: 'rate_table',
                Cell: ({row}) => {
                    return row.original['rate_table'] === null ? <></> : <Icon.Database />
                },
            },
            {
                accessor: 'link_to_source_code',
                className: 'min',
                Cell: ({row}) => <ExternalLink url={'http://' + postbackDomain + '/zone_postback.php?zone=' + row.original[keyOriginZoneId]} title={<Icon.Code/>}/>,
            },
            {
                accessor: 'link_to_log',
                className: 'min',
                Cell: ({row}) => <ExternalLink url={'http://' + postbackDomain + '/conversion_logs/' + row.original[keyOriginZoneId] + '.txt'} title={<Icon.FileText/>}/>,
            },
            {
                accessor: 'button_edit',
                className: 'min',
                Cell: ({row}) => <Link to={"/postback-setup/zones/edit/" + row.original['id']}><Icon.Settings /></Link>,
            },
        ],
        []
    );

    const initialState = {
        sortBy: [
            {
                id: keyId,
                desc: true
            }
        ]
    }

    return [{
        columns,
        initialState
    }];
}
