import React, {useState, useEffect} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";

import Container from "../../../components/Container";
import Card from "../../../components/Сard";
import Table from "../../../components/table/TablePaginated";
import DataModel from "./DataNftCollection";
import TableParams from "./ListTableParams";
import SelectMultiWithHeader from "../../../components/select/SelectMultiWithHeader";
import DataStates from "./data_states";
import Loading from "../../../components/Loading";
import {ObjectRemoveEmptyForUrl} from "../../../../components/helpers";

const NftCollectionList = ({user}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    const location = useLocation();
    const locationPathname = location.pathname;

    const navigate = useNavigate();

    const listState = DataStates.getDataStatesList();

    const [{
        columns: tableColumns,
        initialState: tableInitialState,
    }] = TableParams();

    const [filterState, setFilterState] = useState([]);
    const [filterButton, setFilterButton] = useState(0);

    const [params, setParams] = useState({});
    const [{data, isLoading}, setUrlParams] = DataModel();

    useEffect(() => {
        const fetchData = async () => {
            const promises = [];
            Promise.all(promises).then(function (results) {
                const searchParams = new URLSearchParams(location.search)
                const p = Object.fromEntries(searchParams);

                const statesValues = listState.map(e => e.value + '');
                let urlStates = ("state" in p) ? p.state.split(',') : filterState;
                urlStates = urlStates.filter(v => statesValues.indexOf(v) !== -1).map(v => parseInt(v, 10));
                setFilterState(urlStates);
                p.state = urlStates;

                setParams(p);

                setIsLoaded(true);
            });
        };

        setIsLoaded(false);
        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoaded) {
            let params = {
                state: filterState,
            }

            setParams(params)
        }
    }, [filterState, filterButton]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        navigate(locationPathname + '?' + (new URLSearchParams(ObjectRemoveEmptyForUrl(params))).toString());
        setUrlParams(params);
    }, [params]);

    return (
        <Container title={'Collections'} isLoaded={isLoaded} error={error}>
            <Card>
                <Link to={'/nft/collection/add/'}>Add collection</Link>
            </Card>
            <Card>
                <div className="row">
                    <div className="col-md-3">
                        <SelectMultiWithHeader value={filterState} updateValue={v => setFilterState(v)}
                                               options={listState} defaultLabel="Select state"/>
                    </div>
                    <div className="col-md-9"></div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary" onClick={e => {
                            const t = Date.now();
                            setFilterButton(t)
                        }}>Show Results
                        </button>
                    </div>
                </div>

            </Card>
            <Card>
                {
                    isLoading
                    && <Loading/>
                }
                {
                    !isLoading
                    && <Table columns={tableColumns} data={data} initialState={tableInitialState}/>
                }
                <></>
            </Card>
        </Container>
    );
}

export default NftCollectionList;
