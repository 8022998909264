import React from 'react'

import SelectMulti from "./SelectMulti";

export default function SelectMultiWithHeader({value, updateValue, options, defaultLabel}) {
    return (
        <>
            <b>{defaultLabel}</b>
            <SelectMulti value={value} updateValue={updateValue} options={options} />
        </>
    )
}
