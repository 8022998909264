import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";

import Container from "../../components/Container";
import Card from "../../components/Сard";
import {useGetRequest} from "../../../data/RequestData";
import {useZoneUpdate} from "../../../data/zone";
import {networkIsPropush} from "../../../components/affiliates";

const ZoneAssignForm = ({user}) => {
    let {zone_id} = useParams();

    const [{data}] = useGetRequest('ex-stat/zone/' + zone_id);

    const [{
        data: updateProfile,
        setRequestData,
        dataErrors
    }] = useZoneUpdate(zone_id);

    useEffect(() => {
        if (data && data['is_available'] == 1) {
            const data = {
                is_available: 0,
            };
            setRequestData(data);
        }
    }, [data]);

    return (
        <Container title={"Assign: " + data['origin_zone_id']}>
            <Card>
                {data && data['is_available'] == 0 && <div className={"alert alert-danger"} role="alert">
                    <div className="alert-message">
                        <h4 className="alert-heading">This zone is already in use! Choose another!</h4>
                    </div>
                </div>}

                {dataErrors && dataErrors.length > 0 && <div className={"alert alert-danger"} role="alert">
                    <div className="alert-message">
                        <h4 className="alert-heading">{dataErrors[0]['message']}</h4>
                    </div>
                </div>}

                {updateProfile && updateProfile['id'] == zone_id && updateProfile['is_available'] == 0 && <div className={"alert alert-success"} role="alert">
                    <div className="alert-message">
                        <h4 className="alert-heading">Success assign!</h4>
                        {networkIsPropush(data['pub_account_type']) && <>
                            <p>You can rename zone: <Link
                                to={"/ex-stat/zone/edit-propush/" + data['id']}>{"/ex-stat/zone/edit-propush/" + data['id']}</Link>
                            </p>
                        </>}
                        <p>Or setup postback: {data['conversion_setup_id'] !== null
                            ? <Link to={"/postback-setup/zones/edit/" + data['conversion_setup_id']}>{"/postback-setup/zones/edit/" + data['conversion_setup_id']}</Link>
                            : <Link to={"/postback-setup/zones/create/" + data['id']}>{"/postback-setup/zones/create/" + data['id']}</Link>
                        }</p>
                    </div>
                </div>}
            </Card>
        </Container>
    );
}

export default ZoneAssignForm;
