import React from "react";
import {round} from "../../../components/Math";

export default function LssTableParams(user) {
    const keyDate = 'date';
    const keyCountry = 'country';
    const keyUserId = 'user_id';
    const keyUserEmail = 'user_email';
    const keyProfit = 'profit';

    const columns = React.useMemo(
        () => [
            {
                Header: 'Date',
                accessor: keyDate,
                Footer: <b>Total:</b>,
            },
            {
                Header: 'Email',
                accessor: keyUserEmail,
            },
            {
                Header: 'Country',
                accessor: keyCountry,
                Footer: <b>Total:</b>,
            },
            {
                Header: 'Conversions',
                accessor: 'conversions',
                Footer: info => {
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.conversions) + sum, 0),
                        [info.rows]
                    )

                    return <>{round(total, 4)}</>
                },
            },
            {
                Header: 'Money, $',
                accessor: 'money',
                Footer: info => {
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.money) + sum, 0),
                        [info.rows]
                    )

                    return <>{round(total, 4)}</>
                },
            },
            {
                Header: 'Profit, $',
                accessor: keyProfit,
                Footer: info => {
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.profit) + sum, 0),
                        [info.rows]
                    )

                    return <>{round(total, 4)}</>
                },
            },
        ],
        []
    );

    const initialState = {
        sortBy: [
            {
                id: keyDate,
                desc: true
            }
        ]
    }

    return [{keyDate, keyCountry, keyUserEmail, keyProfit, columns, initialState}];
}
