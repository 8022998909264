import React, {useEffect, useState} from 'react';

import TableColumnsStorage from "../../../components/table/TableColumnsStorage";

export default function TableColumns({storageKey, columns, setUserTableHiddenColumns}) {
    const [collapsed, setCollapsed] = useState(false);
    const [columnsState, setColumnsState] = useState([]);

    const [columnsCount, setColumnsCount] = useState(0);
    const [columnsTotalCount, setColumnsTotalCount] = useState(0);

    useEffect(() => {
        if (collapsed === false) {
            let nt = 0
            let n = 0
            setColumnsState(columns.map(e => {
                nt++;
                let v = TableColumnsStorage.get(storageKey, e.accessor);
                e.checked = v;
                if (v === true) {
                    n++;
                }

                return e;
            }));
            setColumnsCount(n);
            setColumnsTotalCount(nt);
        }
    }, [collapsed, columns]);

    const checkedHandler = (e, column) => {
        setColumnsState((prev) => {
            return prev.map(item => {
                if (item.accessor === column) {
                    item.checked = e.target.checked;

                    TableColumnsStorage.set(storageKey, item.accessor, e.target.checked);
                    if (e.target.checked == false) {
                        setColumnsCount(columnsCount - 1);
                    } else {
                        setColumnsCount(columnsCount + 1);
                    }
                }
                return item;
            });
        });
    };

    useEffect(() => {
        setUserTableHiddenColumns(columnsState.map(e => {
            return e.checked === true ? null : e.accessor
        }).filter(e => e))
    }, [columnsState])

    return <>
        <button className="btn btn-light border" onClick={e => setCollapsed(!collapsed)}>
            Колонки {columnsCount}/{columnsTotalCount}
        </button>

        { collapsed && <div className={"flex-shrink-1 bg-light rounded py-2 px-3 mt-1 text-left"}>
            {columnsState.map(item => (
                <div key={item.accessor}>
                    <input
                        checked={item?.checked || false}
                        onChange={(e) => checkedHandler(e, item.accessor)}
                        type='checkbox'
                    />
                    &nbsp;<span>{item.Header ? item.Header : item.accessor}</span>
                </div>
            ))}
        </div>}
    </>
}
