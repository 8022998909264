const TYPE_PUSH_COST = '11';
const TYPE_PUSH_REVENUE = '12';
const TYPE_PUSH_REVENUE_TB = '13';
const TYPE_TB_COST = '21';
const TYPE_TB_REVENUE = '23';
const TYPE_PLACEMENTS_COST = '31';
const TYPE_PLACEMENTS_REVENUE = '32';
const TYPE_CAMPAIGN_TEST_COST_TB = '40';
const TYPE_REALTIME_TB_COST = '51';
const TYPE_REALTIME_TB_REVENUE = '53';

const typeName = (value) => {
    value = value + ''
    switch (value) {
        case TYPE_PUSH_COST:
            return 'Spend - api';
        case TYPE_PUSH_REVENUE:
            return 'Revenue - api';
        case TYPE_PUSH_REVENUE_TB:
            return 'Revenue tb - api';
        case TYPE_TB_COST:
            return 'Spend - api';
        case TYPE_TB_REVENUE:
            return 'Revenue - api';
        case TYPE_PLACEMENTS_COST:
            return '____';
        case TYPE_PLACEMENTS_REVENUE:
            return '____';
        case TYPE_CAMPAIGN_TEST_COST_TB:
            return 'Spend - test';
        case TYPE_REALTIME_TB_COST:
            return 'Spend - realtime';
        case TYPE_REALTIME_TB_REVENUE:
            return 'Revenue - realtime';
        default:
            return 'Unknown (' + value + ')';
    }
}

const DataData = {
    TYPE_PUSH_COST,
    TYPE_PUSH_REVENUE,
    TYPE_PUSH_REVENUE_TB,
    TYPE_TB_COST,
    TYPE_TB_REVENUE,
    TYPE_PLACEMENTS_COST,
    TYPE_PLACEMENTS_REVENUE,
    TYPE_CAMPAIGN_TEST_COST_TB,
    TYPE_REALTIME_TB_COST,
    TYPE_REALTIME_TB_REVENUE,
    typeName,
}

export default DataData;
