import React from 'react';
import Container from "../../components/Container";

/**
 * @deprecated
 * @returns {JSX.Element}
 * @constructor
 */
function Terms() {
    return (
        <Container title={"Terms"}>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. A condimentum vitae sapien pellentesque habitant morbi tristique. Ante in nibh
                mauris cursus mattis. Fermentum dui faucibus in ornare quam viverra orci sagittis. At auctor urna nunc
                id. Diam phasellus vestibulum lorem sed. Sit amet facilisis magna etiam tempor orci eu lobortis
                elementum. Purus non enim praesent elementum facilisis leo vel fringilla est. Gravida quis blandit
                turpis cursus. Adipiscing diam donec adipiscing tristique. In hendrerit gravida rutrum quisque non
                tellus.
            </p>
            <p>
                Sed vulputate mi sit amet mauris. Vitae elementum curabitur vitae nunc sed velit dignissim. Est lorem
                ipsum dolor sit amet. Phasellus vestibulum lorem sed risus ultricies tristique nulla aliquet. Ultrices
                in iaculis nunc sed. Arcu non odio euismod lacinia at quis. Quam lacus suspendisse faucibus interdum
                posuere lorem ipsum dolor. Neque vitae tempus quam pellentesque nec nam aliquam sem. Arcu felis bibendum
                ut tristique et egestas. Elementum facilisis leo vel fringilla est ullamcorper eget. Nulla malesuada
                pellentesque elit eget gravida cum. Nibh tortor id aliquet lectus. Vitae sapien pellentesque habitant
                morbi. Volutpat blandit aliquam etiam erat velit scelerisque in. Arcu cursus vitae congue mauris
                rhoncus. Eget gravida cum sociis natoque.
            </p>
            <p>
                Et tortor consequat id porta nibh. Orci phasellus egestas tellus rutrum tellus. Egestas sed sed risus
                pretium quam vulputate dignissim suspendisse. Nibh ipsum consequat nisl vel pretium lectus quam id leo.
                Sagittis orci a scelerisque purus. Lacus vel facilisis volutpat est. Est sit amet facilisis magna etiam.
                Eget arcu dictum varius duis at. Aliquet eget sit amet tellus cras adipiscing enim eu turpis. Elementum
                nisi quis eleifend quam adipiscing vitae proin sagittis. Nec ultrices dui sapien eget mi proin sed
                libero enim. Nisl condimentum id venenatis a condimentum vitae. At ultrices mi tempus imperdiet nulla.
                Dui id ornare arcu odio ut sem. Mollis nunc sed id semper risus in. Nunc scelerisque viverra mauris in.
                Mauris nunc congue nisi vitae suscipit tellus mauris a. Lobortis mattis aliquam faucibus purus.
            </p>
            <p>
                Faucibus a pellentesque sit amet porttitor eget. Ultrices mi tempus imperdiet nulla. Est ullamcorper
                eget nulla facilisi etiam. Rhoncus dolor purus non enim. Id porta nibh venenatis cras sed felis eget.
                Eget arcu dictum varius duis at. Dignissim diam quis enim lobortis scelerisque fermentum dui faucibus
                in. Tempus quam pellentesque nec nam aliquam sem et. Aliquam malesuada bibendum arcu vitae. Rhoncus
                dolor purus non enim. Nunc sed blandit libero volutpat sed cras ornare. Purus non enim praesent
                elementum facilisis leo vel fringilla est.
            </p>
            <p>
                Iaculis eu non diam phasellus vestibulum. Et leo duis ut diam quam nulla porttitor massa. Cras
                adipiscing enim eu turpis egestas pretium. Amet commodo nulla facilisi nullam vehicula. Tellus at urna
                condimentum mattis. Condimentum id venenatis a condimentum vitae sapien pellentesque habitant. Elit ut
                aliquam purus sit amet luctus venenatis lectus. Nibh tellus molestie nunc non blandit massa. Laoreet id
                donec ultrices tincidunt arcu non sodales neque. Cras tincidunt lobortis feugiat vivamus at augue eget
                arcu dictum. Pellentesque habitant morbi tristique senectus et netus. Lobortis elementum nibh tellus
                molestie nunc non. Diam volutpat commodo sed egestas egestas fringilla.
            </p>
            <p>
                Eget sit amet tellus cras. Adipiscing elit ut aliquam purus sit amet luctus venenatis lectus. Aenean
                pharetra magna ac placerat vestibulum lectus mauris ultrices eros. Pulvinar neque laoreet suspendisse
                interdum consectetur libero id. Fusce id velit ut tortor. Aliquet eget sit amet tellus cras adipiscing
                enim eu. Cursus mattis molestie a iaculis at erat pellentesque adipiscing commodo. Adipiscing bibendum
                est ultricies integer quis auctor elit. Arcu cursus vitae congue mauris. Dictumst quisque sagittis purus
                sit amet volutpat. Vestibulum morbi blandit cursus risus at ultrices. Morbi blandit cursus risus at
                ultrices mi tempus imperdiet. Iaculis at erat pellentesque adipiscing commodo. Vitae suscipit tellus
                mauris a diam maecenas sed. Ornare suspendisse sed nisi lacus. Quam id leo in vitae. Platea dictumst
                vestibulum rhoncus est pellentesque. Et tortor at risus viverra.
            </p>
            <p>
                Feugiat vivamus at augue eget. Sodales ut etiam sit amet nisl purus in mollis. Pellentesque id nibh
                tortor id aliquet lectus proin nibh nisl. Massa ultricies mi quis hendrerit dolor magna eget est lorem.
                Eget nunc scelerisque viverra mauris in aliquam sem fringilla. Morbi enim nunc faucibus a pellentesque
                sit amet porttitor eget. Sit amet nulla facilisi morbi tempus iaculis. Ipsum dolor sit amet consectetur
                adipiscing elit ut aliquam purus. Tempor orci dapibus ultrices in iaculis nunc sed. Sit amet facilisis
                magna etiam tempor orci. Nunc mattis enim ut tellus. Nulla porttitor massa id neque. Fusce ut placerat
                orci nulla pellentesque. Habitasse platea dictumst quisque sagittis purus sit amet. Nunc lobortis mattis
                aliquam faucibus purus in. Urna cursus eget nunc scelerisque viverra mauris in aliquam. At lectus urna
                duis convallis convallis. Sagittis orci a scelerisque purus. Ipsum consequat nisl vel pretium lectus.
            </p>
            <p>
                Dictumst vestibulum rhoncus est pellentesque. Ultricies integer quis auctor elit sed vulputate mi. Augue
                mauris augue neque gravida in fermentum et. Quis commodo odio aenean sed. Nulla facilisi morbi tempus
                iaculis urna id volutpat lacus laoreet. At risus viverra adipiscing at. Suspendisse interdum consectetur
                libero id faucibus. Convallis convallis tellus id interdum velit laoreet id donec ultrices. Sit amet
                mauris commodo quis imperdiet. Sed egestas egestas fringilla phasellus faucibus scelerisque eleifend
                donec pretium. Et leo duis ut diam quam. Mattis aliquam faucibus purus in massa tempor nec feugiat. Sed
                egestas egestas fringilla phasellus faucibus scelerisque. Auctor urna nunc id cursus metus. Venenatis
                lectus magna fringilla urna porttitor rhoncus dolor purus non. Nunc eget lorem dolor sed.
            </p>
            <p>
                Diam volutpat commodo sed egestas. Ultricies mi eget mauris pharetra et. Bibendum at varius vel pharetra
                vel turpis nunc eget. Nunc pulvinar sapien et ligula ullamcorper malesuada. Quam id leo in vitae turpis
                massa sed elementum tempus. Ut enim blandit volutpat maecenas volutpat blandit aliquam. Nisi lacus sed
                viverra tellus in hac. Vulputate sapien nec sagittis aliquam. Lobortis elementum nibh tellus molestie
                nunc non blandit massa enim. Tincidunt nunc pulvinar sapien et ligula ullamcorper malesuada proin
                libero. Dui id ornare arcu odio. Interdum varius sit amet mattis.
            </p>
            <p>
                Vitae tortor condimentum lacinia quis vel. Turpis egestas sed tempus urna. Etiam tempor orci eu lobortis
                elementum nibh tellus. Mi sit amet mauris commodo. Libero nunc consequat interdum varius sit amet.
                Porttitor eget dolor morbi non arcu risus quis. Morbi quis commodo odio aenean. Auctor neque vitae
                tempus quam pellentesque nec nam. Donec enim diam vulputate ut pharetra. Tristique et egestas quis ipsum
                suspendisse ultrices gravida dictum fusce. Gravida rutrum quisque non tellus orci ac auctor augue
                mauris. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse.
            </p>
        </Container>
    );
}

export default Terms;
