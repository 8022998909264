const AFFILIATE_PROPUSHME = "3"
const AFFILIATE_ROLLERADS = "9"
const AFFILIATE_ZEYDOO = "22"
const AFFILIATE_GALAKSION = "23"

export const AFFILIATES_ID = {
    PROPUSHME: AFFILIATE_PROPUSHME,
    ROLLERADS: AFFILIATE_ROLLERADS,
    ZEYDOO: AFFILIATE_ZEYDOO,
    GALAKSION: AFFILIATE_GALAKSION,
};

export const networkIsPropush = (networkId) => networkId + "" === AFFILIATE_PROPUSHME;
export const networkIsRollerads = (networkId) => networkId + "" === AFFILIATE_ROLLERADS;
export const networkIsZeydoo = (networkId) => networkId + "" === AFFILIATE_ZEYDOO;
export const networkIsGalaksion = (networkId) => networkId + "" === AFFILIATE_GALAKSION;
