import {useState, useEffect} from 'react';

import ajax from "./ajax";
import axios from "axios";
import {round} from "../components/Math";

export default function BackendRequest(path, handleFunction) {
    const [params, setParams] = useState({});
    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsError(false);
            setIsLoading(true);
            setData([]);

            try {
                if (Object.keys(params).length > 0) {
                    let url = window.trackerBaseUrl + path + "?" + (new URLSearchParams(params)).toString();

                    // console.log('====================')
                    // console.log('path:', path)
                    // console.log('handleFunction:', handleFunction)

                    const result = await axios.get(url, ajax.getBaseConfig());
                    if (handleFunction === undefined) {
                        setData(result.data);
                    } else {
                        setData(result.data.map(handleFunction));
                    }
                }
            } catch (error) {
                setIsError(true);
            }

            setIsLoading(false);
        };

        fetchData();
    }, [params]);

    return [{ data, isLoading, isError }, setParams];
}
