import React from "react";

export default function LssFilterGroupByParams(isAdmin) {
    const keyDate = 'date';
    const keyCountry = 'country';
    const keyZone = 'lss_zone';

    const items = React.useMemo(() => [
        {
            title: "Date",
            key: keyDate,
        },
        {
            title: "Country",
            key: keyCountry,
        },
        {
            title: "Zone",
            key: keyZone,
            visible: isAdmin,
        }
    ], []);

    return [{keyDate, keyCountry, keyZone, items}];
}
