import React from 'react';

/**
 * @deprecated
 * @returns {JSX.Element}
 * @constructor
 */
function Privacy() {
    return (
        <div className="container-fluid p-0">
            <h1 className="h3 mb-3">Privacy</h1>

            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Nullam ac tortor vitae purus faucibus ornare suspendisse sed nisi. Eget est lorem
                ipsum dolor. Enim lobortis scelerisque fermentum dui. Velit dignissim sodales ut eu sem integer vitae.
                Massa tincidunt dui ut ornare. At consectetur lorem donec massa sapien faucibus. Non quam lacus
                suspendisse faucibus interdum. Egestas integer eget aliquet nibh praesent tristique. Quam viverra orci
                sagittis eu volutpat odio. Velit ut tortor pretium viverra. Tempor orci dapibus ultrices in iaculis
                nunc. Semper quis lectus nulla at volutpat diam ut venenatis. Orci ac auctor augue mauris. Est lorem
                ipsum dolor sit. Quisque id diam vel quam elementum pulvinar. Rutrum tellus pellentesque eu tincidunt
                tortor.
            </p>
            <p>
                Neque laoreet suspendisse interdum consectetur libero id. Facilisis leo vel fringilla est ullamcorper.
                Feugiat nisl pretium fusce id velit ut. Enim lobortis scelerisque fermentum dui faucibus in. Turpis
                massa tincidunt dui ut ornare lectus sit. Sit amet consectetur adipiscing elit. Amet dictum sit amet
                justo donec enim diam vulputate ut. In mollis nunc sed id semper risus in. Porta lorem mollis aliquam ut
                porttitor leo a. Dictum non consectetur a erat nam. In nibh mauris cursus mattis molestie a iaculis at
                erat. Est ullamcorper eget nulla facilisi etiam dignissim. Facilisis mauris sit amet massa vitae tortor
                condimentum lacinia.
            </p>
            <p>
                Enim sit amet venenatis urna cursus. Vestibulum morbi blandit cursus risus at ultrices mi tempus
                imperdiet. Tincidunt augue interdum velit euismod in pellentesque. Donec pretium vulputate sapien nec
                sagittis aliquam malesuada. Facilisis volutpat est velit egestas dui id. Convallis tellus id interdum
                velit laoreet id donec. Scelerisque varius morbi enim nunc faucibus. Natoque penatibus et magnis dis
                parturient montes nascetur ridiculus mus. Dolor sed viverra ipsum nunc aliquet bibendum. Eget nunc
                scelerisque viverra mauris in aliquam sem fringilla. Proin fermentum leo vel orci porta non. Ut aliquam
                purus sit amet. Mauris cursus mattis molestie a iaculis at erat. Aenean sed adipiscing diam donec. At
                quis risus sed vulputate odio ut enim. Fermentum leo vel orci porta non. Aliquam malesuada bibendum arcu
                vitae elementum curabitur vitae nunc sed. Diam vel quam elementum pulvinar etiam non quam lacus. Tortor
                aliquam nulla facilisi cras fermentum odio eu feugiat.
            </p>
            <p>
                Risus pretium quam vulputate dignissim suspendisse. Facilisis volutpat est velit egestas dui id ornare.
                Nisl nisi scelerisque eu ultrices vitae. Eu augue ut lectus arcu bibendum at varius. Lobortis feugiat
                vivamus at augue eget arcu dictum. Netus et malesuada fames ac turpis egestas integer eget. Sed
                elementum tempus egestas sed sed risus. Posuere ac ut consequat semper viverra nam libero justo.
                Tristique sollicitudin nibh sit amet commodo nulla facilisi nullam. Sit amet nisl purus in. Laoreet non
                curabitur gravida arcu ac tortor dignissim convallis aenean. Congue mauris rhoncus aenean vel elit
                scelerisque. Gravida neque convallis a cras semper. Vitae aliquet nec ullamcorper sit amet risus nullam
                eget. Tristique nulla aliquet enim tortor. Augue eget arcu dictum varius duis. Proin sed libero enim sed
                faucibus turpis in eu. Sit amet consectetur adipiscing elit duis tristique.
            </p>
            <p>
                Semper risus in hendrerit gravida rutrum quisque. Diam donec adipiscing tristique risus nec feugiat in
                fermentum posuere. Elit pellentesque habitant morbi tristique senectus et netus et. Egestas diam in arcu
                cursus euismod quis viverra nibh. Leo in vitae turpis massa sed. Morbi enim nunc faucibus a pellentesque
                sit amet porttitor. Facilisis mauris sit amet massa. Faucibus et molestie ac feugiat sed lectus
                vestibulum mattis ullamcorper. In metus vulputate eu scelerisque felis imperdiet proin fermentum.
                Elementum facilisis leo vel fringilla est ullamcorper eget nulla facilisi. Quam quisque id diam vel quam
                elementum. Cras adipiscing enim eu turpis egestas pretium aenean. Risus viverra adipiscing at in tellus.
                Porttitor eget dolor morbi non arcu. Nulla aliquet porttitor lacus luctus accumsan tortor. Sed pulvinar
                proin gravida hendrerit lectus a. Nulla pharetra diam sit amet nisl suscipit. Quis viverra nibh cras
                pulvinar mattis nunc.
            </p>
            <p>
                Ut lectus arcu bibendum at varius. Nunc non blandit massa enim nec. Libero enim sed faucibus turpis in
                eu mi bibendum neque. Ut ornare lectus sit amet est placerat in. Est ultricies integer quis auctor elit
                sed. Scelerisque eleifend donec pretium vulputate sapien nec sagittis aliquam. Consectetur adipiscing
                elit pellentesque habitant morbi tristique senectus et. Ultricies leo integer malesuada nunc vel risus
                commodo. Lectus magna fringilla urna porttitor rhoncus dolor. Nulla facilisi etiam dignissim diam quis
                enim lobortis scelerisque fermentum. Id volutpat lacus laoreet non curabitur gravida arcu. Vulputate
                enim nulla aliquet porttitor lacus luctus accumsan. Ullamcorper morbi tincidunt ornare massa. Et
                malesuada fames ac turpis egestas. Pellentesque id nibh tortor id aliquet lectus proin nibh nisl. Enim
                diam vulputate ut pharetra sit. Condimentum id venenatis a condimentum vitae sapien pellentesque
                habitant morbi. Sed vulputate mi sit amet mauris commodo quis imperdiet. Sit amet est placerat in
                egestas erat imperdiet sed. Mauris in aliquam sem fringilla ut.
            </p>
            <p>
                Pellentesque pulvinar pellentesque habitant morbi tristique senectus et netus. Et odio pellentesque diam
                volutpat commodo. Egestas fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate
                sapien. Nibh tortor id aliquet lectus proin nibh nisl condimentum id. Sodales ut eu sem integer vitae
                justo eget magna fermentum. Elementum nibh tellus molestie nunc non blandit massa enim nec. Maecenas
                volutpat blandit aliquam etiam. Sit amet cursus sit amet dictum sit amet justo donec. Et ligula
                ullamcorper malesuada proin. Ut tellus elementum sagittis vitae et leo duis ut diam. Non odio euismod
                lacinia at quis risus sed vulputate. Nam at lectus urna duis convallis convallis tellus id interdum.
                Urna id volutpat lacus laoreet non curabitur gravida arcu ac. Egestas congue quisque egestas diam in
                arcu. Pharetra diam sit amet nisl suscipit adipiscing. Semper viverra nam libero justo laoreet sit amet
                cursus. Cras semper auctor neque vitae tempus quam pellentesque nec nam. Vitae semper quis lectus nulla
                at volutpat diam ut venenatis.
            </p>
        </div>
    );
}

export default Privacy;
