import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {Dump} from "../../../components/development";
import {round} from "../../../components/Math";

export default function PreviewTable({forwardPercentage, marginPercentage, precision}) {
    const [tableData, setTableData] = useState({});

    useEffect(() => {
        // function randomIntFromInterval(min, max) { // min and max included
        //     return Math.floor(Math.random() * (max - min + 1) + min)
        // }

        let tableData = {
            coefficient: round((100 / forwardPercentage) * (1 - marginPercentage / 100), 8),
            convPrice: 0.01,
            totalConversions: 100000,
            totalPrice: 0,
            totalPriceMargin: 0,
            forwardConversions: 0
        };
        const ic = Math.round(tableData.totalConversions * forwardPercentage / 100);
        for (let i = 0; i < ic; i++) {
            tableData.forwardConversions++;
            tableData.totalPriceMargin += tableData.convPrice * tableData.coefficient;
            // const p = precision == -1 ? 8 : precision;
            // tableData.totalPriceMargin += Math.round((tableData.convPrice * tableData.coefficient + Number.EPSILON) * Math.pow(10, p)) / Math.pow(10, p);
        }
        tableData.totalPrice += tableData.convPrice * tableData.totalConversions;

        // for (let i = 0; i < tableData.totalConversions; i++) {
        //     const r = 10000;
        //     if (forwardPercentage >= randomIntFromInterval(1, 100)) {
        //         tableData.forwardConversions++;
        //         tableData.totalPriceMargin += tableData.convPrice * (100 / forwardPercentage) * (1 - marginPercentage / 100);
        //     }
        //     tableData.totalPrice += tableData.convPrice;
        // }

        tableData.totalPrice = round(tableData.totalPrice, 2);
        tableData.totalPriceMargin = round(tableData.totalPriceMargin, 2);

        setTableData(tableData)
    }, [forwardPercentage, marginPercentage]); // , precision

    return (
        <>
            <table className={"table table-sm table-striped"}>
                <thead>
                <tr>
                    <th colSpan={5}>Preview</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td></td>
                    <th>FP = 100<br/>MP = 0<br/>conv={tableData.convPrice}</th>
                    <th>FP = {forwardPercentage}<br/>MP = {marginPercentage}<br/>conv={tableData.convPrice}</th>
                </tr>
                <tr>
                    <th>Conversions</th>
                    <td>{tableData.totalConversions}</td>
                    <td>{tableData.forwardConversions}</td>
                </tr>
                <tr>
                    <th>Sum income</th>
                    <td>{tableData.totalPrice}</td>
                    <td>{tableData.totalPrice}</td>
                </tr>
                <tr>
                    <th>Sum outcome</th>
                    <td>{tableData.totalPrice}</td>
                    <td><span className={tableData.totalPriceMargin > tableData.totalPrice ? 'text-danger' : ''}>{tableData.totalPriceMargin}</span></td>
                </tr>
                <tr>
                    <th>Rate coefficient</th>
                    <td>{(100 / 100) * (1 - 0 / 100)}</td>
                    <td>{tableData.coefficient != Infinity ? tableData.coefficient : <span className='text-danger'>-</span>}</td>
                </tr>
                <tr>
                    <th>Avg conv outcome</th>
                    <td>{tableData.convPrice}</td>
                    <td>{tableData.totalPriceMargin && tableData.forwardConversions ? tableData.totalPriceMargin / tableData.forwardConversions : ''}</td>
                </tr>
                <tr>
                    <th>ROI</th>
                    <td>{round(100 * (tableData.totalPrice - tableData.totalPrice) / tableData.totalPrice, 2)} %</td>
                    <td>{round(100 * (tableData.totalPrice - tableData.totalPriceMargin) / tableData.totalPriceMargin, 2)} %</td>
                </tr>
                </tbody>
            </table>

            <Dump value={tableData} />
        </>
    )
}

PreviewTable.propTypes = {
    forwardPercentage: PropTypes.number,
    marginPercentage: PropTypes.number,
    precision: PropTypes.number,
}

PreviewTable.defaultProps = {
    forwardPercentage: 80,
    marginPercentage: 20,
    precision: 3,
}
