import React from 'react';
import PropTypes from 'prop-types';

export default function ExternalLink({url, title}) {
    return <a href={url} target="_blank" rel="noreferrer noopener">{title === "" ? url : title}</a>
}

ExternalLink.propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string
    ]),
}

ExternalLink.defaultProps = {
    title: "",
}
