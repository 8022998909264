import {filterAllowedValues} from "./helpers";

const collectParamsForFilter = (location, filters) => {
	const searchParams = new URLSearchParams(location.search)
	const p = Object.fromEntries(searchParams);

	console.log('p', p)

	const callBackParam = {};
	filters.forEach(f => {
		const value = filterAllowedValues(p[f.key], f.data.map(e => e.value));
		f.input.setOptions(f.data);
		f.input.setValue(value);

		callBackParam[f.key] = value;
	})

	return callBackParam;
}

/**
 * @deprecated
 * @param callback
 * @param location
 * @param filters
 */
const applyUrlToFilter = (callback, location, filters) => {
	callback(collectParamsForFilter(location, filters));
}

const Filter ={
	applyUrlToFilter,
	collectParamsForFilter,
}

export default Filter;