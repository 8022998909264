import React from "react";

export default function ListGroupByParams() {
    const keyDate = 'date';
    const keyDateTail = 'date_tail';
    const keyCountry = 'country';
    const keyZone = 'zone';
    // const keyCampaign = 'campaign';

    const items = React.useMemo(() => [
        {
            title: "Date",
            key: keyDate,
        },
        {
            title: "Date spend",
            key: keyDateTail,
        },
        {
            title: "Country",
            key: keyCountry,
        },
        {
            title: "Zone",
            key: keyZone,
            // visible: bool,
        },
        // {
        //     title: "Campaign",
        //     key: keyCampaign,
        // },
    ], []);

    // return [{keyDate, keyCountry, keyZone, keyCampaign, items}];
    return [{keyDate, keyDateTail, keyCountry, keyZone, items}];
}
