import React from "react";
import TableTdCompare from "./TableTdCompare";
import {
    formulaProfit,
    formulaROI,
    roundMoney,
    roundPercent,
    tableColumnSum
} from "../../../components/table/TableListFunctions";

export const compareCellDate = (x) => {
    const y = "prev_" + x;
    return ({row: {original: o}}) => {
        return <>
            <div>{o[x]}</div>
            {y in o && <div className={"text-secondary"}>{o[y]}</div>}
        </>
    }
}

export const compareCellBasic = (x, y, roundF) => {
    return ({row: {original: o}}) => {
        return <TableTdCompare
            val1={o[x]}
            val2={y in o ? o[y] : undefined}
            roundFunction={roundF}
        />
    }
}

export const compareFooterBasic = (x, y, roundF) => {
    return ({rows}) => {
        return <TableTdCompare
            val1={tableColumnSum(rows, x)}
            val2={rows.length > 0 && y in rows[0].original ? tableColumnSum(rows, y) : undefined}
            roundFunction={roundF}
        />
    }
}

export const compareCell = (x, roundF) => compareCellBasic(x, "prev_" + x, roundF)
export const compareFooter = (x, roundF) => compareFooterBasic(x, "prev_" + x, roundF)

export const compareCellMoney = (x) => compareCell(x, roundMoney);
export const compareFooterMoney = (x) => compareFooter(x, roundMoney);

export const compareFooterROI = (fRevenue, fCost) => {
    return ({rows}) => {
        const revenue = tableColumnSum(rows, fRevenue);
        const cost = tableColumnSum(rows, fCost);
        const roi = formulaROI(formulaProfit(revenue, cost), cost);

        let prev_roi = undefined;
        const pcf = 'prev_' + fCost
        if (rows.length > 0 && pcf in rows[0].original) {
            const prev_revenue = tableColumnSum(rows, 'prev_' + fRevenue);
            const prev_cost = tableColumnSum(rows, pcf);
            prev_roi = formulaROI(formulaProfit(prev_revenue, prev_cost), prev_cost);
        }

        return <TableTdCompare val1={roi} val2={prev_roi} roundFunction={roundPercent}/>
    }
}
