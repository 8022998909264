import React, {useState, useEffect} from 'react';
import {useLocation} from "react-router-dom";
import axios from "axios";
import {CopyToClipboard} from "react-copy-to-clipboard/src";

import Container from "../../components/Container";
import Card from "../../components/Сard";
import ExternalLink from "../../components/ExternalLink";
import DivFormGroupRow from "../../components/form/DivFormGroupRow";
import SelectSingle from "../../components/select/SelectSingle";
import DivError from "../../components/form/DivError";
import {useInput, useSelect} from "../../../components/input-hook";
import ajax from "../../../data/ajax";
import {consoleLogResponse} from "../../../components/development";
import {SelectDataFormatter} from "../../../components/select_data";
import {
    TRAFFIC_SOURCES_ID,
    TRAFFIC_SOURCES_PLACEHOLDER_KEYS,
    TRAFFIC_SOURCES_PLACEHOLDERS
} from "../../../components/traffic_sources";

const LandingLinkPage = ({user}) => {
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(true);

    const inputZoneId = useInput('');
    const inputSource = useSelect('');
    const inputIndexX3 = useInput('');
    const inputTbZoneId = useInput('');

    const [domainsList, setDomainsList] = useState({});

    const [smLnkPropushIntent, setSmLnkPropushIntent] = useState('');
    const [smLnkPropush, setSmLnkPropush] = useState('');
    const [smLnkRollerIntent, setSmLnkRollerIntent] = useState('');
    const [smLnkRoller, setSmLnkRoller] = useState('');
    const [lpLnkPropushX1v1, setLpLnkPropushX1v1] = useState('');
    const [lpLnkPropushX1v2, setLpLnkPropushX1v2] = useState('');
    const [lpLnkRollerX1TbPropush, setLpLnkRollerX1TbPropush] = useState('');
    const [lpLnkRollerX1TbRoller, setLpLnkRollerX1TbRoller] = useState('');
    const [lpLnkPropushX3, setLpLnkPropushX3] = useState('');
    const [lpLnkPropushLnk17_5, setLpLnkPropushLnk17_5] = useState('');
    const [lpLnkPropushLnk17_6, setLpLnkPropushLnk17_6] = useState('');

    useEffect(() => {
        const requestConfig = ajax.getBaseConfig();
        const promises = [
            axios.get(window.trackerBaseUrl + "landing-domains-list", requestConfig),
        ];
        Promise.all(promises).then(results =>  {
            consoleLogResponse(results);

            inputSource.setOptions(SelectDataFormatter.trafficSources());

            const searchParams = Object.fromEntries(new URLSearchParams(location.search));
            console.log('searchParams', searchParams)

            inputZoneId.setValue('zone' in searchParams ? searchParams.zone : '0000000');
            inputSource.setValue('source' in searchParams ? searchParams.source : '');

            inputIndexX3.setValue('1')

            setDomainsList(results[0].data)

            setIsLoading(false);
        });
    }, []);

    let macrosYmid = "CLICK_ID_MACROS"
    let macrosSource = "SOURCE_MACROS"
    let macrosGeo = "GEO_MACROS"

    const r = (url) => {
        inputSource.setError('');

        let mYmid = macrosYmid
        let mSource = macrosSource
        let mGeo = macrosGeo

        if (inputSource.value in TRAFFIC_SOURCES_PLACEHOLDERS) {
            if (TRAFFIC_SOURCES_PLACEHOLDER_KEYS.CLICK_ID in TRAFFIC_SOURCES_PLACEHOLDERS[inputSource.value]) {
                mYmid = TRAFFIC_SOURCES_PLACEHOLDERS[inputSource.value][TRAFFIC_SOURCES_PLACEHOLDER_KEYS.CLICK_ID];
            }
            if (TRAFFIC_SOURCES_PLACEHOLDER_KEYS.CAMPAIGN_ID in TRAFFIC_SOURCES_PLACEHOLDERS[inputSource.value]) {
                mSource = TRAFFIC_SOURCES_PLACEHOLDERS[inputSource.value][TRAFFIC_SOURCES_PLACEHOLDER_KEYS.CAMPAIGN_ID];
            }
            if (TRAFFIC_SOURCES_PLACEHOLDER_KEYS.GEO in TRAFFIC_SOURCES_PLACEHOLDERS[inputSource.value]) {
                mGeo = TRAFFIC_SOURCES_PLACEHOLDERS[inputSource.value][TRAFFIC_SOURCES_PLACEHOLDER_KEYS.GEO];
            }
        }
        switch (inputSource.value) {
            case TRAFFIC_SOURCES_ID.ADSTERRA:
                mSource += "__##PLACEMENT_ID##";
                break;
            case TRAFFIC_SOURCES_ID.CLICKADU:
                mSource += "__{zoneid}";
                break;
            case TRAFFIC_SOURCES_ID.ADCASH:
                mSource += "__[zone]";
                break;
            case TRAFFIC_SOURCES_ID.GALAKSION:
                mSource += "__{zoneid}";
                break;
            case TRAFFIC_SOURCES_ID.POPADS:
                mSource += "__[WEBSITEID]";
                break;
            case TRAFFIC_SOURCES_ID.TRAFFIC_STARS:
                mSource += "__{site_id}";
                break;
            case TRAFFIC_SOURCES_ID.MONETIZER:
                mSource += "__[[pid]]";
                break;
        }

        url = url.replace(macrosYmid, mYmid)
        url = url.replace(macrosSource, mSource)
        url = url.replace(macrosGeo, mGeo)

        if (mYmid === macrosYmid) {
            inputSource.setError('No source selected. Replace placeholders manually');
        }

        return url;
    }

    useEffect(() => {
        if (!isLoading) {
            const domainSmartlink = domainsList.smartlink_simple;
            const domainSmartlinkIntent = domainsList.smartlink_intent;
            const domainPropushPushX1 = domainsList.propush_push_x1;
            const domainPropushPushX3 = domainsList.propush_push_x3;
            const domainPropushPushLnk17 = domainsList.propush_push_lnk17;
            const domainRollerPushX1 = domainsList.rollerads_push_x1;

            const smPropushSearchParams = new URLSearchParams({
                zoneid: inputZoneId.value,
                ymid: macrosYmid,
                sourceid: macrosSource,
                tt: 2,
            });
            setSmLnkPropushIntent(r("https://" + domainSmartlinkIntent + "/sm_a2_nwvcnkyqd0pp.html?" + smPropushSearchParams.toString()));
            setSmLnkPropush(r("https://" + domainSmartlink + "/sm_b2_ygbgxry90i72.html?" + smPropushSearchParams.toString()));

            const smRollerSearchParams = new URLSearchParams({
                zoneid: inputZoneId.value,
                ymid: macrosYmid,
                sourceid: macrosSource,
                geo: macrosGeo,
                tt: 2,
            });
            setSmLnkRollerIntent(r("https://" + domainSmartlinkIntent + "/sm_ra_2_b17trmu6.html?" + smRollerSearchParams.toString()));
            setSmLnkRoller(r("https://" + domainSmartlink + "/sm_ra_1_mfgwvmoa.html?" + smRollerSearchParams.toString()));

            const lpPushX1SearchParams = new URLSearchParams({
                zoneid: inputZoneId.value,
                ymid: macrosYmid,
                sourceid: macrosSource,
            });
            if (inputTbZoneId.value !== "") {
                lpPushX1SearchParams.set("tbzone", inputTbZoneId.value);
            }
            setLpLnkPropushX1v1(r("https://" + domainPropushPushX1 + "/lp_a4_vtmmzfzwml2x.html?" + lpPushX1SearchParams.toString()));
            setLpLnkPropushX1v2(r("https://" + domainPropushPushX1 + "/lp_d1_w2t58vbd9yy7.html?" + lpPushX1SearchParams.toString()));

            const lpRollerX1TbPropushSearchParams = new URLSearchParams({
                zoneid: inputZoneId.value,
                ymid: macrosYmid,
                geo: macrosGeo,
            });
            if (inputTbZoneId.value !== "") {
                lpRollerX1TbPropushSearchParams.set("tbzone", inputTbZoneId.value);
            }
            setLpLnkRollerX1TbPropush(r("https://" + domainRollerPushX1 + "/lp_ra_2_rs.html?" + lpRollerX1TbPropushSearchParams.toString()));

            const lpLnkRollerX1TbRollerSearchParams = new URLSearchParams({
                zoneid: inputZoneId.value,
                ymid: macrosYmid,
                geo: macrosGeo,
                tbzone: inputTbZoneId.value,
            });
            setLpLnkRollerX1TbRoller(r("https://" + domainRollerPushX1 + "/lp_ra_3_tb.html?" + lpLnkRollerX1TbRollerSearchParams.toString()));

            const lpPushX3SearchParams = new URLSearchParams({
                zoneid: inputZoneId.value,
                ymid: macrosYmid,
                geo: macrosGeo,
                sourceid: macrosSource,
            });
            setLpLnkPropushX3(r("https://" + domainPropushPushX3 + "/lp_b2_sj062abmdrhb_" + inputIndexX3.value + ".html?" + lpPushX3SearchParams.toString()));

            const lpPushLnk17SearchParams = new URLSearchParams({
                resubscription: 20,
                zoneid: inputZoneId.value,
                ymid: macrosYmid,
                sourceid: macrosSource,
            });
            setLpLnkPropushLnk17_5(r("https://" + domainPropushPushLnk17 + "/lnk17_5.html?" + lpPushLnk17SearchParams.toString()));
            setLpLnkPropushLnk17_6(r("https://" + domainPropushPushLnk17 + "/lnk17_6.html?" + lpPushLnk17SearchParams.toString()));
        }
    }, [
        isLoading,
        inputZoneId.value,
        inputSource.value,
        inputIndexX3.value,
        inputTbZoneId.value,
    ]);

    const elementButton = <button className={"btn btn-secondary"}>Copy</button>;

    return (
        <Container title={"Landings"}>
            <Card>
                <form>
                    <DivFormGroupRow label="Zone">
                        <input
                            type="text"
                            id="inputZoneId"
                            placeholder="Zone ID"
                            className={"form-control " + (inputZoneId.error.length > 0 ? "is-invalid" : '')}
                            {...inputZoneId.bind}
                        />
                    </DivFormGroupRow>

                    <DivFormGroupRow label="Source">
                        <SelectSingle {...inputSource.bind} />
                        <DivError error={inputSource.error}/>
                    </DivFormGroupRow>
                </form>
            </Card>

            <Card>
                <h2>Smartlink</h2>

                <p>
                    main domain - <ExternalLink url={"https://" + domainsList.smartlink_simple} title={domainsList.smartlink_simple} />
                    <br/>
                    intent domain - <ExternalLink url={"https://" + domainsList.smartlink_intent} title={domainsList.smartlink_intent} />
                </p>

                <p>allowed only landings with prefix "sm_"</p>

                <table className={"table"}>
                    <thead>
                        <tr>
                            <th>Zone</th>
                            <th>Intent</th>
                            <th>Link</th>
                            <th>Copy</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Propush</td>
                        <td></td>
                        <td><ExternalLink url={smLnkPropush}/></td>
                        <td><CopyToClipboard text={smLnkPropush}>{elementButton}</CopyToClipboard></td>
                    </tr>
                    <tr>
                        <td>Propush</td>
                        <td>Yes</td>
                        <td><ExternalLink url={smLnkPropushIntent}/></td>
                        <td><CopyToClipboard text={smLnkPropushIntent}>{elementButton}</CopyToClipboard></td>
                    </tr>
                    <tr>
                        <td>Rollerads</td>
                        <td></td>
                        <td><ExternalLink url={smLnkRoller}/></td>
                        <td><CopyToClipboard text={smLnkRoller}>{elementButton}</CopyToClipboard></td>
                    </tr>
                    <tr>
                        <td>Rollerads</td>
                        <td>Yes</td>
                        <td><ExternalLink url={smLnkRollerIntent}/></td>
                        <td><CopyToClipboard text={smLnkRollerIntent}>{elementButton}</CopyToClipboard></td>
                    </tr>
                    </tbody>
                </table>
            </Card>

            {/*<Card>
                <h2>Push (x1 all landings with intent)</h2>

                <p>
                    propush current domain - <ExternalLink url={"https://" + domainsList.propush_push_x1} title={domainsList.propush_push_x1} />
                    <br/>
                    roller current domain - <ExternalLink url={"https://" + domainsList.rollerads_push_x1} title={domainsList.rollerads_push_x1} />
                </p>
                <p>allowed only landings with prefix "lp_"</p>

                <form>
                    <DivFormGroupRow label="Custom TB Zone">
                        <input
                            type="text"
                            id="inputTbZoneId"
                            placeholder="zone id"
                            className={"form-control " + (inputTbZoneId.error.length > 0 ? "is-invalid" : '')}
                            {...inputTbZoneId.bind}
                        />
                    </DivFormGroupRow>
                </form>

                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>Zone</th>
                        <th>TB zone</th>
                        <th>Link</th>
                        <th>Copy</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Propush</td>
                        <td>Propush</td>
                        <td>
                            <ExternalLink url={lpLnkPropushX1v1}/>
                            <br/>
                            Tb zones: 5168383, 5925145, 5925146, 5925147, 5925148, 5925151, 5925152, 5925153, 5925154, 5925155, 5925160, 6962901, 6962902
                        </td>
                        <td><CopyToClipboard text={lpLnkPropushX1v1}>{elementButton}</CopyToClipboard></td>
                    </tr>
                    <tr>
                        <td>Propush</td>
                        <td>Propush</td>
                        <td>
                            <ExternalLink url={lpLnkPropushX1v2}/>
                            <br/>
                            Tb zones: 6962903, 6962904, 6962905, 6962906, 6962907
                        </td>
                        <td><CopyToClipboard text={lpLnkPropushX1v2}>{elementButton}</CopyToClipboard></td>
                    </tr>
                    <tr>
                        <td>Rollerads</td>
                        <td>Propush</td>
                        <td>
                            <ExternalLink url={lpLnkRollerX1TbPropush}/>
                            <br/>
                            Tb zones: 6962903, 6962904, 6962905, 6962906, 6962907
                        </td>
                        <td><CopyToClipboard text={lpLnkRollerX1TbPropush}>{elementButton}</CopyToClipboard></td>
                    </tr>
                    <tr>
                        <td>Rollerads</td>
                        <td>Rollerads</td>
                        <td>
                            {inputTbZoneId.value !== "" ? <ExternalLink url={lpLnkRollerX1TbRoller}/> : "Required tb zone"}
                        </td>
                        <td>
                            {inputTbZoneId.value !== "" && <CopyToClipboard text={lpLnkRollerX1TbRoller}>
                                {elementButton}
                            </CopyToClipboard>}
                        </td>
                    </tr>
                </tbody>
                </table>
            </Card>*/}

            {/*<Card>
                <h2>Push (x3)</h2>

                <p>
                    current domain - <ExternalLink url={"https://" + domainsList.propush_push_x3} title={domainsList.propush_push_x3} />
                </p>
                <p>allowed only landings with prefix "lp_"</p>

                <form>
                    <DivFormGroupRow label="Index">
                        <input
                            type="text"
                            id="inputIndexX3"
                            placeholder="index"
                            className={"form-control " + (inputIndexX3.error.length > 0 ? "is-invalid" : '')}
                            {...inputIndexX3.bind}
                        />
                    </DivFormGroupRow>
                </form>

                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>Zone</th>
                        <th>Intent</th>
                        <th>Link</th>
                        <th>Copy</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Propush</td>
                        <td>Yes</td>
                        <td>
                            <ExternalLink url={lpLnkPropushX3}/>
                            <br/>
                            Tb zones: 5168383, 5925145, 5925146, 5925147, 5925148, 5925151, 5925152, 5925153, 5925154, 5925155, 5925160, 6962901, 6962902
                        </td>
                        <td><CopyToClipboard text={lpLnkPropushX3}>{elementButton}</CopyToClipboard></td>
                    </tr>
                    </tbody>
                </table>
            </Card>*/}

            {/*<Card>
                <h2>Push (lnk17)</h2>

                <p>
                    current domain - <ExternalLink url={"https://" + domainsList.propush_push_lnk17} title={domainsList.propush_push_lnk17} />
                </p>
                <p>allowed only landings with prefix "lnk17_"</p>

                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>Zone</th>
                        <th>Intent</th>
                        <th>Link</th>
                        <th>Copy</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Propush</td>
                        <td></td>
                        <td>
                            <ExternalLink url={lpLnkPropushLnk17_5}/>
                            <br/>
                            Tb zones: 5168378, 5168380, 5168381, 5168382, 5168383, 5925145, 5925146
                        </td>
                        <td><CopyToClipboard text={lpLnkPropushLnk17_5}>{elementButton}</CopyToClipboard></td>
                    </tr>
                    <tr>
                        <td>Propush</td>
                        <td>Yes</td>
                        <td>
                            <ExternalLink url={lpLnkPropushLnk17_6}/>
                            <br/>
                            Tb zones: 5168378, 5168380, 5168381, 5168382, 5168383, 5925145, 5925146, 6962901, 6962902
                        </td>
                        <td><CopyToClipboard text={lpLnkPropushLnk17_6}>{elementButton}</CopyToClipboard></td>
                    </tr>
                    </tbody>
                </table>
            </Card>*/}

        </Container>
    );
}

export default LandingLinkPage;
