import React from 'react';
import PropTypes from 'prop-types';
import Loading from "./Loading";

export default function Container({children, title, titleInfo, isLoaded, error}) {
    if (error) {
        console.error("container loading error: ", error);
        return <div>Ошибка: {error.message}</div>
    } else if (!isLoaded) {
        return <Loading/>
    } else {
        return (
            <div className="container-fluid p-0">
                {titleInfo.length > 0 && <h1 className="h4 mb-3 float-right">{titleInfo}</h1>}
                {title.length > 0 && <h1 className="h3 mb-3">{title}</h1>}

                <>{children}</>
            </div>
        )
    }
}

Container.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element.isRequired,
        PropTypes.string.isRequired
    ]),
    titleInfo: PropTypes.string,
    title: PropTypes.string,
    isLoaded: PropTypes.bool,
    error: PropTypes.object,
}

Container.defaultProps = {
    isLoaded: true,
    title: '',
    titleInfo: '',
    // error: {
    //     message: "Что-то пошло не так"
    // },
}
