import React from 'react';
import PropTypes from 'prop-types';

import Alert from "./Alert";

export default function AlertDanger({messagePrefix, message}) {
    return (
        <Alert className='alert-danger' message={message} messagePrefix={messagePrefix}/>
    )
}

AlertDanger.propTypes = {
    message: PropTypes.string.isRequired,
    messagePrefix: PropTypes.string,
}

AlertDanger.defaultProps = {
    messagePrefix: "Error:",
}
