import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import Tab from "./../Settings/Tab";
import ButtonSubmit from "../../components/ButtonSubmit";
import AlertSuccess from "../../components/AlertSuccess";
import {useInput} from "../../../components/input-hook";
import {useSegmentRequest, useSegmentUpdate} from "../../../data/segment";

export default function SegmentDefaultsEditFormTabSettings({tabActiveID, segmentID}) {
    const [{data: segment, isLoading, error}] = useSegmentRequest(segmentID);

    const [{
        data: updateData,
        setRequestData,
        isLoading: updateIsLoading,
        error: updateError,
        dataErrors
    }] = useSegmentUpdate(segmentID);

    const {
        value: rateDefault,
        setValue: setRateDefault,
        error: errorRateDefault,
        setError: setErrorRateDefault,
        bind: bindRateDefault
    } = useInput('');
    const {
        value: rateMin,
        setValue: setRateMin,
        error: errorRateMin,
        setError: setErrorRateMin,
        bind: bindRateMin
    } = useInput('');
    const {
        value: rateMax,
        setValue: setRateMax,
        error: errorRateMax,
        setError: setErrorRateMax,
        bind: bindRateMax
    } = useInput('');

    const setFromResponse = (result) => {
        console.log('result -------------------', result)
        setRateDefault(result.rate_default ? result.rate_default : '');
        setRateMax(result.rate_max ? result.rate_max : '');
        setRateMin(result.rate_min ? result.rate_min: '');
        // setRateDefault(result.custom_revenue_model ? result.custom_revenue_model : '');
    }

    useEffect(() => {
        setFromResponse(segment);
    }, [segment]);

    useEffect(() => {
        if (updateData) {
            setFromResponse(updateData);
        }
    }, [updateData]);

    useEffect(() => {
        // console.log('dataErrors -------------------', dataErrors)
        let errors = {
            rate_default: '',
            rate_min: '',
            rate_max: '',
        };
        dataErrors.forEach(v => {
            // console.log('v -------------------', v)
            if (v.field === 'rate_default') {
                errors.rate_default = v.message
            } else if (v.field === 'rate_min') {
                errors.rate_min = v.message
            } else if (v.field === 'rate_max') {
                errors.rate_max = v.message
            }
        })
        // console.log('errors -------------------', errors)

        setErrorRateDefault(errors.rate_default);
        setErrorRateMin(errors.rate_min);
        setErrorRateMax(errors.rate_max);
    }, [dataErrors]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            rate_default: rateDefault,
            rate_max: rateMax,
            rate_min: rateMin,
        };
        setRequestData(data);
    }

    return (
        <Tab tabID="custom_settings" tabActiveID={tabActiveID} isLoading={isLoading}
             title={"Settings for: country - " + segment.country_code + "; account - " + segment.account_name + "; zone - " + segment.zone_name}>
            <form onSubmit={handleSubmit}>
                {
                    updateData && <AlertSuccess message="Data updated successfully"/>
                }

                {/*<div>{JSON.stringify(segment)}</div>*/}

                <div className="form-group">
                    <label htmlFor="inputRateDefault">Default rate</label>
                    <input type="text" id="inputRateDefault"
                           placeholder="default rate"
                           className={"form-control " + (errorRateDefault.length > 0 ? "is-invalid" : '')}
                           {...bindRateDefault}/>
                    <div className="invalid-feedback">{errorRateDefault}</div>
                </div>

                <div className="form-group">
                    <label htmlFor="inputRateMax">Max rate</label>
                    <input type="text" id="inputRateMax"
                           placeholder="max rate"
                           className={"form-control " + (errorRateMax.length > 0 ? "is-invalid" : '')}
                           {...bindRateMax}/>
                    <div className="invalid-feedback">{errorRateMax}</div>
                </div>

                <div className="form-group">
                    <label htmlFor="inputRateMin">Min rate</label>
                    <input type="text" id="inputRateMin"
                           placeholder="min rate"
                           className={"form-control " + (errorRateMin.length > 0 ? "is-invalid" : '')}
                           {...bindRateMin}/>
                    <div className="invalid-feedback">{errorRateMin}</div>
                </div>

                <ButtonSubmit isLoading={updateIsLoading} text="Save changes"/>
            </form>
        </Tab>
    )
}

SegmentDefaultsEditFormTabSettings.propTypes = {
    tabActiveID: PropTypes.string.isRequired,
}
