import React, {useEffect, useState} from 'react';
import axios from "axios";

import AlertSuccess from "../../../../components/AlertSuccess";
import SelectMulti from "../../../../components/select/SelectMulti";
import ButtonSubmit from "../../../../components/ButtonSubmit";
import {useCampaignCreate} from "./DataCreate";
import {useCheckboxInput, useInput, useSelect} from "../../../../../components/input-hook";
import ajax from "../../../../../data/ajax";
import {SelectDataFormatter, UrlCreator} from "../../../../../components/select_data";
import {consoleLogResponse, Dump, isDebug} from "../../../../../components/development";
import Card from "../../../../components/Сard";
import Container from "../../../../components/Container";
import LoadingWrapper from "../../../../components/LoadingWrapper";
import SelectSingle from "../../../../components/select/SelectSingle";
import DivFormGroupRow from "../../../../components/form/DivFormGroupRow";
import DivError from "../../../../components/form/DivError";
import DivFormGroupRowCheckBox from "../../../../components/form/DivFormGroupRowCheckBox";
import {getCampaignUpdateIntervalList} from "../../../../../data/static_data";
import {round} from "../../../../../components/Math";
import {ArrayUnique, strToArray} from "../../../../../components/helpers";

export default function ExStatCampaignClickaduCreateForm() {
    const defaultValues = {
        url: "https://play.playvideolife.com/lnk17_5wv3.html?ymid=${SUBID}&zoneid=6042377&sourceid={zoneid}__{campaignid}&tt=3&tbzone=5838896&cost={cost}&geo={geo}",
        sourceZoneslist: ['1890436', '1692206', '1658615', '1780021', '1754564', '1801441', '1791726', '1776543',
            '1889347', '1898943', '1822842', '1892598', '1881927', '1783372', '1854847', '1898079', '1909807',
            '1882176', '1352060', '1894496', '1884922', '1845003'
        ],
        updateInterval: '5m',
    }

    const [geoPresets, setGeoPresets] = useState({});

    const [isLoading, setIsLoading] = useState(false);

    const [{
        data: formData,
        setFormData,
        isLoading: updateIsLoading,
        error: updateError,
        dataErrors
    }] = useCampaignCreate();

    const inputCampUrl = useInput(defaultValues.url);
    const inputCampBudgetDailyAmount = useInput('');
    const inputCampBudgetTotalAmount = useInput('');
    const inputCampUserFrequency = useInput('');
    const inputCampUserCapping = useInput('');
    const inputCampPrice = useInput('');
    const inputCampZones = useInput(defaultValues.sourceZoneslist.join(', '));

    const inputBaseCampaign = useSelect();
    const inputCampFeed = useSelect();
    const inputCampTrafficVertical = useSelect();
    const inputCampConnection = useSelect();
    const inputCampProxy = useSelect();
    const inputUpdateInterval = useSelect(defaultValues.updateInterval);

    const inputCountries = useSelect([]);
    const inputCampOsTypes = useSelect([]);
    const inputCampOs = useSelect([]);
    const inputCampOsVersions = useSelect([]);
    const inputCampDeviceTypes = useSelect([]);
    const inputCampDeviceVendors = useSelect([]);
    const inputCampDeviceModels = useSelect([]);
    const inputCampMobileISP = useSelect([]);
    const inputCampBrowsers = useSelect([]);
    const inputCampBrowserLanguage = useSelect([]);
    const inputCampBrowserVersions = useSelect([]);

    const inputCampOsTypesIsExcluded = useCheckboxInput(false);
    const inputCampOsIsExcluded = useCheckboxInput(false);
    const inputCampOsVersionsIsExcluded = useCheckboxInput(false);
    const inputCampDeviceTypesIsExcluded = useCheckboxInput(false);
    const inputCampDeviceVendorsIsExcluded = useCheckboxInput(false);
    const inputCampDeviceModelsIsExcluded = useCheckboxInput(false);
    const inputCampMobileISPIsExcluded = useCheckboxInput(false);
    const inputCampBrowsersIsExcluded = useCheckboxInput(false);
    const inputCampBrowserLanguageIsExcluded = useCheckboxInput(false);
    const inputCampBrowserVersionsIsExcluded = useCheckboxInput(false);
    const inputCampZonesIsExcluded = useCheckboxInput(false);

    useEffect(() => {
        const fetchData = async () => {
            const promises = [
                axios.get(UrlCreator.clickadu_targeting(), ajax.getBaseConfig()),
                axios.get(UrlCreator.campaigns({account_id: 1, data_type: 3}), ajax.getBaseConfig()),
            ];
            Promise.all(promises).then(function (results) {
                consoleLogResponse(results)
                const d = results[0].data;

                /**
                 * @deprecated see fSelectable
                 */
                const filterSelectable = a => {
                    return a.filter(v => v['isSelectable'] === 1);
                }

                const fSelectable = v => v['isSelectable'] === 1;

                const filterCountry = (parent = "") => {
                    let list = d['country'];
                    if (parent !== "") {
                        list = list.filter(v => v['parent'] === parent)
                    }

                    return list.filter(fSelectable).map(v => v['id'].toLowerCase());
                }

                const presets = {
                    "Imp": ['IN','VN','US','ID','BR','JP',/*'TR',*/'PH','IQ','TH','DE','KR','BD','MY','SA','EG','IT','FR','MX','PK','GB','CN','DZ','RO','CA','SG','TW','AR','ES',/*'RU',*/'NL','PL','CO','PE','AU','AE','CL','NP','YE','MA','LY','ZA','HK','NG','SY','AZ','LK','KW','KH',/*'UA',*/'JO','VE','EC','SE','OM','PT','HU','IL','UZ','MM','LB','BE','SD','BO','BG','CZ','GR','TN','AT','CH','QA','GT','FI','IR','TZ','KZ','NO','DO','IE','RS','CR','AF','PS','ET','GH','NZ','BH','SV','PY','DK','SK','UY','UG','PA','SO','HR','LA','KE','KG','NI','HN',/*'BY',*/'LU','MN','CU','MD',/*'EU',*/'GE','MU','CD','PR','AL','AO','BN','CI','SN','CY','LT','ZM','BA','MR','ML','CM','A1','SI','JM','AM','MV','TJ','MZ','TT','MK','PG','LV','RE','MO','MW','MT','HT','ZW','EE','BT','TD','ME','NE'],
                }

                const chunk = (arr, size) => Array.from({length: Math.ceil(arr.length / size)}, (v, i) =>
                    arr.slice(i * size, i * size + size)
                );
                const chunkedPresets = {
                    "Asia": filterCountry('as'),
                    "Africa": filterCountry('af'),
                    "North America": filterCountry('na'),
                    "South America": filterCountry('sa'),
                    "Europe": filterCountry('eu'),
                    "Oceania": filterCountry('oc'),
                    "WW": filterCountry(),
                }
                for (const [key, value] of Object.entries(presets)) {
                    chunk(value, 10).forEach((chv, chi) => {
                        chunkedPresets[key+"_"+chi] = chv
                    });
                }
                setGeoPresets(chunkedPresets)

                // const filteredCampaignsList = ['2261429'];
                // const filteredCampaigns = results[1].data.filter(v => filteredCampaignsList.indexOf(v['origin_campaign_id']) !== -1)
                inputBaseCampaign.setOptions(SelectDataFormatter.campaignsIdOrigin(results[1].data));
                inputCampFeed.setOptions(SelectDataFormatter.id_title(
                    ['main', 'adult', 'all'].map(i => {
                        return {id: i, title: i.charAt(0).toUpperCase() + i.slice(1)}
                    })
                ));
                inputCampTrafficVertical.setOptions(SelectDataFormatter.id_title(d['campaignTrafficVertical']));
                inputCampConnection.setOptions(SelectDataFormatter.id_title(d['connection']));
                inputCampProxy.setOptions(SelectDataFormatter.id_title([{id: 'all', title: 'All'}].concat(d['proxy'])));
                inputUpdateInterval.setOptions(getCampaignUpdateIntervalList());

                inputCountries.setOptions(SelectDataFormatter.id_id_title(
                    filterSelectable(d['country'])
                        .sort((a, b) => (a.id > b.id) ? 1 : -1)
                ))

                inputCampOsTypes.setOptions(SelectDataFormatter.id_title(
                    filterSelectable(d['osType'])
                ));
                inputCampOs.setOptions(SelectDataFormatter.id_title(
                    filterSelectable(d['os'])
                ));
                inputCampOsVersions.setOptions(SelectDataFormatter.id_id_title(
                    filterSelectable(d['osVersion'])
                        // .filter(v => v['parent'] == 'android')
                        .sort((a, b) => (a.title > b.title) ? 1 : -1)
                ));
                inputCampDeviceTypes.setOptions(SelectDataFormatter.id_title(
                    filterSelectable(d['deviceType'])
                ));
                inputCampDeviceVendors.setOptions(SelectDataFormatter.id_title(
                    filterSelectable(d['vendor'])
                ));
                inputCampDeviceModels.setOptions(SelectDataFormatter.id_title(
                    filterSelectable(d['model'])
                ));
                inputCampMobileISP.setOptions(SelectDataFormatter.id_title(
                    filterSelectable(d['mobileIsp'])
                        // .filter(v => v['parent'] === country)
                        .sort((a, b) => (a.title > b.title) ? 1 : -1)
                ));
                inputCampBrowsers.setOptions(SelectDataFormatter.id_title(
                    filterSelectable(d['browser'])
                        .sort((a, b) => (a.title > b.title) ? 1 : -1)
                ));
                inputCampBrowserLanguage.setOptions(SelectDataFormatter.id_title(
                    filterSelectable(d['language'])
                        .sort((a, b) => (a.title > b.title) ? 1 : -1)
                ));
                inputCampBrowserVersions.setOptions(SelectDataFormatter.id_title(
                    filterSelectable(d['browserVersion'])
                        // .filter(v => v['title'].startsWith("chrome", 0))
                        .sort((a, b) => (a.title > b.title) ? 1 : -1)
                ));

                setIsLoading(false);
            });
        };

        fetchData();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            base_campaign : inputBaseCampaign.value,
            url: inputCampUrl.value,
            price: inputCampPrice.value,
            countries: inputCountries.value,

            camp_feed: inputCampFeed.value,
            camp_traffic_vertical: inputCampTrafficVertical.value,
            camp_budget_daily_amount: inputCampBudgetDailyAmount.value,
            camp_budget_total_amount: inputCampBudgetTotalAmount.value,
            camp_user_frequency: inputCampUserFrequency.value,
            camp_user_capping: Math.floor(inputCampUserCapping.value) * 3600,

            camp_connection: inputCampConnection.value,
            camp_os_types: inputCampOsTypes.value,
            camp_os_types_is_excluded: inputCampOsTypesIsExcluded.value,
            camp_os: inputCampOs.value,
            camp_os_is_excluded: inputCampOsIsExcluded.value,
            camp_os_versions: inputCampOsVersions.value,
            camp_os_versions_is_excluded: inputCampOsVersionsIsExcluded.value,
            camp_device_types: inputCampDeviceTypes.value,
            camp_device_types_is_excluded: inputCampDeviceTypesIsExcluded.value,
            camp_device_vendors: inputCampDeviceVendors.value,
            camp_device_vendors_is_excluded: inputCampDeviceVendorsIsExcluded.value,
            camp_device_models: inputCampDeviceModels.value,
            camp_device_models_is_excluded: inputCampDeviceModelsIsExcluded.value,
            camp_mobile_isp: inputCampMobileISP.value,
            camp_mobile_isp_is_excluded: inputCampMobileISPIsExcluded.value,
            camp_browsers: inputCampBrowsers.value,
            camp_browsers_is_excluded: inputCampBrowsersIsExcluded.value,
            camp_browser_language: inputCampBrowserLanguage.value,
            camp_browser_language_is_excluded: inputCampBrowserLanguageIsExcluded.value,
            camp_browser_versions: inputCampBrowserVersions.value,
            camp_browser_versions_is_excluded: inputCampBrowserVersionsIsExcluded.value,
            camp_proxy: inputCampProxy.value,

            data_update_time: inputUpdateInterval.value,
            source_zones: ArrayUnique(strToArray(inputCampZones.value)),
            source_zones_exclude: inputCampZonesIsExcluded.value,
        };
        setFormData(data);
    }

    return (
        <Container title={"Create Clickadu campaigns"}>
            <Card>
                <LoadingWrapper isLoaded={!isLoading}>
                    <form onSubmit={handleSubmit}>
                        {
                            formData && <AlertSuccess message={JSON.stringify(formData, null, 2)}/>
                        }

                        <DivFormGroupRow label="Base campaign">
                            <SelectSingle {...inputBaseCampaign.bind}/>
                            <DivError error={inputBaseCampaign.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Url" labelAttributes={{htmlFor: "inputCampUrl"}}>
                            <input
                                type="text"
                                id="inputCampUrl"
                                placeholder="Url"
                                className={"form-control " + (inputCampUrl.error.length > 0 ? "is-invalid" : '')}
                                {...inputCampUrl.bind}
                            />
                            <DivError error={inputCampUrl.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Price" labelAttributes={{htmlFor: "inputCampPrice"}}>
                            <input
                                type="text"
                                id="inputCampPrice"
                                placeholder="Price"
                                className={"form-control " + (inputCampPrice.error.length > 0 ? "is-invalid" : '')}
                                {...inputCampPrice.bind}
                            />
                            <DivError error={inputCampPrice.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Countries">
                            <SelectMulti {...inputCountries.bind}/>
                            {Object.keys(geoPresets).length > 0 && <div>
                                <b>Presets: </b>
                                { Object.entries(geoPresets).map(([key,value],i) => <span key={i}>
                                    {i!==0 && <>&nbsp;/&nbsp;</> }

                                    <a onClick={() => {
                                        if (key in geoPresets) {
                                            inputCountries.setValue(geoPresets[key].map(e => e.toLowerCase()))
                                        }
                                        return false;
                                    }}>{key}</a>
                                </span>)}
                            </div>}
                            <DivError error={inputCountries.error}/>
                        </DivFormGroupRow>

                        <hr/>

                        <DivFormGroupRow label="Feed New">
                            <SelectSingle {...inputCampFeed.bind}/>
                            <DivError error={inputCampFeed.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Vertical">
                            <SelectSingle {...inputCampTrafficVertical.bind}/>
                            <DivError error={inputCampTrafficVertical.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Budget daily (USD)" labelAttributes={{htmlFor: "inputCampBudgetDailyAmount"}}>
                            <input
                                type="text"
                                id="inputCampBudgetDailyAmount"
                                className={"form-control " + (inputCampBudgetDailyAmount.error.length > 0 ? "is-invalid" : '')}
                                {...inputCampBudgetDailyAmount.bind}
                            />
                            <DivError error={inputCampBudgetDailyAmount.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Budget total (USD)" labelAttributes={{htmlFor: "inputCampBudgetTotalAmount"}}>
                            <input
                                type="text"
                                id="inputCampBudgetTotalAmount"
                                className={"form-control " + (inputCampBudgetTotalAmount.error.length > 0 ? "is-invalid" : '')}
                                {...inputCampBudgetTotalAmount.bind}
                            />
                            <DivError error={inputCampBudgetTotalAmount.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="User Frequency" labelAttributes={{htmlFor: "inputCampUserFrequency"}}>
                            <input
                                type="text"
                                id="inputCampUserFrequency"
                                className={"form-control " + (inputCampUserFrequency.error.length > 0 ? "is-invalid" : '')}
                                {...inputCampUserFrequency.bind}
                            />
                            <DivError error={inputCampUserFrequency.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="User Capping (h)" labelAttributes={{htmlFor: "inputCampUserCapping"}}>
                            <input
                                type="text"
                                id="inputCampUserCapping"
                                className={"form-control " + (inputCampUserCapping.error.length > 0 ? "is-invalid" : '')}
                                {...inputCampUserCapping.bind}
                            />
                            <DivError error={inputCampUserCapping.error}/>
                        </DivFormGroupRow>

                        <hr/>

                        <DivFormGroupRow label="Connection">
                            <SelectSingle {...inputCampConnection.bind}/>
                            <DivError error={inputCampConnection.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Os Types">
                            <SelectMulti {...inputCampOsTypes.bind}/>
                            <DivError error={inputCampOsTypes.error}/>

                            <DivFormGroupRowCheckBox
                                bind={inputCampOsTypesIsExcluded.bind}
                                error={inputCampOsTypesIsExcluded.error}
                            />
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Os">
                            <SelectMulti {...inputCampOs.bind}/>
                            <DivError error={inputCampOs.error}/>

                            <DivFormGroupRowCheckBox
                                bind={inputCampOsIsExcluded.bind}
                                error={inputCampOsIsExcluded.error}
                            />
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Os Versions">
                            <SelectMulti {...inputCampOsVersions.bind}/>
                            <div>
                                <b>Presets: </b>
                                <a onClick={() => {
                                    inputCampOs.setValue(ArrayUnique(inputCampOs.value.concat(['android'])));

                                    let startIndex = 1;
                                    let list = [];
                                    const versions = inputCampOsVersions.options
                                        .filter(v => v['value'].startsWith("android", 0))
                                        .map(e => e.value);

                                    while (true) {
                                        if (versions.indexOf('android' + startIndex) !== -1) {
                                            list.push('android' + startIndex)
                                        } else {
                                            break;
                                        }
                                        startIndex++;
                                        if (startIndex > 1000) {
                                            break;
                                        }
                                    }

                                    inputCampOsVersions.setValue(list.slice(-7))

                                    // console.log('listBrowserVersions', listBrowserVersions)
                                    // console.log('versions', versions)
                                    // console.log('list', list)

                                    return false;
                                }}>Android latest 7</a>
                            </div>
                            <DivError error={inputCampOsVersions.error}/>

                            <DivFormGroupRowCheckBox
                                bind={inputCampOsVersionsIsExcluded.bind}
                                error={inputCampOsVersionsIsExcluded.error}
                            />
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Device Types">
                            <SelectMulti {...inputCampDeviceTypes.bind}/>
                            <DivError error={inputCampDeviceTypes.error}/>

                            <DivFormGroupRowCheckBox
                                bind={inputCampDeviceTypesIsExcluded.bind}
                                error={inputCampDeviceTypesIsExcluded.error}
                            />
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Device Vendors">
                            <SelectMulti {...inputCampDeviceVendors.bind}/>
                            <DivError error={inputCampDeviceVendors.error}/>

                            <DivFormGroupRowCheckBox
                                bind={inputCampDeviceVendorsIsExcluded.bind}
                                error={inputCampDeviceVendorsIsExcluded.error}
                            />
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Device Models">
                            <SelectMulti {...inputCampDeviceModels.bind}/>
                            <DivError error={inputCampDeviceModels.error}/>

                            <DivFormGroupRowCheckBox
                                bind={inputCampDeviceModelsIsExcluded.bind}
                                error={inputCampDeviceModelsIsExcluded.error}
                            />
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Mobile ISP">
                            <SelectMulti {...inputCampMobileISP.bind}/>
                            <DivError error={inputCampMobileISP.error}/>

                            <DivFormGroupRowCheckBox
                                bind={inputCampMobileISPIsExcluded.bind}
                                error={inputCampMobileISPIsExcluded.error}
                            />
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Browsers">
                            <SelectMulti {...inputCampBrowsers.bind}/>
                            <DivError error={inputCampBrowsers.error}/>

                            <DivFormGroupRowCheckBox
                                bind={inputCampBrowsersIsExcluded.bind}
                                error={inputCampBrowsersIsExcluded.error}
                            />
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Browser Language">
                            <SelectMulti {...inputCampBrowserLanguage.bind}/>
                            <DivError error={inputCampBrowserLanguage.error}/>

                            <DivFormGroupRowCheckBox
                                bind={inputCampBrowserLanguageIsExcluded.bind}
                                error={inputCampBrowserLanguageIsExcluded.error}
                            />
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Browser Versions">
                            <SelectMulti {...inputCampBrowserVersions.bind}/>
                            <div>
                                <b>Presets: </b>
                                <a onClick={() => {
                                    inputCampBrowsers.setValue(ArrayUnique(inputCampBrowsers.value.concat(['chrome'])));
                                    inputCampBrowserVersionsIsExcluded.setValue(false);

                                    // @TODO startIndex = 1 stop if not found 10 times
                                    let startIndex = 25;
                                    let list = [];
                                    const versions = inputCampBrowserVersions.options
                                        .filter(v => v['value'].startsWith("chrome", 0))
                                        .map(e => e.value);

                                    while (true) {
                                        if (versions.indexOf('chrome' + startIndex) !== -1) {
                                            list.push('chrome' + startIndex)
                                        } else {
                                            break;
                                        }
                                        startIndex++;
                                        if (startIndex > 1000) {
                                            break;
                                        }
                                    }
                                    inputCampBrowserVersions.setValue(list.slice(-20))

                                    return false;
                                }}>Chrome latest 20</a>
                                &nbsp;/&nbsp;
                                <a onClick={() => {
                                    inputCampBrowsers.setValue(ArrayUnique(inputCampBrowsers.value.concat(['chrome'])));
                                    inputCampBrowserVersionsIsExcluded.setValue(true);

                                    let startIndex = 0;
                                    let list = [];
                                    const versions = inputCampBrowserVersions.options
                                        .filter(v => v['value'].startsWith("chrome", 0))
                                        .map(e => e.value);

                                    while (true) {
                                        if (versions.indexOf('chrome' + startIndex) !== -1) {
                                            list.push('chrome' + startIndex)
                                        }
                                        startIndex++;
                                        if (startIndex > 105) {
                                            break;
                                        }
                                    }
                                    inputCampBrowserVersions.setValue(list);

                                    return false;
                                }}>Exclude Chrome before v106</a>
                            </div>
                            <DivError error={inputCampBrowserVersions.error}/>

                            <DivFormGroupRowCheckBox
                                bind={inputCampBrowserVersionsIsExcluded.bind}
                                error={inputCampBrowserVersionsIsExcluded.error}
                            />
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Proxy">
                            <SelectSingle {...inputCampProxy.bind}/>
                            <DivError error={inputCampProxy.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Source Zones" labelAttributes={{htmlFor: "inputSourceZones"}}>
                             <textarea
                                 id="inputSourceZones"
                                 placeholder="Source Zones"
                                 className={"form-control " + (inputCampZones.error.length > 0 ? "is-invalid" : '')}
                                 {...inputCampZones.bind}
                             ></textarea>
                            <DivError error={inputCampZones.error}/>

                            <DivFormGroupRowCheckBox
                                bind={inputCampZonesIsExcluded.bind}
                                error={inputCampZonesIsExcluded.error}
                            />
                        </DivFormGroupRow>

                        <hr/>

                        <DivFormGroupRow label="Update interval">
                            <SelectSingle {...inputUpdateInterval.bind}/>
                            <DivError error={inputUpdateInterval.error}/>
                        </DivFormGroupRow>

                        <div className="form-group">
                            <ButtonSubmit isLoading={updateIsLoading} text="Create"/>
                        </div>
                    </form>

                </LoadingWrapper>
            </Card>

            {isDebug() && <Card><Dump value={true}/></Card>}
        </Container>
    )
}
