import React from "react";
import {Link} from "react-router-dom";
import * as Icon from "react-feather";

export default function PostbackSetupCustomListTableParams() {
    const keyZoneId = 'zone_id';
    const keyOriginZoneId = 'origin_zone_id';

    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Code (tsc)',
                accessor: 'uid',
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Advertiser',
                accessor: 'account_name',
            },
            {
                Header: 'Acc Code (tac)',
                accessor: 'account_code',
            },
            {
                Header: 'Forward Percentage',
                accessor: 'forward_percentage',
            },
            {
                Header: 'Margin Percentage',
                accessor: 'margin_percentage',
            },
            {
                Header: 'Rates',
                accessor: 'rate_table',
                Cell: ({row}) => {
                    return row.original['rate_table'] === null ? <></> : <Icon.Database />
                },
            },
            {
                accessor: 'button_edit',
                className: 'min',
                Cell: ({row}) => <Link to={"/postback-setup/custom/edit/" + row.original['id']}><Icon.Settings /></Link>,
            },
        ],
        []
    );

    const initialState = {
        sortBy: [
            {
                id: keyOriginZoneId,
                desc: true
            }
        ]
    }

    return [{
        columns,
        initialState
    }];
}
