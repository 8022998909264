import React from "react";

import DataData from "../../../../components/DataData";
import {formulaProfit, formulaROI, roundPercent, tableColumnSum} from "../../../../components/table/TableListFunctions";
import TableTdCompare from "../../../components/table/TableTdCompare";
import {
    compareCell,
    compareCellDate,
    compareCellMoney,
    compareFooterMoney,
    compareFooterROI
} from "../../../components/table/helpers";

export default function ExStatDashboardTableParams() {
    const keyDate = 'date';
    const keyManagerGroupID = 'manager_group';
    const keyManagerGroupName = 'manager_group_name';
    const keyManagerID = 'manager';
    const keyManagerName = 'manager_email';
    const keyAccountID = 'account';
    const keyAccountName = 'account_name';
    const keyPubAccountID = 'pub_account';
    const keyPubAccountName = 'pub_account_name';
    const keyZoneID = 'origin_zone_id';
    const keyZoneName = 'zone_name';
    const keyCampaignID = 'origin_campaign_id';
    const keyCampaignName = 'campaign_name';
    const keyCountry = 'country';
    const keyType = 'type';
    const keyTypeName = 'type_name';

    const columns = React.useMemo(
        () => {
            return [
                {
                    Header: 'Date',
                    accessor: keyDate,
                    Cell: compareCellDate(keyDate),
                    Footer: <b>Total:</b>,
                },
                {
                    Header: 'Country',
                    accessor: keyCountry,
                    Footer: <b>Total:</b>,
                },
                {
                    Header: 'Manager Group ID',
                    accessor: keyManagerGroupID,
                },
                {
                    Header: 'Manager Group',
                    accessor: keyManagerGroupName,
                },
                {
                    Header: 'Manager ID',
                    accessor: keyManagerID,
                },
                {
                    Header: 'Manager',
                    accessor: keyManagerName,
                },
                {
                    Header: 'Traffic source ID',
                    accessor: keyAccountID,
                },
                {
                    Header: 'Traffic source',
                    accessor: keyAccountName,
                },
                {
                    Header: 'Aff. network ID',
                    accessor: keyPubAccountID,
                },
                {
                    Header: 'Affiliate network',
                    accessor: keyPubAccountName,
                },
                {
                    Header: 'Zone ID',
                    accessor: keyZoneID,
                },
                {
                    Header: 'Zone',
                    accessor: keyZoneName,
                },
                {
                    Header: 'Campaign ID',
                    accessor: keyCampaignID,
                },
                {
                    Header: 'Campaign',
                    accessor: keyCampaignName,
                },
                {
                    Header: 'Type',
                    accessor: keyType,
                },
                {
                    Header: 'Type Name',
                    Cell: ({row: {original: o}}) => DataData.typeName(o['type']),
                    accessor: keyTypeName,
                },
                {
                    Header: 'Tests , $',
                    accessor: 'cost_test',
                    className: 'text-right',
                    sortType: 'basic',
                    Cell: compareCellMoney('cost_test'),
                    Footer: compareFooterMoney('cost_test'),
                },
                {
                    Header: 'Cost, $',
                    accessor: 'cost',
                    className: 'text-right',
                    sortType: 'basic',
                    Cell: compareCellMoney('cost'),
                    Footer: compareFooterMoney('cost'),
                },
                {
                    Header: 'Cost (total), $',
                    accessor: 'cost_total',
                    className: 'text-right',
                    sortType: 'basic',
                    Cell: compareCellMoney('cost_total'),
                    Footer: compareFooterMoney('cost_total'),
                },
                {
                    Header: 'Revenue, $',
                    accessor: 'revenue_tb',
                    className: 'text-right',
                    sortType: 'basic',
                    Cell: compareCellMoney('revenue_tb'),
                    Footer: compareFooterMoney('revenue_tb'),
                },
                {
                    Header: 'Profit, $',
                    accessor: 'profit_tb',
                    className: 'text-right',
                    sortType: 'basic',
                    Cell: compareCellMoney('profit_tb'),
                    Footer: compareFooterMoney('profit_tb'),
                },
                {
                    Header: 'ROI, %',
                    accessor: 'total_roi',
                    className: 'text-right',
                    sortType: 'basic',
                    Cell: compareCell('total_roi', roundPercent),
                    Footer: compareFooterROI('revenue_tb', 'cost_total'),
                },
            ]
        }, []
    );

    const initialState = {
        sortBy: [
            {
                id: keyDate,
                desc: true
            }
        ]
    }

    return {
        keyDate,
        keyManagerGroupID,
        keyManagerGroupName,
        keyManagerID,
        keyManagerName,
        keyAccountID,
        keyAccountName,
        keyZoneID,
        keyZoneName,
        keyCampaignID,
        keyCampaignName,
        keyCountry,
        keyType,
        keyTypeName,
        keyPubAccountID,
        keyPubAccountName,
        columns,
        initialState,
    };
}
