import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";

import Container from "../../../components/Container";
import Card from "../../../components/Сard";
import Table from "../../../components/table/TablePaginated";
import TableParams from "./ListTableParams";
import ajax from "../../../../data/ajax";
import Loading from "../../../components/Loading";
import {SelectDataFormatter, UrlCreator} from "../../../../components/select_data";
import {consoleLogResponse} from "../../../../components/development";
import FilterGroupBy from "../../../components/FilterGroupBy";
import {useInput} from "../../../../components/input-hook";
import BackendRequest from "../../../../data/BackendRequest";
import SelectMulti from "../../../components/select/SelectMulti";
import {DATA_TYPE_PUSH, DATA_TYPE_SMARTLINK} from "../../../../components/const_data_type";

const ExStatCampaignList = ({user}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    const [{
        columns: tableColumns,
        initialState: tableInitialState,
    }] = TableParams();

    const inputUrlPart = useInput('');

    const [direction, setDirection] = useState(1);

    const [filterAccount, setFilterAccount] = useState('');
    const [filterZone, setFilterZone] = useState('');
    const [filterButton, setFilterButton] = useState(0);

    const [listAccount, setListAccount] = useState([]);
    const [listZone, setListZone] = useState([]);

    const [{data, isLoading}, setUrlParams] = BackendRequest("ex-stat/campaign");

    const [listZones1, setListZones1] = useState([]);
    const [listZones2, setListZones2] = useState([]);

    useEffect(() => {
        setUrlParams({});

        const fetchData = async () => {
            const requestConfig = ajax.getBaseConfig();
            const promises = [
                axios.get(UrlCreator.zones({'data_type': DATA_TYPE_PUSH}), requestConfig),
                axios.get(UrlCreator.zones({'data_type': DATA_TYPE_SMARTLINK}), requestConfig),
            ];
            Promise.all(promises).then(function (results) {
                consoleLogResponse(results);

                const accounts = SelectDataFormatter.accounts(user.getTrafficSourceAccounts())
                setListAccount(accounts);

                const z = SelectDataFormatter.zones(results[0].data);
                setListZones1(z);
                setListZones2(SelectDataFormatter.zones(results[1].data));

                setListZone(z);

                setIsLoaded(true);
            });
        };

        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoaded) {
            if (direction === 1) {
                setListZone(listZones1);
            } else {
                setListZone(listZones2);
            }
        }
    }, [direction]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let params = {
            account: filterAccount,
            zone: filterZone,
            url_part: inputUrlPart.value
        }

        setUrlParams(params)
    }, [filterAccount, filterZone, filterButton]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container title={"Campaigns"} isLoaded={isLoaded} error={error}>
            <Card>
                <div className="row">
                    <div className="col-md-3">
                        <b>Select traffic source</b>
                        <SelectMulti value={filterAccount} updateValue={v => setFilterAccount(v)} options={listAccount}/>
                    </div>
                    <div className="col-md-6">
                        <b>Select zone</b>
                        <div className="row">
                            <div className="col-md-3">
                                <FilterGroupBy
                                    label={""}
                                    items={[{title: "Push", key: 1}, {title: "TB", key: 2}]}
                                    value={direction}
                                    updateValue={v => setDirection(v)}
                                />
                            </div>
                            <div className="col-md-9">
                                <SelectMulti value={filterZone} updateValue={v => setFilterZone(v)} options={listZone}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <b>Url part</b>
                        <input
                            type="text"
                            placeholder="url part"
                            className={"form-control"}
                            {...inputUrlPart.bind}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary" onClick={e => {
                            const t = Date.now();
                            setFilterButton(t)
                        }}>Show Results
                        </button>
                    </div>
                </div>
            </Card>
            <Card>
                {
                    isLoading
                    && <Loading/>
                }
                {
                    !isLoading
                    && <Table columns={tableColumns} data={data} initialState={tableInitialState}/>
                }
            </Card>
        </Container>
    );
}

export default ExStatCampaignList;
