import React, {useState, useEffect} from "react";
import styled from 'styled-components'

const Styled = styled.div`
  label {
    margin-right: 10px;
  }
  button {
    margin-right: 10px;
  }
  button:last-child {
    margin-right: 0;
  }
`

export default function FilterGroupByMulti({items, value, updateValue}) {
    const [currentVal, setCurrentVal] = useState(value);

    useEffect(() => {
        updateValue(currentVal);
    }, [currentVal]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Styled>
            <label className="form-label">Group by (multi with SHIFT):</label>
            {items.filter(i => i.visible !== false).map(item => (
                <button
                    key={item.key}
                    className={"btn " + ([...currentVal].includes(item.key) ? "btn-primary" : "btn-outline-primary")}
                    onClick={(e) => {
                        if (e.shiftKey) {
                            if ([...currentVal].includes(item.key)) {
                                return setCurrentVal(currentVal.filter(e => e !== item.key));
                            } else {
                                return setCurrentVal([...currentVal, item.key]);
                            }
                        }
                        return setCurrentVal([item.key])
                    }}
                >{item.title}</button>
            ))}
        </Styled>
    )
}