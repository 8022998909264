const DIRECTION_PUSH = '72';
const DIRECTION_TB = '73';

const REVENUE_MODEL_REV_SHARE = '1';
const REVENUE_MODEL_CPS = '7';

const toString = (v) => v + ''

const directionName = (value) => {
    switch (toString(value)) {
        case DIRECTION_TB:
            return 'TB';
        case DIRECTION_PUSH:
            return 'Push';
        default:
            return 'Unknown (' + value + ')';
    }
}

const revenueModelName = (value) => {
    switch (toString(value)) {
        case REVENUE_MODEL_REV_SHARE:
            return 'Rev. share';
        case REVENUE_MODEL_CPS:
            return 'CPS';
        default:
            return 'Unknown (' + value + ')';
    }
}

const isPushDirection = (value) => toString(value) === DIRECTION_PUSH;
const isTbDirection = (value) => toString(value) === DIRECTION_TB;

const ZoneData = {
    DIRECTION_PUSH,
    DIRECTION_TB,
    REVENUE_MODEL_CPS,
    REVENUE_MODEL_REV_SHARE,
    directionName,
    revenueModelName,
    isPushDirection,
    isTbDirection,
}

export default ZoneData;
