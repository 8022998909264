const DATA_STATUS_LOADED = 0;
const DATA_STATUS_WAIT_RELEASE = 1; // нет данных об аттрибутах
const DATA_STATUS_NEED_LOAD = 2; // загрузка данных ассетов (токенов)
const DATA_STATUS_NEED_CALC = 3; // calc RANK
const DATA_STATUS_LOAD_PRICE = 4; // load price

function getDataStatesList() {
    return [
        {value: DATA_STATUS_LOADED, label: 'Loaded'},
        {value: DATA_STATUS_WAIT_RELEASE, label: 'Wait data'},
        {value: DATA_STATUS_NEED_LOAD, label: 'Load data'},
        {value: DATA_STATUS_NEED_CALC, label: 'Calc rank'},
        {value: DATA_STATUS_LOAD_PRICE, label: 'Load prices'},
    ];
}

function getDataStateLabel(state) {
    let label = 'Unknown';
    getDataStatesList().forEach(v => {
        if (state * 1 === v.value) {
            label = v.label;
        }
    });

    return label;
}

export default {getDataStatesList, getDataStateLabel, DATA_STATUS_NEED_LOAD};
