const key = 'token'

const get = () => {
    return localStorage.getItem(key)
}

const set = (value) => {
    localStorage.setItem(key, value);
}

const remove = () => {
    localStorage.removeItem(key)
}

const TokenStorage = {
    get,
    set,
    remove
}

export default TokenStorage;
