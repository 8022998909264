import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import Tab from "./../../Settings/Tab";
import ButtonSubmit from "../../../components/ButtonSubmit";
import AlertSuccess from "../../../components/AlertSuccess";
import {useInput} from "../../../../components/input-hook";
import {useCollectionAdd} from "./DataAddCollection";

export default function AddFormTabMain({tabActiveID}) {
    const isLoading = false;

    const [{
        data: formData,
        setFormData,
        isLoading: updateIsLoading,
        error: updateError,
        dataErrors
    }] = useCollectionAdd();

    const {
        value: slug,
        setValue: setSlug,
        error: errorSlug,
        setError: setErrorSlug,
        bind: bindSlug
    } = useInput('');

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            slug: slug,
        };
        setFormData(data);
    }

    return (
        <Tab tabID="main" tabActiveID={tabActiveID} isLoading={isLoading} title="Main">
            <form onSubmit={handleSubmit}>
                {
                    formData && <AlertSuccess message="Data save successfully"/>
                }

                <div className="form-group">
                    <label htmlFor="inputSlug">Slug</label>
                    <input type="text" id="inputSlug"
                           placeholder="Collection slug"
                           className={"form-control " + (errorSlug.length > 0 ? "is-invalid" : '')}
                           {...bindSlug}/>
                    <div className="invalid-feedback">{errorSlug}</div>
                </div>

                <ButtonSubmit isLoading={updateIsLoading} text="Add"/>
            </form>
        </Tab>
    )
}

AddFormTabMain.propTypes = {
    tabActiveID: PropTypes.string.isRequired,
}
