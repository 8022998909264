import React from "react";
import ExternalLink from "../../components/ExternalLink";

export const CustomSettingsTemplate = ({}) => {
    const customTemplate = [
        {
            "countries": ["us", "br", "ru"],
            "fp": 90,
            "mp": 10,
            "mv": 0.001
        },
        {
            "countries": ["us"],
            "fp": 80,
            "maxv": 50,
        }
    ];

    return <div>
        <b>Template:</b> {JSON.stringify(customTemplate)} / <ExternalLink url={'https://jsonformatter.org/json-editor'} /><br/>
        <i><b>fp</b> - Forward Percentage; <b>mp</b> - Margin Percentage; <b>mv</b> - Min postback value; <b>maxv</b> - Max postback value</i>
    </div>;
}

export const SubscriptionMPTemplate = ({}) => {
    const customTemplate = {
        "1": 1.1,
        "2": 1,
        "3": 0.9,
        "4": 0.9,
        "5": 0.7,
        "6": 0.6,
        "7": 0.5,
        "8": 0.2,
        "9": 0.2,
        "10": 0.1,
    };

    return <div>
        <b>Template:</b> {JSON.stringify(customTemplate)}
    </div>;
}

export const PostbackPlaceholdersInfo = ({url}) => {
    return <div>
        Use placeholders <span
        className={url.includes('{click_id}') ? "text-success" : "text-danger"}>{"{click_id}"}</span>, <span
        className={url.includes('{payout}') ? "text-success" : "text-danger"}>{"{payout}"}</span>, <span
        className={url.includes('{geo}') ? "text-success" : "text-muted"}>{"{geo}"}</span>, <span
        className={url.includes('{sub_cnt}') ? "text-success" : "text-muted"}>{"{sub_cnt}"}</span>
    </div>;
}

export const PhpToJsonInfo = () => {
    return <div>PHP to JSON converter - <ExternalLink url={'https://onlinephp.io/c/41290'}/></div>;
}

export const MaxNegativeMargin = -100