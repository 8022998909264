import {round} from "../../../components/Math";

export const backendRequestHandleFunction = (e) => {
    let parsed = JSON.parse(e.data);

    e.value_source = round(parseFloat(parsed.source), 2);
    e.value_cabinet = round(parseFloat(parsed.cabinet), 2);
    e.cabinet_percentage = e.value_source > 0 ? round(100 * e.value_cabinet / e.value_source, 2) : '-';
    e.info = JSON.stringify(parsed.cabinet_parts);

    return e;
}