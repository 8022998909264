import React, {useState, useEffect} from 'react';
import {Link, useNavigate, useLocation} from "react-router-dom";
import moment from 'moment-timezone';
import axios from "axios";

import Container from "../../../components/Container";
import Card from "../../../components/Сard";
import FilterGroupBy from "../../../components/FilterGroupBy";
import Table from "../../../components/table/Table";
import DateRangePicker from "../../../components/DateRangePicker";
import DataModel from "../../../../data/DataExStatDashboard3";
import TableParams from "./ListTableParams";
import GroupByParams from "./ListGroupByParams";
import ajax from "../../../../data/ajax";
import SelectMultiWithHeader from "../../../components/select/SelectMultiWithHeader";
import {SelectDataFormatter, UrlCreator} from "../../../../components/select_data";
import {consoleLog} from "../../../../components/development";
import LoadingWrapper from "../../../components/LoadingWrapper";
import {ObjectRemoveEmptyForUrl} from "../../../../components/helpers";
import {DATA_TYPE_CLICKADU} from "../../../../components/const_data_type";

const ExStatDashboardList = ({user}) => {
    const dataType = DATA_TYPE_CLICKADU;

    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    const location = useLocation();
    const locationPathname = location.pathname;

    const navigate = useNavigate();

    const [{
        keyDate: tableKeyDate,
        keyHour: tableKeyHour,
        keyManagerID: tableKeyManagerID,
        keyManagerName: tableKeyManagerName,
        keyAccountID: tableKeyAccountID,
        keyAccountName: tableKeyAccountName,
        keyZoneID: tableKeyZoneID,
        keyZoneName: tableKeyZoneName,
        keyCampaignID: tableKeyCampaignID,
        keyCampaignName: tableKeyCampaignName,
        keyCountry: tableKeyCountry,
        keyPlacement: tableKeyPlacement,
        keyPlacementPrice: tableKeyPlacementPrice,
        keyRevenueTb: tableKeyRevenueTb,
        keyProfitTb: tableKeyProfitTb,
        keyTotalROI: tableKeyTotalROI,
        keyTbOfCost: tableKeyTbOfCost,
        keyButtonCampaign: tableKeyButtonCampaign,
        keyButtonPlacement: tableKeyButtonPlacement,
        columns: tableColumns,
        initialState: tableInitialState,
    }] = TableParams();

    const [{
        keyDate: filterGroupByKeyDate,
        keyDateHour: filterGroupByKeyDateHour,
        keyManager: filterGroupByKeyManager,
        keyAccount: filterGroupByKeyAccount,
        keyPubAccount: filterGroupByKeyPubAccount,
        keyCampaign: filterGroupByKeyCampaign,
        keyZone: filterGroupByKeyZone,
        keyCountry: filterGroupByKeyCountry,
        keyPlacement: filterGroupByKeyPlacement,
        keyCampaignPlacement: filterGroupByKeyCampaignPlacement,
        items: filterGroupByItems,
    }] = GroupByParams(user);

    const [filterGroupBy, setFilterGroupBy] = useState(filterGroupByKeyDate);
    const [tableHiddenColumns, setTableHiddenColumns] = useState([tableKeyDate]);

    const [calendarDates, setCalendarDates] = useState({
        start: moment().subtract(6, 'days').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
    });
    const [filterHour, setFilterHour] = useState([])
    const [filterAccount, setFilterAccount] = useState([])
    const [filterManager, setFilterManager] = useState([])
    const [filterCountry, setFilterCountry] = useState([])
    const [filterZone, setFilterZone] = useState([])
    const [filterCampaign, setFilterCampaign] = useState([])
    const [filterPlacement, setFilterPlacement] = useState([])
    const [filterButton, setFilterButton] = useState(0);

    const [listHour, setListHour] = useState([]);
    const [listManager, setListManager] = useState([]);
    const [listAccount, setListAccount] = useState([]);
    const [listCountry, setListCountry] = useState([]);
    const [listZone, setListZone] = useState([]);
    const [listCampaign, setListCampaign] = useState([]);
    const [listPlacement, setListPlacement] = useState([]);

    const [params, setParams] = useState({});
    const [{data, isLoading}, setUrlParams] = DataModel();

    // const defaultHiddenColumns = user.id == 79 ? [keyZoneId, tableKeyRevenueTb, tableKeyProfitTb, tableKeyTotalROI, tableKeyTbOfCost] : [keyZoneId];
    const defaultHiddenColumns = [];
    // const defaultHiddenColumns = [tableKeyRevenueTb, tableKeyProfitTb, tableKeyTotalROI, tableKeyTbOfCost];
    // const defaultHiddenColumns = adminsIdList.includes(user.id)
    //     ? []
    //     : [tableKeyRevenueTb, tableKeyProfitTb, tableKeyTotalROI, tableKeyTbOfCost];
    // const defaultHiddenColumns = isAdmin ? [] : [keyUserEmail, keyProfit]

    useEffect(() => {
        const fetchData = async () => {
            const requestConfig = ajax.getBaseConfig();
            const promises = [
                axios.get(UrlCreator.zones({'data_type': dataType}), requestConfig),
                axios.get(UrlCreator.campaigns({'data_type': dataType}), requestConfig),
                axios.get(UrlCreator.placements({'data_type': dataType}), requestConfig),
            ];

            Promise.all(promises).then(function (results) {
                consoleLog(results);
                const [rZones, rCampaigns, rPlacements] = results;

                const accounts = SelectDataFormatter.accounts(user.getTrafficSourceAccounts());
                setListAccount(accounts);

                const countries = SelectDataFormatter.countries(user.getCountries())
                setListCountry(countries);

                const zones = SelectDataFormatter.zones(rZones.data)
                setListZone(zones);

                const campaigns = SelectDataFormatter.campaigns(rCampaigns.data);
                setListCampaign(campaigns);

                const placements = SelectDataFormatter.placements(rPlacements.data);
                setListPlacement(placements);

                let managers = [];
                if (user.allowManagers()) {
                    managers = SelectDataFormatter.managers(user.getManagers());
                    setListManager(managers);
                }

                const hours = SelectDataFormatter.hours();
                setListHour(hours);

                const searchParams = new URLSearchParams(location.search)
                const p = Object.fromEntries(searchParams);

                if (("group_by" in p) && filterGroupByItems.map(e => e.key).indexOf(p.group_by) !== -1) {
                    setFilterGroupBy(p.group_by);
                } else {
                    p.group_by = filterGroupBy;
                }

                if (!("date_from" in p)) {
                    p.date_from = calendarDates.start
                }
                if (!("date_to" in p)) {
                    p.date_to = calendarDates.end
                }
                setCalendarDates({
                    start: p.date_from,
                    end: p.date_to,
                });

                const accountsValues = accounts.map(e => e.value);
                let urlAccounts = ("account" in p) ? p.account.split(',') : filterAccount;
                urlAccounts = urlAccounts.filter(v => accountsValues.indexOf(v) !== -1)
                setFilterAccount(urlAccounts);
                p.account = urlAccounts;

                const managersValues = managers.map(e => e.value);
                let urlManagers = ("manager" in p) ? p.manager.split(',') : filterManager;
                urlManagers = urlManagers.filter(v => managersValues.indexOf(v) !== -1)
                setFilterManager(urlManagers);
                p.manager = urlManagers;

                const countriesValues = countries.map(e => e.value);
                let urlCountries = ("country" in p) ? p.country.split(',') : filterCountry;
                urlCountries = urlCountries.filter(v => countriesValues.indexOf(v) !== -1)
                setFilterCountry(urlCountries);
                p.country = urlCountries;

                const zonesValues = zones.map(e => e.value);
                let urlZones = ("zone" in p) ? p.zone.split(',') : filterZone;
                urlZones = urlZones.filter(v => zonesValues.indexOf(v) !== -1)
                setFilterZone(urlZones);
                p.zone = urlZones;

                const campaignsValues = campaigns.map(e => e.value);
                let urlCampaign = ("campaign" in p) ? p.campaign.split(',') : filterCampaign;
                urlCampaign = urlCampaign.filter(v => campaignsValues.indexOf(v) !== -1)
                setFilterCampaign(urlCampaign);
                p.campaign = urlCampaign;

                const hoursValues = hours.map(e => e.value);
                let urlHour = ("hour" in p) ? p.hour.split(',') : filterHour;
                urlHour = urlHour.filter(v => hoursValues.indexOf(v) !== -1)
                setFilterHour(urlHour);
                p.hour = urlHour;

                setParams(p);

                setIsLoaded(true);
            });
        };

        setIsLoaded(false);
        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (filterGroupBy === filterGroupByKeyManager) {
            setTableHiddenColumns(defaultHiddenColumns.concat([tableKeyDate, tableKeyHour, tableKeyAccountID, tableKeyAccountName, tableKeyZoneID, tableKeyZoneName, tableKeyCampaignName, tableKeyCampaignID, tableKeyCountry, tableKeyPlacement, tableKeyPlacementPrice, tableKeyButtonCampaign, tableKeyButtonPlacement]))
        } else if (filterGroupBy === filterGroupByKeyAccount || filterGroupBy === filterGroupByKeyPubAccount) {
            setTableHiddenColumns(defaultHiddenColumns.concat([tableKeyDate, tableKeyHour, tableKeyManagerID, tableKeyManagerName, tableKeyZoneID, tableKeyZoneName, tableKeyCampaignName, tableKeyCampaignID, tableKeyCountry, tableKeyPlacement, tableKeyPlacementPrice, tableKeyButtonCampaign, tableKeyButtonPlacement]))
        } else if (filterGroupBy === filterGroupByKeyDate) {
            setTableHiddenColumns(defaultHiddenColumns.concat([tableKeyAccountID, tableKeyHour, tableKeyAccountName, tableKeyManagerID, tableKeyManagerName, tableKeyZoneID, tableKeyZoneName, tableKeyCampaignName, tableKeyCampaignID, tableKeyCountry, tableKeyPlacement, tableKeyPlacementPrice, tableKeyButtonCampaign, tableKeyButtonPlacement]))
        } else if (filterGroupBy === filterGroupByKeyDateHour) {
            setTableHiddenColumns(defaultHiddenColumns.concat([tableKeyAccountID, tableKeyAccountName, tableKeyManagerID, tableKeyManagerName, tableKeyZoneID, tableKeyZoneName, tableKeyCampaignName, tableKeyCampaignID, tableKeyCountry, tableKeyPlacement, tableKeyPlacementPrice, tableKeyButtonCampaign, tableKeyButtonPlacement]))
        } else if (filterGroupBy === filterGroupByKeyZone) {
            setTableHiddenColumns(defaultHiddenColumns.concat([tableKeyDate, tableKeyHour, tableKeyAccountID, tableKeyAccountName, tableKeyManagerID, tableKeyManagerName, tableKeyCampaignName, tableKeyCampaignID, tableKeyCountry, tableKeyPlacement, tableKeyPlacementPrice, tableKeyButtonCampaign, tableKeyButtonPlacement]))
        } else if (filterGroupBy === filterGroupByKeyCampaign) {
            setTableHiddenColumns(defaultHiddenColumns.concat([tableKeyDate, tableKeyHour, tableKeyAccountID, tableKeyAccountName, tableKeyManagerID, tableKeyManagerName, tableKeyZoneID, tableKeyZoneName, tableKeyCountry, tableKeyPlacement, tableKeyPlacementPrice, tableKeyButtonPlacement]))
        } else if (filterGroupBy === filterGroupByKeyCountry) {
            setTableHiddenColumns(defaultHiddenColumns.concat([tableKeyAccountID, tableKeyAccountName, tableKeyManagerID, tableKeyManagerName, tableKeyZoneID, tableKeyZoneName, tableKeyCampaignName, tableKeyCampaignID, tableKeyDate, tableKeyHour, tableKeyPlacement, tableKeyPlacementPrice, tableKeyButtonCampaign, tableKeyButtonPlacement]))
        }  else if (filterGroupBy === filterGroupByKeyPlacement) {
            setTableHiddenColumns(defaultHiddenColumns.concat([tableKeyAccountID, tableKeyAccountName, tableKeyManagerID, tableKeyManagerName, tableKeyZoneID, tableKeyZoneName, tableKeyCampaignName, tableKeyCampaignID, tableKeyDate, tableKeyHour, tableKeyCountry, tableKeyPlacementPrice, tableKeyButtonCampaign, tableKeyButtonPlacement]))
        } else if (filterGroupBy === filterGroupByKeyCampaignPlacement) {
            setTableHiddenColumns(defaultHiddenColumns.concat([tableKeyAccountID, tableKeyAccountName, tableKeyManagerID, tableKeyManagerName, tableKeyZoneID, tableKeyZoneName, tableKeyDate, tableKeyHour, tableKeyCountry, tableKeyButtonCampaign]))
        } else {
            setTableHiddenColumns(defaultHiddenColumns)
        }
    }, [filterGroupBy]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoaded) {
            let params = {
                date_from: calendarDates.start,
                date_to: calendarDates.end,
                hour: filterHour,
                manager: filterManager,
                account: filterAccount,
                country: filterCountry,
                zone: filterZone,
                campaign: filterCampaign,
                placement: filterPlacement,
                group_by: filterGroupBy,
            }

            setParams(params)
        }
    }, [filterGroupBy, calendarDates, filterHour, filterAccount, filterManager, filterCountry, filterZone, filterCampaign, filterPlacement, filterButton]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        navigate(locationPathname + '?' + (new URLSearchParams(ObjectRemoveEmptyForUrl(params))).toString());
        setUrlParams(params);
    }, [params]);

    return (
        <Container title={"Dashboard Clickadu"} isLoaded={isLoaded} error={error}>
            <Card>
                <div className="row">
                    <div className="col-md-12">
                        <Link to={"/ex-stat/campaign/create"}><button className="btn btn-primary">Create clickadu campaign</button></Link>
                    </div>
                </div>
            </Card>
            <Card>
                <div className="row">
                    <div className="col-md-3">
                        <b>Date</b>
                        <DateRangePicker dates={calendarDates} updateDates={v => setCalendarDates(v)}/>
                    </div>
                    <div className="col-md-3">
                        <SelectMultiWithHeader value={filterHour} updateValue={v => setFilterHour(v)}
                                               options={listHour} defaultLabel="Select hour"/>
                    </div>
                    <div className="col-md-3">
                        <SelectMultiWithHeader value={filterAccount} updateValue={v => setFilterAccount(v)}
                                               options={listAccount} defaultLabel="Select traffic source"/>
                    </div>
                    <div className="col-md-3">
                        {
                            user.allowManagers() &&
                            <SelectMultiWithHeader value={filterManager} updateValue={v => setFilterManager(v)}
                                                   options={listManager} defaultLabel="Select manager"/>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <SelectMultiWithHeader value={filterCountry} updateValue={v => setFilterCountry(v)}
                                               options={listCountry} defaultLabel="Select country"/>
                    </div>
                    <div className="col-md-3">
                        <SelectMultiWithHeader value={filterZone} updateValue={v => setFilterZone(v)}
                                               options={listZone} defaultLabel="Select zone"/>
                    </div>
                    <div className="col-md-3">
                        <SelectMultiWithHeader value={filterCampaign} updateValue={v => setFilterCampaign(v)}
                                               options={listCampaign} defaultLabel="Select campaign"/>
                    </div>
                    <div className="col-md-3">
                        <SelectMultiWithHeader value={filterPlacement} updateValue={v => setFilterPlacement(v)}
                                               options={listPlacement} defaultLabel="Select placement"/>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary" onClick={e => {
                            const t = Date.now();
                            setFilterButton(t)
                        }}>Show Results
                        </button>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <FilterGroupBy
                            items={filterGroupByItems}
                            value={filterGroupBy}
                            updateValue={v => setFilterGroupBy(v)}
                        />
                    </div>
                </div>
            </Card>
            <LoadingWrapper isLoaded={!isLoading}>
                <Card>
                    <Table
                        columns={tableColumns}
                        data={data}
                        initialState={tableInitialState}
                        hiddenColumns={tableHiddenColumns}
                    />
                </Card>
            </LoadingWrapper>
        </Container>
    );
}

export default ExStatDashboardList;
