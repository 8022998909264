import React from 'react';
import PropTypes from 'prop-types';

import Loading from "./Loading";

export default function ButtonSubmit({isLoading, className, text, disabled}) {
    let content;
    if (isLoading) {
        content = <Loading/>
    } else {
        content = <button type="submit" className={"btn " + className} disabled={disabled}>{text}</button>
    }

    return content
}

ButtonSubmit.propTypes = {
    isLoading: PropTypes.bool,
    className: PropTypes.string,
    text: PropTypes.string,
    disabled: PropTypes.bool,
}

ButtonSubmit.defaultProps = {
    isLoading: false,
    className: "btn-primary",
    text: "Submit",
    disabled: false,
}
