import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import Tab from "./Tab";
import {useInput} from "../../../components/input-hook";
import ButtonSubmit from "../../components/ButtonSubmit";
import {useProfileRequest, useProfileUpdate} from "../../../data/profile";
import AlertSuccess from "../../components/AlertSuccess";

export default function TabPostback({tabActiveID}) {
    const [{data: profile, isLoading, error}] = useProfileRequest([]);
    const [{
        data: updateProfile,
        setRequestData,
        isLoading: updateIsLoading,
        error: updateError,
        dataErrors
    }] = useProfileUpdate(null);

    const {
        value: postbackUrl,
        setValue: setPostBackUrl,
        error: errorPostBackUrl,
        setError: setErrorPostBackUrl,
        bind: bindPostbackUrl
    } = useInput('');

    const setFromResponse = (result) => {
        // console.log("result")
        // console.log(result)
        setPostBackUrl(result.postback_url ? result.postback_url : '');
    }

    useEffect(() => {
        setFromResponse(profile);
    }, [profile]);

    useEffect(() => {
        if (updateProfile) {
            setFromResponse(updateProfile);
        }
    }, [updateProfile]);

    useEffect(() => {
        let errors = {
            postback_url: '',
        };
        dataErrors.forEach(v => {
            if (v.field === 'postback_url') {
                errors.postback_url = v.message
            }
        })

        setErrorPostBackUrl(errors.postback_url);
    }, [dataErrors]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            postback_url: postbackUrl,
        };
        setRequestData(data);
    }

    return (
        <Tab tabID="postback" tabActiveID={tabActiveID} isLoading={isLoading} title="Global Postback" error={error}
             errorUpdate={updateError}>
            <form onSubmit={handleSubmit}>
                {
                    updateProfile && <AlertSuccess message="Data updated successfully"/>
                }

                <div className="form-group">
                    <label htmlFor="inputPostbackURL">Your Postback Url</label>
                    <input type="text" id="inputPostbackURL"
                           placeholder="Postback Url"
                           className={"form-control " + (errorPostBackUrl.length > 0 ? "is-invalid" : '')}
                           {...bindPostbackUrl}
                    />
                    <div className="invalid-feedback">{errorPostBackUrl}</div>

                    <div className="form-group">
                        <b>Placeholders:</b>
                        <ul>
                            <li><b>{"{ymid}"}</b>: click ID</li>
                            <li><b>{"{geo}"}</b>: geo</li>
                            <li><b>{"{amount}"}</b>: payout</li>
                            {/*<li><b>{request_var}</b>: request var</li>*/}
                        </ul>
                    </div>

                    {
                        process.env.REACT_APP_TYPE === "cabinet" &&
                        <div className="form-group">
                            Learn how to set up S2S conversion tracking in
                            <a href="/index.html"><strong>this instruction</strong></a>
                        </div>
                    }

                    <ButtonSubmit isLoading={updateIsLoading} text="Save changes"/>
                </div>
            </form>
        </Tab>
    )
}

TabPostback.propTypes = {
    tabActiveID: PropTypes.string.isRequired,
}
