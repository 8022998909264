import React from 'react';
import PropTypes from 'prop-types';

import AlertDanger from "../../components/AlertDanger";
import Card from "../../components/Сard";
import Loading from "../../components/Loading";

export default function Tab({tabID, tabActiveID, title, isLoading, error, errorUpdate, children}) {
    let content;
    if (error) {
        console.error("tab loading error: ", error);
        content = <AlertDanger message={error.message}/>
    } else if (isLoading) {
        content = <Loading/>
    } else {
        content = children
    }

    return (
        <div className={"tab-pane fade" + (tabID === tabActiveID ? " active show" : "")}>
            <Card>
                <>
                    {title.length > 0 && <h5 className="card-title">{title}</h5>}

                    {errorUpdate && <AlertDanger message={errorUpdate.message}/>}

                    {content}
                </>
            </Card>
        </div>
    )
}

Tab.propTypes = {
    tabID: PropTypes.string.isRequired,
    tabActiveID: PropTypes.string.isRequired,
    title: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.object,
    errorUpdate: PropTypes.object,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element.isRequired,
        PropTypes.string.isRequired
    ])
}

Tab.defaultProps = {
    isLoading: false,
    title: "",
}
