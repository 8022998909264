import React, {useState} from 'react';
import axios from "axios";
import styled from 'styled-components'

const Styled = styled.div`
  .alert {
    margin-bottom: 0;
    margin-left: 5rem;
  }
  .alert-icon {
    padding: 0.5rem 0.95rem;
  }
  .alert-message {
    padding: 0.5rem 0.95rem;
  }
`

export default function AppUpdatedWarning() {
    const appVersion = process.env.REACT_APP_VERSION;
    const [actualVersion, setActualVersion] = useState("");

    const refreshPage = () => {
        window.location.reload();
    }

    if (process.env.NODE_ENV !== "development") {
        const interval = setInterval(() => {
            axios.get("https://" + window.location.hostname + "/version.json?t=" + Math.floor(Date.now() / 1000), {
                "headers": {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                }
            }).then(response => {
                if (response.data && response.data.version != appVersion) {
                    setActualVersion(response.data.version);
                }
            })
        }, 5 * 60 * 1000);
    }

    if (actualVersion === "") {
        return <></>
    }

    return (
        <Styled>
            <div className="alert alert-warning" role="alert">
                <div className="alert-icon">
                    <i className="far fa-fw fa-bell"></i>
                </div>
                <div className="alert-message">
                    <strong>New Version Released!</strong> <a href="#" onClick={refreshPage}>Please reload the page!</a>
                </div>
            </div>
        </Styled>
    )
}
