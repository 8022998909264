import React, {useEffect} from 'react';

import TokenStorage from "../../components/TokenStorage";
import {useDeleteRequest} from "../../data/RequestData";

const Logout = () => {
    const [{isLoading}] = useDeleteRequest('auth/0');

    useEffect(() => {
        if (!isLoading) {
            TokenStorage.remove();
            window.location.reload();
        }
    }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    return (<div></div>);
}

export default Logout;
