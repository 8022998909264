import React from "react";
import {round} from "../../../components/Math";

export default function ReportBalancesListTableParams() {
    const keyId = 'id';
    const keyManagerName = 'manager_name';

    const columns = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: keyId,
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Network',
                accessor: 'network_name',
            },
            {
                Header: 'Manager',
                accessor: keyManagerName,
            },
            {
                Header: 'Balance',
                accessor: 'balance',
                sortType: 'basic',
                Footer: info => {
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.balance) + sum, 0),
                        [info.rows]
                    )

                    return <>{round(total, 4)}</>
                },
            },
        ],
        []
    );

    const initialState = {
        sortBy: [
            {
                id: keyId,
                desc: true
            }
        ]
    }

    return [{
        keyManagerName,
        columns,
        initialState
    }];
}
