import {useState, useEffect} from 'react';

import ajax from "./ajax";
import axios from "axios";

import {round} from "../components/Math";

/**
 * @deprecated see BackendRequest
 */
export default function DataExStatDashboard() {
    const [params, setParams] = useState({});
    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    // console.log("DataExStatDashboard")

    useEffect(() => {
        const fetchData = async () => {
            setIsError(false);
            setIsLoading(true);

            try {

                if (Object.keys(params).length > 0) {
                    let url = window.trackerBaseUrl + "ex-stat/dashboard3?" + (new URLSearchParams(params)).toString();

                    const result = await axios.get(url, ajax.getBaseConfig());

                    let data = result.data.map(e => {
                        const cost = Number(e.cost);
                        const revenue = Number(e.revenue);
                        const revenue_tb = Number(e.revenue_tb);
                        const profit = Number(e.revenue) - cost;
                        const profit_tb = revenue_tb + profit;
                        const impressions = Number(e.impressions);

                        e.profit = round(profit, 4);
                        e.profit_tb = round(profit_tb, 4);

                        e.tb_of_cost = cost === 0 ? null : round(revenue_tb / cost * 100, 2);
                        e.roi = cost === 0 ? null : round(profit / cost * 100, 2);
                        e.total_roi = cost === 0 ? null : round(profit_tb / cost * 100, 2);

                        e.cpm = impressions === 0 ? null : round(1000 * cost / impressions, 4);
                        e.ecpm_total = impressions === 0 ? null : round(1000 * (revenue + revenue_tb) / impressions, 4);

                        if ('pub_account' in e && !('account' in e)) {
                            e.account = e.pub_account;
                        }

                        return e;
                    })

                    // setIsLoaded(true);
                    setData(data);
                }
            } catch (error) {
                setIsError(true);
            }

            setIsLoading(false);
        };

        fetchData();
    }, [params]);

    return [{ data, isLoading, isError }, setParams];
}
