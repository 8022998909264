import {useState, useEffect} from 'react';

import ajax from "./ajax";
import axios from "axios";

/**
 * @deprecated see BackendRequest
 */
export default function DataSegmentRates() {
    const [params, setParams] = useState({});
    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    console.log("DataSegmentRates")

    useEffect(() => {
        const fetchData = async () => {
            setIsError(false);
            setIsLoading(true);

            try {
                let url = window.trackerBaseUrl + "segment-rate/list?a=a";

                if ("group" in params) {
                    url += "&group_by=" + params.group

                    if ("date_from" in params) {
                        url += "&date_from=" + params.date_from
                    }
                    if ("date_to" in params) {
                        url += "&date_to=" + params.date_to
                    }
                    if ("country" in params) {
                        url += "&country=" + params.country
                    }
                    if ("zone" in params) {
                        url += "&zone=" + params.zone
                    }
                    if ("account" in params) {
                        url += "&account=" + params.account
                    }
                    // if ("revenue_model" in params) {
                    //     url += "&revenue_model=" + params.revenue_model
                    // }

                    const result = await axios.get(url, ajax.getBaseConfig());

                    let data = result.data.map(e => {
                        e.data_roi = e.data["roi"] !== undefined && e.data["roi"] !== null ? e.data["roi"] * 1 : null;
                        e.data_rate_mod = e.data["rate_mod"] !== undefined ? e.data["rate_mod"] * 1 : null;
                        // e.data = JSON.stringify(e.data);
                        // e.profit = Math.round((Number(e.revenue) - Number(e.cost)) * 10000) / 10000;
                        // e.profit_tb = Math.round((Number(e.revenue) + Number(e.revenue_tb) - Number(e.cost)) * 10000) / 10000;

                        return e;
                    })

                    // setIsLoaded(true);
                    setData(data);
                }
            } catch (error) {
                setIsError(true);
            }

            setIsLoading(false);
        };

        fetchData();
    }, [params]);

    return [{ data, isLoading, isError }, setParams];
}
