import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import moment from 'moment';

import Container from "../../../components/Container";
import Card from "../../../components/Сard";
import Table from "../../../components/table/Table";
import DateRangePicker from "../../../components/DateRangePicker";
import DataModel from "../../../../data/DataExStatDashboardTotal";
import TableParams from "./DashboardTotalListTableParams";
import {consoleLogResponse} from "../../../../components/development";
import ButtonFilter from "../../../components/ButtonFilter";
import TableColumns from "../../../components/table/TableColumns";
import Filter from "../../../../components/filter";
import LoadingWrapper from "../../../components/LoadingWrapper";
import {ObjectRemoveEmptyForUrl} from "../../../../components/helpers";
import {useSelect} from "../../../../components/input-hook";
import {SelectDataFormatter} from "../../../../components/select_data";
import SelectMulti from "../../../components/select/SelectMulti";

const DashboardTotalList = ({user}) => {
    const location = useLocation();
    const locationPathname = location.pathname;

    const navigate = useNavigate();

    const [isLoadedFilterData, setIsLoadedFilterData] = useState(false);

    const localStorageTableKey = 'table_dashboard_total';
    const tableParams = TableParams();

    const defaultHiddenColumns = [];
    const [tableGroupHiddenColumns, setTableGroupHiddenColumns] = useState([]);
    const [tableUserHiddenColumns, setTableUserHiddenColumns] = useState();
    const [tableHiddenColumns, setTableHiddenColumns] = useState([]);
    useEffect(() => {
        setTableHiddenColumns(defaultHiddenColumns.concat(tableGroupHiddenColumns, tableUserHiddenColumns))
    }, [tableGroupHiddenColumns, tableUserHiddenColumns]); // eslint-disable-line react-hooks/exhaustive-deps

    const [calendarDates, setCalendarDates] = useState({
        start: moment().subtract(6, 'days').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
    });

    const inputFilterManager = useSelect([]);
    const inputFilterManagerGroup = useSelect([]);
    const [filterButton, setFilterButton] = useState(0);

    const [dataRequestParams, setDataRequestParams] = useState({});
    const [{data, isLoading}, setUrlParams] = DataModel(user);

    useEffect(() => {
        const fetchData = async () => {
            const promises = [
            ];
            Promise.all(promises).then(results => {
                consoleLogResponse(results);

                let filters = [];
                if (user.allowManagerGroups()) {
                    filters.push({
                        key: 'manager_group',
                        data: SelectDataFormatter.managerGroups(user.getManagerGroups()),
                        input: inputFilterManagerGroup,
                    });
                }
                if (user.allowManagers()) {
                    filters.push({
                        key: 'manager',
                        data: SelectDataFormatter.managers(user.getManagers()),
                        input: inputFilterManager,
                    });
                }
                let params = Filter.collectParamsForFilter(location, filters)

                const searchParamsObj = new URLSearchParams(location.search)
                const searchParams = Object.fromEntries(searchParamsObj);

                params.date_from = ("date_from" in searchParams) ? searchParams.date_from : calendarDates.start;
                params.date_to = ("date_to" in searchParams) ? searchParams.date_to : calendarDates.end;
                setCalendarDates({
                    start: params.date_from,
                    end: params.date_to,
                });

                setIsLoadedFilterData(true);
            });
        };

        if (!isLoadedFilterData) {
            fetchData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoadedFilterData) {
            setDataRequestParams({
                date_from: calendarDates.start,
                date_to: calendarDates.end,
                manager: inputFilterManager.value,
                manager_group: inputFilterManagerGroup.value,
                group_by: 'date',
            })
        }
    }, [
        isLoadedFilterData,
        calendarDates,
        inputFilterManager.value,
        inputFilterManagerGroup.value,
        filterButton
    ]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Object.keys(dataRequestParams).length > 0) {
            navigate(locationPathname + '?' + (new URLSearchParams(ObjectRemoveEmptyForUrl(dataRequestParams))).toString());
            setUrlParams(dataRequestParams);
        }
    }, [dataRequestParams]);

    return (
        <Container title={"Dashboard Total"}>
            <LoadingWrapper isLoaded={isLoadedFilterData}>
                <Card>
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <b>Date</b>
                            <DateRangePicker
                                dates={calendarDates}
                                updateDates={v => setCalendarDates(v)}
                            />
                        </div>
                        {user.allowManagerGroups() && <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <b>Manager group</b>
                            <SelectMulti {...inputFilterManagerGroup.bind}/>
                        </div>}
                        {user.allowManagers() && <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <b>Manager</b>
                            <SelectMulti {...inputFilterManager.bind}/>
                        </div>}
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <ButtonFilter setFilterButton={v => setFilterButton(v)} />
                        </div>
                        <div className="col-md-6">&nbsp;</div>
                        <div className="col-md-3 text-right">
                            <TableColumns
                                storageKey={localStorageTableKey}
                                columns={tableParams.columns}
                                setUserTableHiddenColumns={v => setTableUserHiddenColumns(v)}
                            />
                        </div>
                    </div>
                </Card>
            </LoadingWrapper>

            <LoadingWrapper isLoaded={isLoadedFilterData && !isLoading}>
                <Card>
                    <Table
                        data={data}
                        {...tableParams}
                        hiddenColumns={tableHiddenColumns}
                    />
                </Card>
            </LoadingWrapper>
        </Container>
    );
}

export default DashboardTotalList;
