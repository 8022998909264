const SOURCE_CLICKADU = "1"
const SOURCE_ADSTERRA = "2"
const SOURCE_ADCASH = "4"
const SOURCE_GALAKSION = "6"
const SOURCE_POPADS = "7"
const SOURCE_TRAFFIC_STARS = "13"
const SOURCE_MONETIZER = "12"
const SOURCE_ADMAVEN = "24"

const PLACEHOLDER_CLICK_ID = "CLICK_ID"
const PLACEHOLDER_CAMPAIGN_ID = "CAMPAIGN_ID"
const PLACEHOLDER_ZONE_ID = "ZONE_ID"
const PLACEHOLDER_GEO = "GEO"

export const TRAFFIC_SOURCES_ID = {
    CLICKADU: SOURCE_CLICKADU,
    ADSTERRA: SOURCE_ADSTERRA,
    ADCASH: SOURCE_ADCASH,
    GALAKSION: SOURCE_GALAKSION,
    POPADS: SOURCE_POPADS,
    TRAFFIC_STARS: SOURCE_TRAFFIC_STARS,
    MONETIZER: SOURCE_MONETIZER,
    ADMAVEN: SOURCE_ADMAVEN,

    // self::TYPE_POPCASH,
    // self::TYPE_ZEROPARK,
    // self::TYPE_HILLTOPADS,
    // self::TYPE_LITCHIADS,
    // self::TYPE_TRAFORAMA,
};

export const TRAFFIC_SOURCES_PLACEHOLDER_KEYS = {
    CLICK_ID: PLACEHOLDER_CLICK_ID,
    CAMPAIGN_ID: PLACEHOLDER_CAMPAIGN_ID,
    ZONE_ID: PLACEHOLDER_ZONE_ID,
    GEO: PLACEHOLDER_GEO,
};

const TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL = {};

// https://faq.clickadu.com/en/articles/6234927-what-tokens-macros-or-variables-do-you-support
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_CLICKADU] = {
    "{zoneid}": "{zoneid}", // ID of the ad zone where the ad is displayed
    "{cost}": "{cost}", // allows to track impressions/click cost (Please note that the token {cost} is compatible only with the Onclick campaign working on the smartCPM basis)
    "{os}": "{os}", // allows tracking data by OS
    "{os_version}": "{os_version}", // allows tracking data by OS version
    "{device}": "{device}", // allows tracking data by device
    "{browser}": "{browser}", // allows tracking data by browser
    "{browser_lang}": "{browser_lang}", // allows tracking data by browser language
    "{connection_type}": "{connection_type}", // allows tracking data by connection type (3G\WIFI)
    "{carrier}": "{carrier}", // allows tracking data by ISP (carrier)
    "{state}": "{state}", // allows tracking data by states
    "{city}": "{city}", // allows tracking data by cities
    "{cohort}": "{cohort}", // allows tracking data by user activity
    "{pn_type}": "{pn_type}", // allows tracking data by push-notification campaigns traffic type (Native Push, In-Page Push or Banner in case you use Cross Format Advertising)
    "{bannerid}": "{bannerid}", // allows tracking data for each creative
}
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_CLICKADU][PLACEHOLDER_CLICK_ID] = "${SUBID}"; // Unique ID for tracking conversions
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_CLICKADU][PLACEHOLDER_CAMPAIGN_ID] = "{campaignid}"; // allows referring data by campaign ID
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_CLICKADU][PLACEHOLDER_GEO] = "{geo}"; // allows to track data by countries

// https://support.adcash.com/en/articles/41
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_ADCASH] = {
    "[zone]": "[zone]", // An encrypted ID of traffic source. Data format example: ZONE-SUB1-SUB2 (1111-2222-3333). SUB1 may include User Device OS ID; SUB2 may include User Browser ID
    "[advertiser]": "[advertiser]", // Advertiser profile ID in Adcash system
    "[timestamp]": "[timestamp]", // Cache buster. Time in seconds since Epoch (aka. Timestamp)
    "[ban]": "[ban]", // Creative ID
    "[org]": "[org]", // ISP organization
    "[region]": "[region]", // Region
    "[city]": "[city]", // City
    "[lang]": "[lang]", // Browser main language
    "[redirection_cost]": "[redirection_cost]", // Displays a cost-specific redirection for advertiser
    "[user_timezone]": "[user_timezone]", // Time zone passed based on the IP of the click (HTTP_X_GEOIP2_TIMEZONE)
    "[connection_type]": "[connection_type]", // Cable/DSL Wi-Fi
    "[browser]": "[browser]", // Browser name
    "[ip]": "[ip]", // An IP address of the device making the ad request.
    "[platform]": "[platform]", // Platform name
    "[device_make]": "[device_make]", // Manufacturer of Device
    "[ssp]": "[ssp]", // Supply source
    "[mzone]": "[mzone]", // Zone ID without SubIDs
    "[device_model]": "[device_model]", // Handset Model
    "[currency]": "[currency]", // Currency ISO code: USD EUR
}
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_ADCASH][PLACEHOLDER_CLICK_ID] = "[clickid]"; // Unique Click ID. Mandatory macro for server to server (S2S) tracking method
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_ADCASH][PLACEHOLDER_CAMPAIGN_ID] = "[campaign]"; // Current campaign ID in Adcash system
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_ADCASH][PLACEHOLDER_GEO] = "[country]"; // ISO 3166-1 country code of the user

// https://ssp2.galaksion.com/faq
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_GALAKSION] = {
    "{zoneid}": "{zoneid}", // source
    "{creative_id}": "{creative_id}", // creative ID
    "{cost}": "{cost}",
}
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_GALAKSION][PLACEHOLDER_CLICK_ID] = "[:click_id]";
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_GALAKSION][PLACEHOLDER_CAMPAIGN_ID] = "{cid}"; // campaign ID
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_GALAKSION][PLACEHOLDER_GEO] = "{geo}"; // country

// https://www.popads.net/kb/advertiser
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_POPADS] = {
    "[WEBSITEID]": "[WEBSITEID]", // ID of the website that was source of the popunder
    "[CATEGORYID]": "[CATEGORYID]", // ID of the category of traffic source website
    "[CATEGORYNAME]": "[CATEGORYNAME]", // Name of the category of traffic source website
    "[ISPID]": "[ISPID]", // ID of ISP of visitor
    "[ISPNAME]": "[ISPNAME]", // Name of ISP of visitor
    "[QUALITY]": "[QUALITY]", // Quality score of the website from which the impression orignated. It is a number, from 1 to 10, where 1 means lowest quality website (All websites) and 10 means highest quality website (top 10% of our publisher websites)
    "[BID]": "[BID]", // price of the impression
    "[SCREENRESOLUTION]": "[SCREENRESOLUTION]", // Detected screen resolution of the visitor
    "[OSNAME]": "[OSNAME]", // Operating System name, for example Windows 8.1
    "[BROWSERNAME]": "[BROWSERNAME]", // Browser name, for example Firefox 32
    "[FORMFACTORNAME]": "[FORMFACTORNAME]", // Form factor name, for example Smartphone or Tablet
    "[DEVICENAME]": "[DEVICENAME]", // Name of the device that visitor uses to browse the Internet, for example Apple iPhone
    "[CAMPAIGNNAME]": "[CAMPAIGNNAME]", // Name of campaign
    "[OSID]": "[OSID]", // ID of Operating System (for future use)
    "[BROWSERID]": "[BROWSERID]", // ID of Browser (for future use)
    "[FORMFACTORID]": "[FORMFACTORID]", // ID of Form Factor (for future use)
    "[DEVICEID]": "[DEVICEID]", // ID of Device (for future use)
    "[ADBLOCK]": "[ADBLOCK]", // is set to 1 when visitor uses AdBlock or 0 otherwise
}
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_POPADS][PLACEHOLDER_CLICK_ID] = "[IMPRESSIONID]"; // unique ID of the impression. This can be used for troubleshooting/debugging process in case of traffic mistargeting as well as for conversion tracking (S2S)
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_POPADS][PLACEHOLDER_CAMPAIGN_ID] = "[CAMPAIGNID]"; // ID of campaign
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_POPADS][PLACEHOLDER_GEO] = "[COUNTRY]"; // country of the visitor

// https://trafficstars.com/faq/articles/trafficstars-tokens
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_TRAFFIC_STARS] = {
    "{campaign}": "{campaign}", // Campaign Name
    "{creative_id}": "{creative_id}", // Creative ID
    "{device_type}": "{device_type}", // Device type
    "{device_type_id}": "{device_type_id}", // Device type ID
    "{os}": "{os}", // Operating System
    "{os_id}": "{os_id}", // Operating System ID
    "{browser}": "{browser}", // Browser
    "{browser_id}": "{browser_id}", // Browser ID
    "{referrer}": "{referrer}", // URL of the page that the user visited before following a link to the current page
    "{site_id}": "{site_id}", // Site ID
    "{category}": "{category}", // Categories detected
    "{category_id}": "{category_id}", // Category ID
    "{carrier}": "{carrier}", // Сarrier
    "{carrier_id}": "{carrier_id}", // Carrier ID
    "{keywords}": "{keywords}", // Keywords collected
    "{region}": "{region}", // Region
    "{lang}": "{lang}", // 2-char language code
    "{format_id}": "{format_id}", // Creative format ID
    "{format}": "{format}", // Creative format WIDTHxHIGHT for banners (315x300, 300x250, etc) and name for other, for example: Popunder, Native
    "{pricing_model}": "{pricing_model}", // CPM, CPC, etc
    "{cost}": "{cost}", // It is the actual amount paid. Works forCPC, CPA and DCPM (Popunder only) pricing model.
    "{price}": "{price}", // It is the bid that was set
    "{ip}": "{ip}", // User’s IP address
    "{site_host}": "{site_host}", // Site URL
    "{ecpc}": "{ecpc}", // ECPC
    "{adspot_id}": "{adspot_id}", // Ad spot ID
    "{adspot_name}": "{adspot_name}", // Ad spot name
    "{x}": "{x}", // Locate clicks on hosted banners
    "{y}": "{y}", // Locate clicks on hosted banners
}
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_TRAFFIC_STARS][PLACEHOLDER_CLICK_ID] = "{click_id}"; // Randomly generated unique click id used for s2s tracking
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_TRAFFIC_STARS][PLACEHOLDER_CAMPAIGN_ID] = "{campaign_id}"; // Campaign ID
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_TRAFFIC_STARS][PLACEHOLDER_GEO] = "{geo}"; // 2-char country code

//
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_MONETIZER] = {
    "[[pid]]": "[[pid]]",
}
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_MONETIZER][PLACEHOLDER_CLICK_ID] = "[[subid]]";
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_MONETIZER][PLACEHOLDER_CAMPAIGN_ID] = "[[campaign_id]]";

// https://help-advertisers.adsterra.com/en/articles/4575920-what-are-the-available-tokens
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_ADSTERRA] = {
    "##PLACEMENT_ID##": "##PLACEMENT_ID##", // ID of the actual source
    "##ADVERTISER_ID##": "##ADVERTISER_ID##", // Advertiser ID on the Adsterra platform
    "##USER_OS##": "##USER_OS##", // Operation System on the user’s device
    "##USER_CARRIER##": "##USER_CARRIER##", // ISP
    "##USER_OS_VER##": "##USER_OS_VER##", // User's operating system version
    "##LANDING_ID##": "##LANDING_ID##", // Landing page ID
    "##CAMPAIGN_NAME##": "##CAMPAIGN_NAME##", // the name of your campaign on the Adsterra platform
    "##PUBLISHER_ID##": "##PUBLISHER_ID##", // Publisher ID
    "##ZONE_ID##": "##ZONE_ID##", // Publisher's site ID
    "##REMOTE_COUNTRY##": "##REMOTE_COUNTRY##", // full name of Country
    "##REMOTE_LANGUAGE_CODE##": "##REMOTE_LANGUAGE_CODE##", // user’s browser language
    "##BANNER_ID##": "##BANNER_ID##", // ID of Banner/Creative. This one is handy when you run a campaign with Native Banners or Social bar ad units. With the macro added to the link, you can check performance for all of your creatives on the campaign
    "##BROWSER_NAME##": "##BROWSER_NAME##", // User’s browser
    "##DEVICE_BRAND##": "##DEVICE_BRAND##", // the brand of the user's device
    "##DEVICE_MODEL##": "##DEVICE_MODEL##", // the model of the user's device
    "##COST_CPA##": "##COST_CPA##", // The cost per action (e.g., install, sign-up) derived from the campaign pricing settings, factoring in any custom bids. This value is applicable only for CPA campaigns; in other campaign types, it returns 0
    "##COST_CPC##": "##COST_CPC##", // The cost per click from the campaign pricing settings, factoring in any custom bids. In CPM campaigns, this value represents the cost per 1 impression. It is used in both CPC and CPM campaigns; in other campaign types, it returns 0
    "##COST_CPM##": "##COST_CPM##", // The cost per 1,000 impressions (CPM) from the campaign pricing settings, factoring in any custom bids. This value applies only to CPM campaigns; in other campaign types, it returns 0. For the cost per 1 impression in a CPM campaign, use ##COST_CPC##
    "##COUNTRY_CODE_A3##": "##COUNTRY_CODE_A3##", // 3symbols Country Code (ISO 3166-1 alpha-3)
    "##REMOTE_IP##": "##REMOTE_IP##", // User's IP
    "##USER_CITY##": "##USER_CITY##", // User's city based on IP address
    "##USERAGENT##": "##USERAGENT##", // encoded user-agent
    "##USERAGENT_DEC##": "##USERAGENT_DEC##", // not encoded user-agent
    "##TIMESTAMP##": "##TIMESTAMP##", // ad display time (in Unix timestamp format)
    "##RANDOM_ID##": "##RANDOM_ID##", // random ID for RTB integration
}
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_ADSTERRA][PLACEHOLDER_CLICK_ID] = "##SUB_ID_SHORT(action)##"; // Click ID. This one is a must-have when you are launching a performance basis campaign
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_ADSTERRA][PLACEHOLDER_CAMPAIGN_ID] = "##CAMPAIGN_ID##"; // ID of your campaign at Adsterra’s platform
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_ADSTERRA][PLACEHOLDER_ZONE_ID] = "##SUB_ID_SHORT(action)##"; // Click ID. This one is a must-have when you are launching a performance basis campaign
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_ADSTERRA][PLACEHOLDER_GEO] = "##COUNTRY_CODE##"; // 2symbols Country Code (ISO 3166-1 alpha-2)

//
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_ADMAVEN] = {
    "{:sub_source_id}": "{:sub_source_id}",
    "{:cost}": "{:cost}",
}
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_ADMAVEN][PLACEHOLDER_CLICK_ID] = "{:click_id}";
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_ADMAVEN][PLACEHOLDER_CAMPAIGN_ID] = "{:campaign_id}";
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_ADMAVEN][PLACEHOLDER_ZONE_ID] = "{:source_id}";
TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL[SOURCE_ADMAVEN][PLACEHOLDER_GEO] = "{:country_code}";

export const TRAFFIC_SOURCES_PLACEHOLDERS = TRAFFIC_SOURCES_PLACEHOLDERS_LOCAL;