import React from "react";

export default function SegmentRatesListTableParams(user) {
    const keyDate = 'date';
    const keyCountry = 'country_code';
    const keyZoneId = 'zone';
    const keyZoneOriginId = 'zone_origin_id';
    const keyZoneName = 'zone_name';
    const keyZoneEdit = 'zone_edit';
    const keyRevenueTb = 'revenue_tb';
    const keyProfitTb = 'profit_tb';
    const keyTotalROI = 'total_roi';
    const keyTbOfCost = 'tb_of_cost';

    const columns = React.useMemo(
        () => [
            // {
            //     Header: 'ID',
            //     accessor: keyZoneId,
            // },
            {
                Header: 'Date',
                accessor: keyDate,
            },
            {
                Header: 'Account ID',
                // accessor: keyZoneId,
                accessor: "account_id",
            },
            {
                Header: 'Country',
                accessor: keyCountry,
            },
            {
                Header: 'Rate',
                accessor: 'rate',
                sortType: 'basic',
            },
            {
                Header: 'Zone ID',
                // accessor: keyZoneOriginId,
                accessor: 'zone_id',
            },
            {
                Header: 'Zone Name',
                accessor: 'zone_name',
            },
            {
                Header: 'ROI',
                accessor: 'data_roi',
                sortType: 'basic',
            },
            {
                Header: 'Rate mod',
                accessor: 'data_rate_mod',
                sortType: 'basic',
            },
            {
                Header: 'Data',
                accessor: 'data',
                Cell: ({row}) => {
                    // console.log(row.original);
                    return <div><pre>{JSON.stringify(row.original.data, null, 2) }</pre></div>
                    // return <div><pre>{JSON.stringify(JSON.parse(row.original.data), null, 2) }</pre></div>
                    // return <JSONPretty id={"json-pretty-" . row.original.id} data={row.original.data}></JSONPretty>
                },
            },

//             {
//                 // id: "actions",
//                 accessor: keyZoneEdit,
//                 // accessor: 'id',
// /*                Cell: ({ original }) => (
//                     <button value={original.id} >
//                         {/!* onClick={props.handleClickGroup} *!/}
//                         {original.id}
//                     </button>
//                 )*/
//                 Cell: ({row}) => {
//                     // console.log("---- row ----", {row})
//                     return <>
//                     {/*    <button onClick={(e) => {*/}
//                     {/*    e.preventDefault();*/}
//                     {/*    console.log("---- button onClick ----", {row})*/}
//                     {/*    //setActiveTabID(item.key)*/}
//                     {/*}}>Edit</button>*/}
//
//                         <Link to={"/ex-stat/zone/edit/"+ row.values.zone}><Icon.Settings /></Link>
//                         </>
//                 },
//                 width: 100,
//             }
        ],
        []
    );

    const initialState = {
        sortBy: [
            {
                id: keyDate,
                desc: true
            }
        ],
        pageSize: 100,
    }

    return [{keyZoneId, keyZoneOriginId, keyZoneName, keyDate, keyCountry, keyZoneEdit, keyRevenueTb, keyProfitTb, keyTotalROI, keyTbOfCost, columns, initialState}];
}
