import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import axios from "axios";

import Container from "../../components/Container";
import Card from "../../components/Сard";
import ajax from "../../../data/ajax";
import {SelectDataFormatter, UrlCreator} from "../../../components/select_data";
import {consoleLog, consoleLogResponse} from "../../../components/development";
import LoadingWrapper from "../../components/LoadingWrapper";
import AlertSuccess from "../../components/AlertSuccess";
import ButtonSubmit from "../../components/ButtonSubmit";
import {usePostRequest} from "../../../data/RequestData";
import AlertDanger from "../../components/AlertDanger";
import SelectSingle from "../../components/select/SelectSingle";
import {useInput, useSelect} from "../../../components/input-hook";
import ZoneData from "../../../components/ZoneData";
import DivError from "../../components/form/DivError";
import DivFormGroupRow from "../../components/form/DivFormGroupRow";

const PropushCreateForm = ({user}) => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);

    const [{
        data: dataUpdate,
        isLoading: isLoadingUpdate,
        error: errorUpdate,
        dataErrors
    }, setRequestData] = usePostRequest('ex-stat/zone');

    const inputName = useInput('');

    const inputDirection = useSelect(ZoneData.DIRECTION_PUSH);
    const inputRevenueModel = useSelect(ZoneData.REVENUE_MODEL_CPS);
    const inputSiteId = useSelect('');

    const setFromResponse = (result) => {
        consoleLog(result);

        // setForwardPercentage(result.forward_percentage ? result.forward_percentage : 0)
        // setMarginPercentage(result.margin_percentage ? result.margin_percentage : 0)
        // setSiteId(result.account_id ? result.account_id : '')
        // setName(result.url ? result.url : null)
        // setPrecision(result.precision ? result.precision : -1)
        // setRates(result.rate_table ? JSON.stringify(result.rate_table) : '')
    }

    useEffect(() => {
        const promises = [
            axios.get(UrlCreator.sites(), ajax.getBaseConfig()),
        ];
        Promise.all(promises).then(function (results) {
            consoleLogResponse(results);

            inputSiteId.setOptions(SelectDataFormatter.sites(results[0].data))
            inputDirection.setOptions(SelectDataFormatter.directions());
            inputRevenueModel.setOptions(SelectDataFormatter.revenueModels());

            inputName.setValue('XXX ' + new Date().toJSON().slice(0, 10))

            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        let errors = {
            title: '',
            direction_id: '',
            revenue_model: '',
            origin_site_id: '',
        };
        dataErrors.forEach(v => {
            if (v.field === 'title') {
                errors.title = v.message
            } else if (v.field === 'direction_id') {
                errors.direction_id = v.message
            } else if (v.field === 'revenue_model') {
                errors.revenue_model = v.message
            } else if (v.field === 'origin_site_id') {
                errors.origin_site_id = v.message
            }
        })

        inputName.setError(errors.title);
        inputDirection.setError(errors.direction_id);
        inputRevenueModel.setError(errors.revenue_model);
        inputSiteId.setError(errors.origin_site_id);
    }, [dataErrors]);

    useEffect(() => {
        if (dataUpdate) {
            setFromResponse(dataUpdate);
            window.scrollTo(0, 0);

            navigate('/ex-stat/zone/edit-propush/' + dataUpdate.id);
        }
    }, [dataUpdate]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const d = {
            title: inputName.value,
            direction_id: inputDirection.value,
            origin_site_id: inputSiteId.value,
            revenue_model: ZoneData.isPushDirection(inputDirection.value) ? inputRevenueModel.value : ZoneData.REVENUE_MODEL_REV_SHARE,
        };
        consoleLog('!!! setRequestData', d)
        setRequestData(d);
    }

    return (
        <Container title={"Create zone"}>
            <Card>
                <LoadingWrapper isLoaded={!isLoading}>
                    <form onSubmit={handleSubmit}>
                        {
                            errorUpdate && <AlertDanger messagePrefix={errorUpdate['name'] + ': '} message={errorUpdate['message']}/>
                        }
                        {
                            dataUpdate && <AlertSuccess message="Data updated successfully"/>
                        }

                        <DivFormGroupRow label="Name" labelAttributes={{htmlFor: "inputName"}}>
                            <input
                                type="text"
                                id="inputName"
                                placeholder="Name"
                                className={"form-control " + (inputName.error.length > 0 ? "is-invalid" : '')}
                                {...inputName.bind}
                            />
                            <DivError error={inputName.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Site">
                            <SelectSingle {...inputSiteId.bind} />
                            <DivError error={inputSiteId.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Direction">
                            <SelectSingle {...inputDirection.bind} />
                            <DivError error={inputDirection.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Revenue model">
                            <>
                                {ZoneData.isPushDirection(inputDirection.value) && <>
                                    <SelectSingle {...inputRevenueModel.bind} />
                                </>}

                                {!ZoneData.isPushDirection(inputDirection.value) && <div>
                                    Rev. Share
                                </div>}
                            </>
                            <DivError error={inputRevenueModel.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow>
                            <ButtonSubmit isLoading={false} text="Save changes"/>
                        </DivFormGroupRow>
                    </form>
                </LoadingWrapper>
            </Card>
        </Container>
    );
}

export default PropushCreateForm;
