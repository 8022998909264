import axios from "axios";

import TokenStorage from "../components/TokenStorage";

function getHeaders() {
	return {
		"Accept": "application/json",
		"Content-Type": "application/json",
		"Authorization": "Bearer " + TokenStorage.get(),
	};
}

const getBaseConfig = () => {
	return {
		headers: getHeaders(),
	};
};

const getBaseConfigWithResponseType = () => {
	return {
		headers: getHeaders(),
		responseType: 'json',
	};
};

const requestGetMultiple = (urls) => {
	const config = getBaseConfig();
	const promises = [];
	urls.forEach(url => promises.push(axios.get(url, config)))

	return promises
}

const ajax = {
	// ajax,
	// get,
	// create,
	// update,
	// remove,
	requestGetMultiple,
	getHeaders,
	getBaseConfig,
	getBaseConfigWithResponseType
}

export default ajax;
