import React from "react";

import ListData from "../../../components/ListData";

export default function DataValidationListTableParams() {
    const keyDate = 'date';

    const columns = React.useMemo(
        () => {
            return [
                {
                    Header: 'Date',
                    accessor: keyDate,
                },
                {
                    Header: 'Account ID',
                    accessor: 'account_id',
                },
                {
                    Header: 'Account',
                    accessor: 'account_name',
                },
                {
                    Header: 'Is Valid',
                    accessor: 'is_valid',
                    Cell: ({row}) => ListData.boolName(row.original['is_valid']),
                },
                {
                    Header: 'Is Latest',
                    accessor: 'is_latest',
                    Cell: ({row}) => ListData.boolName(row.original['is_latest']),
                },
                {
                    Header: 'Run date (EST)',
                    accessor: 'date_exec',
                },
                {
                    Header: 'Source Value',
                    accessor: 'value_source',
                },
                {
                    Header: 'Cabinet Value',
                    accessor: 'value_cabinet',
                },
                {
                    Header: 'Cabinet %',
                    accessor: 'cabinet_percentage',
                },
                {
                    Header: 'Info',
                    accessor: 'info',
                },
            ];
        },
        []
    );

    const initialState = {
        sortBy: [
            {
                id: keyDate,
                desc: true
            }
        ]
    }

    return {
        columns, initialState
    };
}
