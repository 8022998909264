const BOOL_YES = '1';
const BOOL_NO = '0';

const boolName = (value) => {
    value = value + ''
    switch (value) {
        case BOOL_YES:
            return 'YES';
        case BOOL_NO:
            return 'NO';
        default:
            return 'Unknown (' + value + ')';
    }
}

const ListData = {
    BOOL_YES,
    BOOL_NO,
    boolName,
}

export default ListData;
