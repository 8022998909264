import React, {useEffect} from 'react';
import Container from "../components/Container";
import Card from "../components/Сard";

function Empty() {
    useEffect(() => {

    }, []);

    return (
        <Container title={"Empty page"}>
            <Card>
                Empty page
            </Card>
        </Container>
    );
}

export default Empty;