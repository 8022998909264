import React from 'react';
import ajax from "../../data/ajax";
import Loading from "../components/Loading";

class Stats extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
        }
    }

    loadStat() {
        const url = window.trackerBaseUrl + "stat/trends?date_group=2&date_range=6";

        fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            // method: 'POST', // *GET, POST, PUT, DELETE, etc.
            // mode: 'cors', // no-cors, *cors, same-origin
            // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: 'same-origin', // include, *same-origin, omit
            headers: ajax.getHeaders(),
            // redirect: 'follow', // manual, *follow, error
            // referrerPolicy: 'no-referrer', // no-referrer, *client
            // body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
            .then(res => res.json())
            .then(
                result => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    })
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                error => {
                    this.setState({
                        isLoaded: true,
                        error
                    })
                }
            )
    }

    componentDidMount() {
        this.loadStat()
        //this.loadTrafficSource()
    }

    render() {
        const {error, isLoaded, items} = this.state
        if (error) {
            return <div>Ошибка: {error.message}</div>
        } else if (!isLoaded) {
            return <Loading />
        } else {
            return (
                <div className="container-fluid p-0">

                    <h1 className="h3 mb-3">Statistics</h1>

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
{/*                                <div className="col-md-6 text-center">
                                    <select className="form-control mb-3">
                                        <option selected>Country</option>
                                        <option>One</option>
                                        <option>Two</option>
                                        <option>Three</option>
                                    </select>
                                </div>*/}
                                <div className="col-md-6 text-center">
                                    <select className="form-control mb-3">
                                        <option selected>Traffic Sources</option>
                                        {/*<option>One</option>*/}
                                        {/*<option>Two</option>*/}
                                        {/*<option>Three</option>*/}
                                    </select>

                                    {/*@TODO https://react-select.com/async */}

                                    {/*<Select*/}
                                    {/*    onChange={this.onChange}*/}
                                    {/*    //options={this.state.options}*/}
                                    {/*    options={this.state.stations}*/}
                                    {/*    value={this.state.value}*/}
                                    {/*    clearable={false}*/}
                                    {/*/>*/}
                                </div>
                            </div>

{/*                            <div className="row">
                                <div className="col-md-3 text-center">
                                    <select className="form-control mb-3">
                                        <option selected>Sub 1</option>
                                        <option>One</option>
                                        <option>Two</option>
                                        <option>Three</option>
                                    </select>
                                </div>
                                <div className="col-md-3 text-center">
                                    <select className="form-control mb-3">
                                        <option selected>Sub 2</option>
                                        <option>One</option>
                                        <option>Two</option>
                                        <option>Three</option>
                                    </select>
                                </div>
                                <div className="col-md-3 text-center">
                                    <select className="form-control mb-3">
                                        <option selected>Sub 3</option>
                                        <option>One</option>
                                        <option>Two</option>
                                        <option>Three</option>
                                    </select>
                                </div>
                                <div className="col-md-3 text-center">
                                    <select className="form-control mb-3">
                                        <option selected>Sub 4</option>
                                        <option>One</option>
                                        <option>Two</option>
                                        <option>Three</option>
                                    </select>
                                </div>
                            </div>*/}

{/*                            <div className="row">
                                <div className="col-md-6 text-center">
                                    <input type="text" className="form-control" placeholder="Date Picker" />
                                </div>
                                <div className="col-md-6 text-center">
                                    &nbsp;
                                </div>
                            </div>*/}
                        </div>


                        <div className="card-body">
                            <div className="mb-3">
                                <button className="btn btn-primary">Date</button>
{/*                                <button className="btn btn-outline-primary">Offers</button>
                                <button className="btn btn-outline-primary">Countries</button>
                                <button className="btn btn-outline-primary">Sub 1</button>
                                <button className="btn btn-outline-primary">Sub 2</button>
                                <button className="btn btn-outline-primary">Sub 3</button>
                                <button className="btn btn-outline-primary">Sub 4</button>*/}
                            </div>
                        </div>

                        <table className="table">
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Clicks</th>
                                <th>Conversions</th>
                                <th>Revenue, $</th>
                                <th>Conv. Rate, %</th>
                                <th>EPC, $</th>

                            </tr>
                            </thead>
                            <tbody>

                            {items.map(item => (
                                <tr>
                                    <td>{item.date_interval}</td>
                                    <td>{item.clicks}</td>
                                    <td>{item.leads}</td>
                                    <td>${item.revenue}</td>
                                    <td>{item.leads > 0 ? item.leads/item.clicks * 100 : 0}%</td>
                                    <td>$0</td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>


                    {/*            <nav aria-label="Page navigation example">
                <ul className="pagination pagination-lg">
                    <li className="page-item"><a className="page-link" href="#"><i
                        className="fas fa-angle-left"></i></a></li>
                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                    <li className="page-item active"><a className="page-link" href="#">2</a></li>
                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                    <li className="page-item"><a className="page-link" href="#">4</a></li>
                    <li className="page-item"><a className="page-link" href="#">5</a></li>
                    <li className="page-item"><a className="page-link" href="#"><i
                        className="fas fa-angle-right"></i></a></li>
                </ul>
            </nav>*/}


                </div>
            )
        }
    }
}

export default Stats;
