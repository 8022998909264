import {useState, useEffect} from 'react';

import ajax from "./ajax";
import axios from "axios";

import {consoleLog, consoleLogError, consoleLogResponse} from "../components/development";

export const useGetRequest = (path) => {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        setError(null);

        const url = process.env.REACT_APP_API_URL + path;
        consoleLog('useGetRequest', 'url: ' + url);

        axios.get(url, ajax.getBaseConfigWithResponseType())
            .then((response) => {
                consoleLogResponse(response);
                setData(response.data);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    window.location.replace("/index.html");
                }
                consoleLogError(error);
                setError(error);
            })
            .then(() => setIsLoading(false));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return [{data, isLoading, error}];
};

export const usePostRequest = (path) => {
    const [requestData, setRequestData] = useState(null);
    const [data, setData] = useState(null);
    const [dataErrors, setDataErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (requestData) {
            setIsLoading(true);
            setError(null);
            setDataErrors([]);

            consoleLog('RequestData:', requestData);

            const url = process.env.REACT_APP_API_URL + path;
            consoleLog('usePostRequest', 'url: ' + url);

            axios.post(url, requestData, ajax.getBaseConfigWithResponseType())
                .then((response) => {
                    consoleLogResponse(response);
                    setData(response.data);
                })
                .catch((error) => {
                    consoleLogError(error, error.message);
                    if (error.response.status === 401) {
                        window.location.replace("/index.html");
                    } else if (error.response.status === 422) {
                        setDataErrors(error.response.data);
                    } else {
                        setError(error.response.data);
                    }
                })
                .then(() => {
                    setIsLoading(false);
                    // window.scrollTo(0, 0);
                });
        }
    }, [requestData]); // eslint-disable-line react-hooks/exhaustive-deps

    return [{data, isLoading, error, dataErrors}, setRequestData];
};

export const usePutRequest = (path) => {
    const [requestData, setRequestData] = useState(null);
    const [data, setData] = useState(null);
    const [dataErrors, setDataErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (requestData) {
            setIsLoading(true);
            setError(null);
            setDataErrors([]);

            consoleLog('RequestData:', requestData);

            const url = process.env.REACT_APP_API_URL + path;
            consoleLog('usePutRequest', 'url: ' + url);

            axios.put(url, requestData, ajax.getBaseConfigWithResponseType())
                .then((response) => {
                    consoleLogResponse(response);
                    setData(response.data);
                })
                .catch((error) => {
                    consoleLogError(error, error.message);
                    if (error.response.status === 401) {
                        window.location.replace("/index.html");
                    } else if (error.response.status === 422) {
                        setDataErrors(error.response.data);
                    } else {
                        setError(error);
                    }
                })
                .then(() => {
                    setIsLoading(false);
                    // window.scrollTo(0, 0);
                });
        }
    }, [requestData]); // eslint-disable-line react-hooks/exhaustive-deps

    return [{data, isLoading, error, dataErrors}, setRequestData];
};

export const useDeleteRequest = (path) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        setError(null);

        const url = process.env.REACT_APP_API_URL + path;
        consoleLog('useDeleteRequest', 'url: ' + url);

        axios.delete(url, ajax.getBaseConfigWithResponseType())
            .then((response) => {
                consoleLogResponse(response);
                setData(response.data);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    window.location.replace("/index.html");
                }
                consoleLogError(error);
                setError(error);
            })
            .then(() => setIsLoading(false));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return [{data, isLoading, error}];
};
