import React, {useEffect} from 'react';
import Container from "../components/Container";
import Card from "../components/Сard";

const Page404 = () => {
    useEffect(() => {

    }, []);

    return (
        <Container title={"Page not found"}>
            <Card>
                Oops!
            </Card>
        </Container>
    );
}

export default Page404
