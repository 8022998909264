import {useState, useEffect} from 'react';

import ajax from "./ajax";
import axios from "axios";

import {round} from "../components/Math";

/**
 * @deprecated see BackendRequest
 */
export default function DataExStatZone(accountId) {
    const [params, setParams] = useState({});
    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    // console.log("DataExStatZone", accountId)

    useEffect(() => {
        const fetchData = async () => {
            setIsError(false);
            setIsLoading(true);

            try {
                if (Object.keys(params).length > 0) {
                    params.account_id = accountId;

                    let url = window.trackerBaseUrl + "ex-stat/zone?" + (new URLSearchParams(params)).toString();

                    const result = await axios.get(url, ajax.getBaseConfig());

                    let data = result.data.map(e => {
                        const conversions = Number(e.conversions);
                        const cost = Number(e.cost);
                        const revenue_tb = Number(e.revenue_tb);
                        const profit = Number(e.revenue) - cost;
                        const profit_tb = revenue_tb + profit;

                        e.profit = round(profit, 4);
                        e.profit_tb = round(profit_tb, 4);

                        e.tb_of_cost = cost === 0 ? null : round(revenue_tb / cost * 100, 2);
                        e.roi = cost === 0 ? null : round(profit / cost * 100, 2);
                        e.total_roi = cost === 0 ? null : round(profit_tb / cost * 100, 2);

                        e.ecpa = (cost === 0 || conversions === 0) ? null : round(cost / conversions, 6);

                        return e;
                    })

                    // setIsLoaded(true);
                    setData(data);
                }
            } catch (error) {
                setIsError(true);
            }

            setIsLoading(false);
        };

        fetchData();
    }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

    return [{data, isLoading, isError}, setParams];
}
