import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import moment from 'moment';

import Container from "../../components/Container";
import Card from "../../components/Сard";
import Table from "../../components/table/Table";
import DateRangePicker from "../../components/DateRangePicker";
import TableParams from "./DataValidationListTableParams";
import {SelectDataFormatter} from "../../../components/select_data";
import {consoleLogResponse} from "../../../components/development";
import {useSelect} from "../../../components/input-hook";
import Filter from "../../../components/filter";
import LoadingWrapper from "../../components/LoadingWrapper";
import SelectMulti from "../../components/select/SelectMulti";
import ButtonFilter from "../../components/ButtonFilter";
import TableColumns from "../../components/table/TableColumns";
import BackendRequest from "../../../data/BackendRequest";
import {backendRequestHandleFunction} from "./functions";

const DataValidationList = ({user}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [isLoadedFilterData, setIsLoadedFilterData] = useState(false);

    const localStorageTableKey = 'table_data_validation';
    const tableParams = TableParams();

    const defaultHiddenColumns = [];
    const [tableUserHiddenColumns, setTableUserHiddenColumns] = useState();
    const [tableHiddenColumns, setTableHiddenColumns] = useState([]);
    useEffect(() => {
        setTableHiddenColumns(defaultHiddenColumns.concat(tableUserHiddenColumns))
    }, [tableUserHiddenColumns]); // eslint-disable-line react-hooks/exhaustive-deps

    const [calendarDates, setCalendarDates] = useState({
        start: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        end: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    });

    const [filterButton, setFilterButton] = useState(0);

    const inputFilterAccount = useSelect([]);
    const inputFilterIsLatest = useSelect(["1"]);
    const inputFilterIsValid = useSelect([]);

    const [dataRequestParams, setDataRequestParams] = useState({});
    const [{data, isLoading}, setUrlParams] = BackendRequest('data_validation', backendRequestHandleFunction);

    useEffect(() => {
        const fetchData = async () => {
            const promises = [];
            Promise.all(promises).then(function (results) {
                consoleLogResponse(results);

                let filters = [
                    {
                        key: 'account',
                        data: SelectDataFormatter.accounts([].concat(user.getTrafficSourceAccounts(), user.getAffiliateAccounts())),
                        input: inputFilterAccount,
                    },
                    {
                        key: 'latest',
                        data: SelectDataFormatter.bool(),
                        input: inputFilterIsLatest,
                    },
                    {
                        key: 'valid',
                        data: SelectDataFormatter.bool(),
                        input: inputFilterIsValid,
                    },
                ];

                let params = Filter.collectParamsForFilter(location, filters)

                const searchParamsObj = new URLSearchParams(location.search)
                const searchParams = Object.fromEntries(searchParamsObj);

                params.date_from = ("date_from" in searchParams) ? searchParams.date_from : calendarDates.start;
                params.date_to = ("date_to" in searchParams) ? searchParams.date_to : calendarDates.end;
                setCalendarDates({
                    start: params.date_from,
                    end: params.date_to,
                });

                setDataRequestParams(params)

                setIsLoadedFilterData(true);
            });
        };

        setIsLoadedFilterData(false);
        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoadedFilterData) {
            setDataRequestParams({
                date_from: calendarDates.start,
                date_to: calendarDates.end,
                account: inputFilterAccount.value,
                latest: inputFilterIsLatest.value,
                valid: inputFilterIsValid.value,
            })
        }
    }, [
        calendarDates,
        inputFilterAccount.value,
        inputFilterIsValid.value,
        inputFilterIsLatest.value,
        filterButton
    ]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Object.keys(dataRequestParams).length > 0) {
            navigate(location.pathname + '?' + (new URLSearchParams(dataRequestParams)).toString());
            setUrlParams(dataRequestParams);
        }
    }, [dataRequestParams]); // eslint-disable-line react-hooks/exhaustive-deps

    // https://stackoverflow.com/questions/45373742/detect-route-change-with-react-router
    // useEffect(() => {
    //     const unlisten = history.listen((location) => {
    //         console.log({ page_path: location.pathname, all: location })
    //     })
    //
    //     // remember, hooks that add listeners
    //     // should have cleanup to remove them
    //     return unlisten
    // }, [history.listen])

    return (
        <Container title={"Data validation"}>
            <LoadingWrapper isLoaded={isLoadedFilterData}>
                <Card>
{/*                    <div className="row">
                        <div className="col-md-12 text-right">
                            <Link to={"/ex-stat/data_validation?"}>Not valid last week</Link>
                            &nbsp;/ <Link to={"/ex-stat/data_validation?date_from=2023-10-13&date_to=2023-10-13&account=&latest=1&valid="}>Everything for today</Link>
                        </div>
                    </div>*/}
                    <div className="row">
                        <div className="col-md-3">
                            <b>Date</b>
                            <DateRangePicker
                                dates={calendarDates}
                                updateDates={v => setCalendarDates(v)}
                            />
                        </div>
                        <div className="col-md-3">
                            <b>Account</b>
                            <SelectMulti {...inputFilterAccount.bind}/>
                        </div>
                        <div className="col-md-3">
                            <b>Is valid</b>
                            <SelectMulti {...inputFilterIsValid.bind}/>
                        </div>
                        <div className="col-md-3">
                            <b>Is Latest</b>
                            <SelectMulti {...inputFilterIsLatest.bind}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <ButtonFilter setFilterButton={v => setFilterButton(v)}/>
                        </div>
                        <div className="col-md-6">&nbsp;</div>
                        <div className="col-md-3 text-right">
                            <TableColumns
                                storageKey={localStorageTableKey}
                                columns={tableParams.columns}
                                setUserTableHiddenColumns={v => setTableUserHiddenColumns(v)}
                            />
                        </div>
                    </div>
                </Card>
            </LoadingWrapper>

            <LoadingWrapper isLoaded={isLoadedFilterData && !isLoading}>
                <Card>
                    <Table
                        data={data}
                        {...tableParams}
                        hiddenColumns={tableHiddenColumns}
                    />
                </Card>
            </LoadingWrapper>
        </Container>
    );
}

export default DataValidationList;
