import React from 'react'
import moment from 'moment';
import {DateRangePicker as ReactDateRangePicker} from "react-bootstrap-daterangepicker";

import 'bootstrap-daterangepicker/daterangepicker.css';

export default function DateRangePicker({dates, updateDates}) {
    const handleCalendarApply = function (event, picker) {
        updateDates({
            start: picker.startDate.format('YYYY-MM-DD'),
            end: picker.endDate.format('YYYY-MM-DD'),
        })
    }

    const generateRanges = () => {
        const todayDate = moment().toDate();

        return {
            'Today': [
                todayDate,
                todayDate,
            ],
            'Yesterday': [
                moment().subtract(1, 'days').toDate(),
                moment().subtract(1, 'days').toDate(),
            ],
            'Last 7 Days': [
                moment().subtract(6, 'days').toDate(),
                todayDate,
            ],
            'Last 30 Days': [
                moment().subtract(29, 'days').toDate(),
                todayDate,
            ],
            'This Month': [
                moment().startOf('month').toDate(),
                moment().endOf('month').toDate(),
            ],
            'Last Month': [
                moment().subtract(1, 'month').startOf('month').toDate(),
                moment().subtract(1, 'month').endOf('month').toDate(),
            ],
            'This Year': [
                moment().startOf('year').startOf('month').toDate(),
                todayDate,
            ],
        }
    }

    return (
        <>
            <ReactDateRangePicker
                onApply={handleCalendarApply}
                initialSettings={{
                    locale: {
                        format: 'YYYY-MM-DD'
                    },
                    startDate: dates.start,
                    endDate: dates.end,
                    alwaysShowCalendars: true,
                    ranges: generateRanges(),
                }}
            >
                <input type="text" className="form-control  form-control-lg"/>
            </ReactDateRangePicker>
        </>
    )
}

DateRangePicker.defaultProps = {
    dates: {
        start: moment().subtract(6, 'days'),
        end: moment(),
    },
}
