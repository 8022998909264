import React from "react";

export default function NftCollectionListTableParams() {
    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Token ID',
                accessor: 'token_id',
            },
            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({row}) => {
                    return <a href={"https://opensea.io/assets/" + row.original.collection_contract + "/" + row.original.token_id} target="_blank" rel="noopener">{row.original.name}</a>;
                },
            },
            {
                Header: 'Contract',
                accessor: 'contract',
                Cell: ({row}) => {
                    return <a href={"https://opensea.io/collection/" + row.original.collection_slug} target="_blank" rel="noopener">{row.original.collection_name}</a>;
                },
            },
            {
                Header: 'Price BN (ETH)',
                accessor: 'price_by_now',
                Cell: ({row}) => {
                    return <>{row.original.price_by_now === null ? '' : row.original.price_by_now * 1}</>;
                },
            },
            {
                Header: 'Price BN',
                accessor: 'price_by_now_origin',
                Cell: ({row}) => {
                    return <>{row.original.curr_origin === 'ETH' || row.original.curr_origin === '' ? '' : row.original.price_by_now_origin * 1 + ' ' + row.original.curr_origin}</>;
                },
            },
            {
                Header: 'Rank',
                accessor: 'rank',
            },
            {
                Header: 'Rarity',
                accessor: 'rarity',
            },
            {
                Header: 'For buy',
                accessor: 'for_buy',
                Cell: ({row}) => {
                    return <>{row.original.for_buy * 1 === 1 ? 'Yes' : 'No'}</>;
                },
            },
            {
                Header: 'K',
                accessor: 'price_calc',
            },
            {
                Header: 'CFP',
                accessor: 'collection_floor_price',
            },
        ],
        []
    );

    const initialState = {
        sortBy: [
            {
                id: 'id',
                desc: true
            }
        ],
        pageSize: 100,
    }

    return [{columns, initialState}];
}
