import React from 'react';
import PropTypes from 'prop-types';

export default function DivError({error}) {
    return <>
        {error && <div className="invalid-feedback" style={{display: "block"}}>{error}</div>}
    </>
}

DivError.propTypes = {
    error: PropTypes.string,
}

DivError.defaultProps = {
    error: "",
}
