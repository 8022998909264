import {useEffect, useState} from "react";
import axios from "axios";

import ajax from "../../../../../data/ajax";
import {consoleLog, consoleLogError, consoleLogResponse} from "../../../../../components/development";

export const useCampaignCreate = () => {
    const [requestData, setRequestData] = useState(null);
    const [data, setData] = useState(null);
    const [dataErrors, setDataErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (requestData) {
            consoleLog('RequestData:', requestData);

            setIsLoading(true);
            setError(null);
            setDataErrors([]);

            const url = process.env.REACT_APP_API_URL + "ex-stat/campaign-clickadu";

            axios.post(url, requestData, ajax.getBaseConfigWithResponseType())
                .then((response) => {
                    consoleLogResponse(response);
                    setData(response.data);
                })
                .catch((error) => {
                    consoleLogError(error, error.message);

                    if (error.response.status === 401) {
                        window.location.replace("/index.html");
                    }else if (error.response.status === 422) {
                        setDataErrors(error.response.data);
                    } else {
                        setError(error);
                    }
                })
                .then(() => {
                    setIsLoading(false);
                    window.scrollTo(0, 0);
                });
        }
    }, [requestData]);

    return [{data, setFormData: setRequestData, isLoading, error, dataErrors}];
};
