import React from "react";
import {PERMISSIONS} from "../../../../components/permissions";
import {mainGroups} from "../../../../components/user_groups";

export default function ListGroupByParams(user) {
    const keyDate = 'date';
    const keyManagerGroup = 'manager_group';
    const keyManager = 'manager';
    const keyAccount = 'account';
    const keyPubAccount = 'pub_account';
    const keyCampaign = 'campaign';
    const keyZone = 'zone';
    const keyZoneTb = 'zone_tb';
    const keyCountry = 'country';
    const keyType = 'type';
    const keyDateYear = 'date_year';
    const keyDateMonth = 'date_month';
    const keyDateWeek = 'date_week';

    const items = React.useMemo(() => [
        {
            title: "Date",
            key: keyDate,
        },
        {
            title: "Aff. network",
            key: keyPubAccount,
        },
        {
            title: "Traffic source",
            key: keyAccount,
        },
        {
            title: "Manager Group",
            key: keyManagerGroup,
            visible: user.allowManagerGroups(),
        },
        {
            title: "Manager",
            key: keyManager,
            visible: user.allowManagers(),
        },
        {
            title: "Country",
            key: keyCountry,
        },
        {
            title: "Zone",
            key: keyZone,
        },
        {
            title: "TB zone",
            key: keyZoneTb,
        },
        {
            title: "Campaign",
            key: keyCampaign,
            visible: user.inGroups(mainGroups),
        },
        {
            title: "Type",
            key: keyType,
            visible: user.allowPermission(PERMISSIONS.DEVELOPER),
        },
        {
            title: "Year",
            key: keyDateYear,
            visible: user.inGroups(mainGroups),
        },
        {
            title: "Month",
            key: keyDateMonth,
            visible: user.inGroups(mainGroups),
        },
        {
            title: "Week",
            key: keyDateWeek,
            visible: user.inGroups(mainGroups),
        },
    ], []);

    return {
        keyDate,
        keyManagerGroup,
        keyManager,
        keyAccount,
        keyPubAccount,
        keyCampaign,
        keyZone,
        keyZoneTb,
        keyCountry,
        keyType,
        keyDateYear,
        keyDateMonth,
        keyDateWeek,
        items,
    };
}
