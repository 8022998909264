import React from 'react';

export default function TableTdCompare({val1, val2, roundFunction}) {
    if (val2 === undefined) {
        return roundFunction(val1)
    }

    let className = "text-secondary"
    let sign = ""
    const diff  = val1 - val2;
    if (diff > 0)     {
        className = "text-success"
        sign = "+"
    } else if (diff < 0) {
        className = "text-danger"
        sign = "" // minus as default
    }

    return <>
        <div>{roundFunction(val1)}</div>
        <div className={className}>{sign + roundFunction(diff)}</div>
    </>
}

TableTdCompare.defaultProps = {
    diff: 0,
    roundFunction: x => x,
};

