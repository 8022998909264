import React, {useEffect, useState} from 'react';
import RangeSlider from "react-bootstrap-range-slider";

import PreviewTable from "./PreviewTable";
import {useCheckboxInput, useInput} from "../../../components/input-hook";
import Container from "../../components/Container";
import Card from "../../components/Сard";
import {round} from "../../../components/Math";

function PostbackSetupDemo() {
    const [minMarginPercentage, setMinMarginPercentage] = useState(0);

    const {
        value: forwardPercentage,
        setValue: setForwardPercentage,
        error: errorForwardPercentage,
    } = useInput(50);

    const {
        value: marginPercentage,
        setValue: setMarginPercentage,
        error: errorMarginPercentage,
    } = useInput(50);

    const {
        value: precision,
        setValue: setPrecision,
        error: errorPrecision,
    } = useInput(-1);

    const {
        value: negativeMargin,
        bind: bindNegativeMargin,
    } = useCheckboxInput(false);

    useEffect(() => {
        setMinMarginPercentage(negativeMargin ? -30 : 0);
        if (!negativeMargin && marginPercentage < 0) {
            setMarginPercentage(0);
        }
    }, [negativeMargin]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container title={"Conversion setup demo"}>
            <Card>
                <form>
                    <div className="form-group">
                        <label htmlFor="inputCustomTitle">Round precision</label>
                        <RangeSlider
                            size='lg'
                            // tooltipLabel={currentValue => `${currentValue}%`}
                            tooltipLabel={currentValue => {
                                const v = 0.55555555555;
                                if (currentValue === -1) {
                                    return v;
                                }
                                return 'round(' + v + ', ' + currentValue + ') = ' + round(v, currentValue)
                            }}
                            tooltip='on'
                            min={-1}
                            max={8}
                            value={precision}
                            onChange={changeEvent => setPrecision(parseInt(changeEvent.target.value))}
                        />
                        {
                            errorPrecision &&
                            <div className="invalid-feedback"
                                 style={{display: "block"}}>{errorPrecision}</div>
                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputCustomTitle">Forward Conversion Percentage</label>
                        <RangeSlider
                            size='lg'
                            tooltipLabel={currentValue => `${currentValue}%`}
                            tooltip='on'
                            min={0}
                            max={100}
                            value={forwardPercentage}
                            onChange={changeEvent => setForwardPercentage(changeEvent.target.value)}
                        />
                        {
                            errorForwardPercentage &&
                            <div className="invalid-feedback"
                                 style={{display: "block"}}>{errorForwardPercentage}</div>
                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputCustomTitle">Margin Percentage</label>
                        <div>
                            <label className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" {...bindNegativeMargin} />
                                <span className="custom-control-label">I want to pay more than I get!</span>
                            </label>
                        </div>
                        <RangeSlider
                            size='lg'
                            tooltipLabel={currentValue => `${currentValue}%`}
                            tooltip='on'
                            min={minMarginPercentage}
                            max={100}
                            value={marginPercentage}
                            onChange={changeEvent => setMarginPercentage(changeEvent.target.value)}
                            variant={marginPercentage < 0 ? 'warning' : 'primary'}
                        />
                        {
                            errorMarginPercentage &&
                            <div className="invalid-feedback"
                                 style={{display: "block"}}>{errorMarginPercentage}</div>
                        }
                    </div>
                </form>

                <PreviewTable
                    forwardPercentage={forwardPercentage}
                    marginPercentage={marginPercentage}
                    precision={precision}
                />
            </Card>
        </Container>
    );
}

export default PostbackSetupDemo;