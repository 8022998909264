import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import Tab from "./../../Settings/Tab";
import ButtonSubmit from "../../../components/ButtonSubmit";
import AlertSuccess from "../../../components/AlertSuccess";
import {useZoneRequest, useZoneUpdate} from "../../../../data/zone";
import {useInput, useSelect} from "../../../../components/input-hook";
import SelectSingle from "../../../components/select/SelectSingle";
import {getZoneRevenueModelsList} from "../../../../data/static_data";
import {Dump, isDebug} from "../../../../components/development";
import Card from "../../../components/Сard";
import DivError from "../../../components/form/DivError";

export default function ExStatZoneEditFormTabCustomSettings({tabActiveID, zoneID}) {
    const [{data: profile, isLoading, error}] = useZoneRequest(zoneID);

    const [{
        data: updateProfile,
        setRequestData,
        isLoading: updateIsLoading,
        error: updateError, dataErrors
    }] = useZoneUpdate(zoneID);

    const inputCustomRevenueModel = useSelect('');
    const inputCustomTitle = useInput('');
    const inputDescription = useInput('');

    const setFromResponse = (result) => {
        console.log('result', result)

        inputCustomRevenueModel.setValue(result.custom_revenue_model ? result.custom_revenue_model : '');
        inputCustomTitle.setValue(result.custom_title ? result.custom_title : '');
        inputDescription.setValue(result.description ? result.description : '');
    }

    useEffect(() => {
        if (Object.keys(profile).length > 0) {
            inputCustomRevenueModel.setOptions(getZoneRevenueModelsList());

            setFromResponse(profile);
        }

    }, [profile]);

    useEffect(() => {
        if (updateProfile) {
            setFromResponse(updateProfile);
        }
    }, [updateProfile]);

    useEffect(() => {
        let errors = {
            custom_title: '', custom_revenue_model: '', description: '',
        };
        dataErrors.forEach(v => {
            if (v.field === 'custom_title') {
                errors.custom_title = v.message
            } else if (v.field === 'custom_revenue_model') {
                errors.custom_revenue_model = v.message
            } else if (v.field === 'description') {
                errors.description = v.message
            }
        })

        inputCustomRevenueModel.setError(errors.custom_revenue_model);
        inputCustomTitle.setError(errors.custom_title);
        inputDescription.setError(errors.description);
    }, [dataErrors]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            custom_revenue_model: inputCustomRevenueModel.value,
            custom_title: inputCustomTitle.value,
            description: inputDescription.value,
        };
        setRequestData(data);
    }

    return (<>
            <Tab tabID="custom_settings" tabActiveID={tabActiveID} isLoading={isLoading}>
                <form onSubmit={handleSubmit}>
                    {updateProfile && <AlertSuccess message="Data updated successfully"/>}

                    <div className="form-group">
                        <label>Custom Revenue Model</label>
                        <SelectSingle {...inputCustomRevenueModel.bind} />
                        <DivError error={inputCustomRevenueModel.error}/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputCustomTitle">Custom title</label>
                        <input
                            type="text"
                            id="inputCustomTitle"
                            placeholder="Custom title for zone"
                            className={"form-control " + (inputCustomTitle.error.length > 0 ? "is-invalid" : '')}
                            {...inputCustomTitle.bind}/>
                        <div className="invalid-feedback">{inputCustomTitle.error}</div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="inputDescription">Local Description</label>
                        <textarea
                            id="inputDescription"
                            placeholder="Description"
                            className={"form-control " + (inputDescription.error.length > 0 ? "is-invalid" : '')}
                            {...inputDescription.bind}></textarea>
                        <div className="invalid-feedback">{inputDescription.error}</div>
                    </div>

                    <div className="form-group">
                        <ButtonSubmit isLoading={updateIsLoading} text="Save changes"/>
                    </div>
                </form>
            </Tab>

            {isDebug() && <Card><Dump value={profile}/></Card>}
        </>)
}

ExStatZoneEditFormTabCustomSettings.propTypes = {
    tabActiveID: PropTypes.string.isRequired,
}
