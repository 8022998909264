import React from "react";

export const isDebug = () => {
    return process.env.NODE_ENV !== 'production';
};

export const Dump = ({value}) => {
    return (
        isDebug() &&
        <pre>{JSON.stringify(value, null, 2)}</pre>
    )
}
export const consoleLog = function () {
    if (isDebug()) {
        console.log(...arguments);
    }
}

export const consoleLogError = function () {
    // if (isDebug()) {
        console.log('Error:', ...arguments);
    // }
}

export const consoleLogResponse = function () {
    if (isDebug()) {
        console.log('Response:', ...arguments);
    }
}
