import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {CopyToClipboard} from "react-copy-to-clipboard/src";

import Container from "../../components/Container";
import Card from "../../components/Сard";
import ExternalLink from "../../components/ExternalLink";
import DivFormGroupRow from "../../components/form/DivFormGroupRow";
import SelectSingle from "../../components/select/SelectSingle";
import DivError from "../../components/form/DivError";
import {useInput, useSelect} from "../../../components/input-hook";
import {SelectDataFormatter} from "../../../components/select_data";
import {TRAFFIC_SOURCES_ID, TRAFFIC_SOURCES_PLACEHOLDERS} from "../../../components/traffic_sources";

const LandingLinkPage2 = ({user}) => {
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(true);

    const inputUrl = useInput('');
    const inputSource = useSelect(TRAFFIC_SOURCES_ID.CLICKADU);

    const [result, setResult] = useState('');
    const [placeholdersList, setPlaceholdersList] = useState([]);


    useEffect(() => {
        const promises = [];
        Promise.all(promises).then(results => {
            inputSource.setOptions(SelectDataFormatter.trafficSources());

            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        if (!isLoading) {
            let result = inputUrl.value;

            if (inputSource.value in TRAFFIC_SOURCES_PLACEHOLDERS) {
                console.log(inputSource.value)
                console.log([inputSource.value])
                console.log(TRAFFIC_SOURCES_PLACEHOLDERS)
                console.log(Object.values(TRAFFIC_SOURCES_PLACEHOLDERS[inputSource.value]))

                const pl = Object.values(TRAFFIC_SOURCES_PLACEHOLDERS[inputSource.value]);
                setPlaceholdersList(pl);

                const a = '______________________________';
                pl.forEach((v, i) => {
                    result = result.replace(v, a + i + a);
                })
                result = encodeURIComponent(result)
                pl.forEach((v, i) => {
                    result = result.replace(a + i + a, v);
                })
            } else {
                setPlaceholdersList([]);
                result = encodeURIComponent(result)
            }

            setResult(result)
        }
    }, [
        isLoading,
        inputUrl.value,
        inputSource.value,
    ]);

    const elementButton = <button className={"btn btn-secondary"}>Copy</button>;

    return (
        <Container title={"Landings 2"}>
            <Card>
                <form>
                    <DivFormGroupRow label="Url">
                        <input
                            type="text"
                            id="inputZoneId"
                            placeholder="Url"
                            className={"form-control " + (inputUrl.error.length > 0 ? "is-invalid" : '')}
                            {...inputUrl.bind}
                        />
                    </DivFormGroupRow>

                    <DivFormGroupRow label="Source">
                        <SelectSingle {...inputSource.bind} />
                        <DivError error={inputSource.error}/>

                        <div>Known placeholders:&ensp;
                            {placeholdersList.map((item, i) => (
                                <span className={inputUrl.value.includes(item) ? "text-success" : "text-muted"}>{item};&ensp;</span>
                            ))}
                        </div>
                    </DivFormGroupRow>
                </form>

                <table className={"table"}>
                    <tbody>
                    <tr>
                        <td>Encoded url</td>
                        <td><CopyToClipboard text={result}>{elementButton}</CopyToClipboard></td>
                        <td style={{maxWidth: '500px', overflowWrap: 'break-word'}}>{result}</td>
                    </tr>
                    <tr>
                        <td>Encoded url as u1 param</td>
                        <td><CopyToClipboard text={"&u1=" + result}>{elementButton}</CopyToClipboard></td>
                        <td style={{maxWidth: '500px', overflowWrap : 'break-word'}}>{"&u1=" + result}</td>
                    </tr>
                    <tr>
                        <td>Encoded url as u2 param</td>
                        <td><CopyToClipboard text={"&u2=" + result}>{elementButton}</CopyToClipboard></td>
                        <td style={{maxWidth: '500px', overflowWrap : 'break-word'}}>{"&u2=" + result}</td>
                    </tr>
                    </tbody>
                </table>

                Old script: <ExternalLink url={'https://jsfiddle.net/1maxg4dt/'}/>
            </Card>
        </Container>
    );
}

export default LandingLinkPage2;
