import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import axios from "axios";

import Container from "../../components/Container";
import Card from "../../components/Сard";
import ajax from "../../../data/ajax";
import { SelectDataFormatter } from "../../../components/select_data";
import {consoleLogResponse, Dump} from "../../../components/development";
import LoadingWrapper from "../../components/LoadingWrapper";
import ButtonFilter from "../../components/ButtonFilter";
import SelectMulti from "../../components/select/SelectMulti";
import {useSelect} from "../../../components/input-hook";
import Filter from "../../../components/filter";
import {ArrayIntersection, ArrayUnique, ObjectRemoveEmptyForUrl} from "../../../components/helpers";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import ExternalLink from "../../components/ExternalLink";
import SelectSingle from "../../components/select/SelectSingle";

const LandingLinkPage3 = ({user}) => {
    const location = useLocation();
    const locationPathname = location.pathname;

    const navigate = useNavigate();

    const [isLoadedFilterData, setIsLoadedFilterData] = useState(false);

    // const [tableParams] = TableParams();

    const [tableGroupHiddenColumns] = useState([]);
    const [tableUserHiddenColumns, setUserTableHiddenColumns] = useState();
    const [tableHiddenColumns, setTableHiddenColumns] = useState([]);
    useEffect(() => {
        setTableHiddenColumns(tableGroupHiddenColumns.concat(tableUserHiddenColumns))
    }, [tableGroupHiddenColumns, tableUserHiddenColumns]); // eslint-disable-line react-hooks/exhaustive-deps

    const [filterButton, setFilterButton] = useState(0);

    const inputFilterType = useSelect([]);
    const inputFilterTemplate = useSelect([]);
    const inputFilterTranslation = useSelect([]);
    const inputFilterScripts1 = useSelect([]);
    const inputFilterScripts2 = useSelect([]);
    const inputFilterAvailable = useSelect([]);
    const inputFilterManager = useSelect([]);

    const inputParamDomain = useSelect('bestmoviepick.com');
    const inputParamSource = useSelect('');

    const [dataRequestParams, setDataRequestParams] = useState({});
    // const [{data, isLoading}, setUrlParams] = BackendRequest('ex-stat/zone-rollerads');

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const requestConfig = ajax.getBaseConfig();
            const promises = [
                axios.get(window.location.protocol + "//" + window.location.host + "/landings_list.json?t=" + Math.floor(Date.now() / 1000), requestConfig),
            ];
            Promise.all(promises).then(function (results) {
                consoleLogResponse(results);
                const landData = results[0].data;

                const d = [];
                const types = [];
                const templates = [];
                let mechanics = [];
                landData.landings.forEach(item => {
                    const itemMechanics = [];
                    const chunks = [];
                    item.chunks.forEach(c => {
                        itemMechanics.push(c.split('/').slice(-1)[0]);
                        chunks.push({
                            'file': c,
                            'description': c in landData.chunks ? landData.chunks[c].description : "",
                            'params': c in landData.chunks ? ('params' in landData.chunks[c] ? landData.chunks[c].params : []) : [],
                        });
                    })
                    if (item.translation) {
                        const c = 'translation.ts';
                        chunks.push({
                            'file': c,
                            'description': landData.chunks[c].description,
                            'params': landData.chunks[c].params,
                        });
                    }
                    mechanics = mechanics.concat(itemMechanics)

                    d.push({
                        'filename': item.filename,
                        'type': item.type,
                        'template': item.template,
                        'translation': item.translation,
                        'chunks': chunks,
                        'mechanics': itemMechanics,
                    });

                    types.push(item.type);
                    templates.push(item.template);
                })
                setData(d);

                let filters = [
                    {
                        key: 'types',
                        data: SelectDataFormatter.value(ArrayUnique(types).sort()),
                        input: inputFilterType,
                    },
                    {
                        key: 'templates',
                        data: SelectDataFormatter.value(ArrayUnique(templates).sort()),
                        input: inputFilterTemplate,
                    },
                    {
                        key: 'translation',
                        data: SelectDataFormatter.bool(),
                        input: inputFilterTranslation,
                    },
                    {
                        key: 'script1',
                        data: SelectDataFormatter.value(ArrayUnique(mechanics).sort()),
                        input: inputFilterScripts1,
                    },
                    {
                        key: 'script2',
                        data: SelectDataFormatter.value(ArrayUnique(mechanics).sort()),
                        input: inputFilterScripts2,
                    },
                    // {
                    //     key: 'is_available',
                    //     data: SelectDataFormatter.bool(),
                    //     input: inputFilterAvailable,
                    // },
                    // {
                    //     key: 'managers',
                    //     data: SelectDataFormatter.managers(results[3].data),
                    //     input: inputFilterManager,
                    // },
                ];

                let params = Filter.collectParamsForFilter(location, filters)

                console.log("params", params);

                setIsLoadedFilterData(true);
            });
        };

        if (!isLoadedFilterData) {
            fetchData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoadedFilterData) {
            setDataRequestParams({
                types: inputFilterType.value,
                templates: inputFilterTemplate.value,
                translation: inputFilterTranslation.value,
                scripts1: inputFilterScripts1.value,
                scripts2: inputFilterScripts2.value,
                is_available: inputFilterAvailable.value,
                managers: inputFilterManager.value,
            })
        }
    }, [
        isLoadedFilterData,
        inputFilterType.value,
        inputFilterTemplate.value,
        inputFilterTranslation.value,
        inputFilterScripts1.value,
        inputFilterScripts2.value,
        inputFilterAvailable.value,
        inputFilterManager.value,
        filterButton
    ]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Object.keys(dataRequestParams).length > 0) {
            const p = locationPathname + '?' + (new URLSearchParams(ObjectRemoveEmptyForUrl(dataRequestParams))).toString();
            navigate(p);

            const filtered = data.filter(item => {
                let pass = true;
                pass = pass && (!inputFilterType.value.length || inputFilterType.value.includes(item.type));
                pass = pass && (!inputFilterTemplate.value.length || inputFilterTemplate.value.includes(item.template));
                pass = pass && (inputFilterTranslation.value === null || Boolean(Number(inputFilterTranslation.value)) === item.translation);
                pass = pass && (!inputFilterScripts1.value.length || ArrayIntersection(inputFilterScripts1.value, item.mechanics).length > 0);
                pass = pass && (!inputFilterScripts2.value.length || ArrayIntersection(inputFilterScripts2.value, item.mechanics).length > 0);
                // pass = pass && (!dataRequestParams.origin_site_id || dataRequestParams.origin_site_id === item.origin_site_id);
                // pass = pass && (!dataRequestParams.direction || dataRequestParams.direction === item.direction);
                // pass = pass && (!dataRequestParams.is_available || dataRequestParams.is_available === item.is_available);
                // pass = pass && (!dataRequestParams.managers.length || dataRequestParams.managers.includes(item.managers));
                return pass;
            });
            setFilteredData(filtered);
        }
    }, [dataRequestParams]);

    return (
        <Container title={"Landings"}>
            <LoadingWrapper isLoaded={isLoadedFilterData}>
                <Card>
                    <div className="row">
                        <div className="col-md-2">
                            <b>Type</b>
                            <SelectMulti {...inputFilterType.bind}/>
                        </div>
                        <div className="col-md-2">
                            <b>Template</b>
                            <SelectMulti {...inputFilterTemplate.bind}/>
                        </div>
                        <div className="col-md-2">
                            <b>Translation</b>
                            <SelectSingle {...inputFilterTranslation.bind}/>
                        </div>
                        <div className="col-md-2">
                            <b>Script 1</b>
                            <SelectMulti {...inputFilterScripts1.bind}/>
                        </div>
                        <div className="col-md-2">
                            <b>Script 2</b>
                            <SelectMulti {...inputFilterScripts2.bind}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <ButtonFilter setFilterButton={v => setFilterButton(v)}/>
                        </div>
                        <div className="col-md-9">&nbsp;</div>
                    </div>
                </Card>
            </LoadingWrapper>

            <Card>
                {filteredData.map((item, i) => {
                    const url = "https://" + inputParamDomain.value + "/" + item.filename;

                    return (
                        <div key={i}>
                            <div className="row">
                                <div className="col-md-11">
                                    <h3><ExternalLink url={url}/></h3>
                                </div>
                                <div className="col-md-1">
                                    <CopyToClipboard text={url}>
                                        <button className={"btn btn-secondary"}>Copy</button>
                                    </CopyToClipboard>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <div>Template: {item.template}</div>
                                    <div>Type: {item.type}</div>
                                    <div>Translation: {item.translation ? "Yes" : "No"}</div>
                                </div>
                                <div className="col-md-9">
                                    <ul>
                                        {item.chunks.map((item, i) => {
                                            const pk = Object.keys(item.params)
                                            return (
                                                <li key={i}>
                                                    <b>{item.file}</b> - {item.description} <br/>
                                                    {pk.length > 0 && <>
                                                        Параметры:
                                                        <ul>
                                                            {pk.map((param, i) => (
                                                                <li key={i}>
                                                                    <b>{param}</b>: {item.params[param].description}<br/>
                                                                    <span>required: {item.params[param].required ? "Yes" : "No"}; default value: {item.params[param].default}</span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </>}
                                                    {/*<Dump value={item}/>*/}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    {/*<Dump value={item}/>*/}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Card>
        </Container>
    );
}

export default LandingLinkPage3;
