import React from 'react';
import PropTypes from "prop-types";

import {consoleLogError} from "../../components/development";
import Loading from "./Loading";

export default function LoadingWrapper({children, isLoaded, error}) {
    if (error) {
        consoleLogError(error)
        return <div>Ошибка: {error.message}</div>
    } else if (!isLoaded) {
        return <Loading/>
    } else {
        return <>{children}</>
    }
}

LoadingWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element.isRequired,
        PropTypes.string.isRequired
    ]),
    isLoaded: PropTypes.bool.isRequired,
    error: PropTypes.object,
}

LoadingWrapper.defaultProps = {
    isLoaded: false,
    // error: {
    //     message: "Что-то пошло не так"
    // },
};
