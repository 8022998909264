import {useEffect, useState} from "react";
import axios from "axios";

import ajax from "./ajax";
import {consoleLog, consoleLogError, consoleLogResponse} from "../components/development";

export const useProfileRequest = (initialData) => {
    const [data, setData] = useState(initialData);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        setError(null);

        const url = process.env.REACT_APP_API_URL + "profile/0";

        axios.get(url, ajax.getBaseConfigWithResponseType())
            .then((response) => {
                consoleLogResponse(response);
                setData(response.data);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    window.location.replace("/index.html");
                }
                consoleLogError(error);
                setError(error);
            })
            .then(() => setIsLoading(false));
    }, []);

    return [{data, isLoading, error}];
};

export const useProfileUpdate = () => {
    const [requestData, setRequestData] = useState(null);
    const [data, setData] = useState(null);
    const [dataErrors, setDataErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (requestData) {
            consoleLog('RequestData:', requestData);

            setIsLoading(true);
            setError(null);
            setDataErrors([]);

            const url = process.env.REACT_APP_API_URL + "profile/0";

            axios.put(url, requestData, ajax.getBaseConfigWithResponseType())
                .then((response) => {
                    consoleLogResponse(response);
                    setData(response.data);
                })
                .catch((error) => {
                    consoleLogError(error, error.message);
                    if (error.response.status === 401) {
                        window.location.replace("/index.html");
                    }else if (error.response.status === 422) {
                        setDataErrors(error.response.data);
                    } else {
                        setError(error);
                    }
                })
                .then(() => {
                    setIsLoading(false);
                    window.scrollTo(0, 0);
                });
        }
    }, [requestData]);

    return [{data, setRequestData, isLoading, error, dataErrors}];
};
