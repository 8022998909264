import React from 'react';
import {useParams} from "react-router-dom";

import Container from "../../../components/Container";
import {useCampaignRequest} from "../../../../data/campaign";
import {Dump} from "../../../../components/development";

export default function ExStatCampaignItem() {
    let {id} = useParams();

    const [{data}] = useCampaignRequest(id);

    return (
        <Container title={"Campaign " + id}>
            <div className="row">
                <Dump value={data} />
            </div>
        </Container>
    )
}
