const PLAN_NO = 'no';
const PLAN_FREE = 'free';
const PLAN_ENTERPRISE = 'enterprise';

const TYPE_REDIRECT = "1"
const TYPE_SMARTLINK = "2"
const TYPE_PUSH = "3"

const STATUS_OLD = "0"
const STATUS_ACTIVE = "1"
const STATUS_NEW = "2"

const getPlan = (value) => {
    return value
}

const getType = (value) => {
    value = value + '';
    switch (value) {
        case TYPE_REDIRECT:
            return 'Redirect';
        case TYPE_SMARTLINK:
            return 'Smartlink';
        case TYPE_PUSH:
            return 'Push';
        default:
            return 'Unknown (' + value + ')';
    }
}

const getStatus = (value) => {
    value = value + '';
    switch (value) {
        case STATUS_OLD:
            return 'Old';
        case STATUS_ACTIVE:
            return 'Active';
        case STATUS_NEW:
            return 'New';
        default:
            return 'Unknown (' + value + ')';
    }
}

const DomainData = {
    PLAN_NO,
    PLAN_FREE,
    PLAN_ENTERPRISE,
    TYPE_REDIRECT,
    TYPE_SMARTLINK,
    TYPE_PUSH,
    STATUS_OLD,
    STATUS_ACTIVE,
    STATUS_NEW,
    getPlan,
    getType,
    getStatus,
}

export default DomainData;
