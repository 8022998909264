import React from "react";

export default function TableParams() {
    const keyCode = 'code';

    const columns = React.useMemo(
        () => [
            {
                Header: 'Code',
                accessor: keyCode,
            },
            {
                Header: 'Country',
                accessor: 'name',
            },
            {
                Header: 'Rate, $',
                accessor: 'rate',
                sortType: 'basic',
            },
        ],
        []
    );

    const initialState = {
        sortBy: [
            {
                id: keyCode,
                desc: false
            }
        ]
    }

    return [{columns, initialState}];
}
