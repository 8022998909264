import React from 'react';
import {Outlet} from "react-router-dom";

function Parent() {
    return (
        <Outlet/>
    );
}

export default Parent;
