import React, {useEffect, useState} from 'react';

import AlertDanger from "./AlertDanger";

export default function JsonValidate({value}) {
    const [message, setMessage] = useState("");

    useEffect(() => {
        try {
            if (value !== "" && value !== null) {
                JSON.parse(value);
            }
            setMessage("")
        } catch (e) {
            setMessage(e.message)
        }
    }, [value]);

    return (
        <>
            {message !== "" && <AlertDanger message={message} messagePrefix={"Parse error"}/>}
        </>

    )
}
