export const getZoneRevenueModelsList = () => {
    return [
        {value: '1', label: 'Not specified'},
        {value: '2', label: 'Revshare'},
        {value: '3', label: 'CPA'},
    ];
};

export const getZoneRevenueModelName = (val) => {
    let title = "Unknown";
    const list = getZoneRevenueModelsList();
    list.forEach(i => {
        if (i.value == val) {
            title = i.label;
        }
    })

    return title;
};

export const getCampaignUpdateIntervalList = () => {
    return ['5m', '1h', '1d'].map(i => {
        return {value: i, label: i}
    });
    // return [
    //     {value: '5m', label: '5m'},
    //     {value: '1h', label: '1h'},
    //     {value: '1d', label: '1d'},
    // ];
};
