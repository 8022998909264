import React from "react";
import {Link} from "react-router-dom";
import * as Icon from "react-feather";
import {round} from "../../../../components/Math";

export default function ExStatZoneListTableParams(user) {
    const keyDate = 'date';
    const keyDateTail = 'date_tail';
    const keyCountry = 'country';
    const keyZoneId = 'zone';
    const keyZoneOriginId = 'zone_origin_id';
    const keyZoneName = 'zone_name';
    const keyZoneEdit = 'zone_edit';
    const keyRevenueTb = 'revenue_tb';
    const keyProfitTb = 'profit_tb';
    const keyTotalROI = 'total_roi';
    const keyTbOfCost = 'tb_of_cost';
    const keyROI = 'roi';

    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: keyZoneId,
            },
            {
                Header: 'Zone ID',
                accessor: keyZoneOriginId,
            },
            {
                Header: 'Name',
                accessor: keyZoneName,
                Cell: ({row}) => {
                    return <>
                        {row.original.zone_custom_name && row.original.zone_custom_name.length > 0
                            ? row.original.zone_custom_name + " (" + row.values[keyZoneName] + ")"
                            : row.values[keyZoneName]}
                    </>
                },
            },
            {
                Header: 'Date Spend',
                accessor: keyDateTail,
                Footer: <b>Total:</b>,
            },
            {
                Header: 'Date',
                accessor: keyDate,
                Footer: <b>Total:</b>,
            },
            {
                Header: 'Country',
                accessor: keyCountry,
                Footer: <b>Total:</b>,
            },
            {
                Header: 'Impressions',
                accessor: 'impressions',
                Footer: info => {
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.impressions) + sum, 0),
                        [info.rows]
                    )

                    return <>{round(total, 4)}</>
                },
            },
            {
                Header: 'Conversions',
                accessor: 'conversions',
                Footer: info => {
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.conversions) + sum, 0),
                        [info.rows]
                    )

                    return <>{round(total, 4)}</>
                },
            },
            {
                Header: 'Cost, $',
                accessor: 'cost',
                Footer: info => {
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.cost) + sum, 0),
                        [info.rows]
                    )

                    return <>{round(total, 4)}</>
                },
            },
            {
                Header: 'eCPA',
                accessor: 'ecpa',
                Footer: info => {
                    const totalCost = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.cost) + sum, 0),
                        [info.rows]
                    )
                    const totalConversions = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.conversions) + sum, 0),
                        [info.rows]
                    )

                    if (totalCost !== 0) {
                        return <>{round(totalCost / totalConversions, 6)}</>
                    } else {
                        return <></>
                    }
                },
            },
            {
                Header: 'Revenue, $',
                accessor: 'revenue',
                Footer: info => {
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.revenue) + sum, 0),
                        [info.rows]
                    )

                    return <>{round(total, 4)}</>
                },
            },
            {
                Header: 'Profit, $',
                accessor: 'profit',
                sortType: 'basic',
                Footer: info => {
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.revenue) - Number(row.values.cost) + sum, 0),
                        [info.rows]
                    )

                    return <>{round(total, 4)}</>
                },
            },
            {
                Header: 'ROI, %',
                accessor: keyROI,
                sortType: 'basic',
                Footer: info => {
                    const totalRevenue = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.revenue) + sum, 0),
                        [info.rows]
                    )

                    const totalCost = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.cost) + sum, 0),
                        [info.rows]
                    )

                    if (totalCost !== 0) {
                        return <>{round(100 * (totalRevenue - totalCost) / totalCost, 2)}</>
                    } else {
                        return <></>
                    }
                },
            },
            {
                Header: 'Revenue TB, $',
                accessor: keyRevenueTb,
                Footer: info => {
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.revenue_tb) + sum, 0),
                        [info.rows]
                    )

                    return <>{round(total, 4)}</>
                },
            },
            {
                Header: 'Profit + TB, $',
                accessor: keyProfitTb,
                sortType: 'basic',
                Footer: info => {
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.revenue) + Number(row.values.revenue_tb) - Number(row.values.cost) + sum, 0),
                        [info.rows]
                    )

                    return <>{round(total, 4)}</>
                },
            },
            {
                Header: 'TB / cost, %',
                accessor: keyTbOfCost,
                sortType: 'basic',
                Footer: info => {
                    const totalRevenueTb = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.revenue_tb) + sum, 0),
                        [info.rows]
                    )

                    const totalCost = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.cost) + sum, 0),
                        [info.rows]
                    )

                    if (totalCost !== 0) {
                        return <>{round(100 * totalRevenueTb / totalCost, 2)}</>
                    } else {
                        return <></>
                    }
                },
            },
            {
                Header: 'Total ROI, %',
                accessor: keyTotalROI,
                sortType: 'basic',
                Footer: info => {
                    const totalRevenue = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.revenue) + Number(row.values.revenue_tb) + sum, 0),
                        [info.rows]
                    )

                    const totalCost = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.cost) + sum, 0),
                        [info.rows]
                    )

                    if (totalCost !== 0) {
                        return <>{round(100 * (totalRevenue - totalCost) / totalCost, 2)}</>
                    } else {
                        return <></>
                    }
                },
            },
            {
                // id: "actions",
                accessor: keyZoneEdit,
                // accessor: 'id',
/*                Cell: ({ original }) => (
                    <button value={original.id} >
                        {/!* onClick={props.handleClickGroup} *!/}
                        {original.id}
                    </button>
                )*/
                Cell: ({row}) => {
                    // console.log("---- row ----", {row})
                    return <>
                    {/*    <button onClick={(e) => {*/}
                    {/*    e.preventDefault();*/}
                    {/*    console.log("---- button onClick ----", {row})*/}
                    {/*    //setActiveTabID(item.key)*/}
                    {/*}}>Edit</button>*/}

                        <Link to={"/ex-stat/zone/edit/"+ row.values.zone}><Icon.Settings /></Link>
                        </>
                },
                width: 100,
            }
        ],
        []
    );

    const initialState = {
        sortBy: [
            {
                id: keyDate,
                desc: true
            }
        ]
    }

    return [{keyZoneId, keyZoneOriginId, keyZoneName, keyDateTail, keyDate, keyCountry, keyZoneEdit, keyRevenueTb, keyProfitTb, keyTotalROI, keyTbOfCost, columns, initialState}];
}
