import React from 'react';
import PropTypes from 'prop-types';

export default function Alert({messagePrefix, message, className}) {
    return (
        <div className={"alert " + className}>
            <div className="alert-message">
                {messagePrefix.length > 0 && <strong>{messagePrefix}</strong>} {message}
            </div>
        </div>
    )
}

Alert.propTypes = {
    message: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]).isRequired,
    messagePrefix: PropTypes.string,
    className: PropTypes.string,
}

Alert.defaultProps = {
    messagePrefix: "",
    className: "alert-secondary",
}
