import React, {useEffect} from 'react'
import {usePagination, useSortBy, useTable} from 'react-table'

export default function TablePaginated({columns, data, initialState, hiddenColumns}) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        // rows,
        prepareRow,
        setHiddenColumns,

        // https://react-table.tanstack.com/docs/examples/pagination
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize},
    } = useTable(
        {
            columns,
            data,
            initialState,
        },
        useSortBy, usePagination)

    useEffect(() => {
        setHiddenColumns(hiddenColumns)
    }, [hiddenColumns]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {/*
            <pre>
                <code>
                {JSON.stringify(
                    {
                        pageIndex,
                        pageSize,
                        pageCount,
                        canNextPage,
                        canPreviousPage,
                    },
                    null,
                    2
                )}
                </code>
            </pre>
            */}

            <table {...getTableProps()} className="table table-striped dataTable">
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className={"sorting"}>
                                {column.render('Header')}
                                <span>
                            {column.isSorted
                                ? column.isSortedDesc
                                    ? ' 🔽'
                                    : ' 🔼'
                                : ''}
                            </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
                </tbody>
                <tfoot>
                {footerGroups.map(group => (
                    <tr {...group.getFooterGroupProps()} className="table-info">
                        {group.headers.map(column => (
                            <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                        ))}
                    </tr>
                ))}
                </tfoot>
            </table>

            {/*
            Pagination can be built however you'd like.
            This is just a very basic UI implementation:
            */}
            <div className="pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>
                {' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>{'<'}</button>
                {' '}
                <button onClick={() => nextPage()} disabled={!canNextPage}>{'>'}</button>
                {' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                {' '}
                <span>
                    Page{' '}
                    <strong>{pageIndex + 1} of {pageOptions.length}</strong>{' '}
                </span>
                <span>
                  | Go to page:{' '}
                            <input
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    gotoPage(page)
                                }}
                                style={{width: '100px'}}
                            />
                </span>
                {' '}
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 50, 100, 200, 500, 1000].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </>
    )
}

TablePaginated.defaultProps = {
    columns: [],
    data: [],
    initialState: {},
    hiddenColumns: [],
}
