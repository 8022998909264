import React from "react";

export default function DataValidationSummaryListTableParams() {
    const keyDate = 'date';

    const columns = React.useMemo(
        () => {
            return [
                {
                    Header: 'Date',
                    accessor: keyDate,
                },
                {
                    Header: 'Adv Source Value',
                    accessor: 'value_advertiser_source',
                },
                {
                    Header: 'Adv Cabinet Value',
                    accessor: 'value_advertiser_cabinet',
                },
                {
                    Header: 'Adv Cabinet %',
                    accessor: 'advertiser_cabinet_percentage',
                },
                {
                    Header: 'Pub Source Value',
                    accessor: 'value_publisher_source',
                },
                {
                    Header: 'Pub Cabinet Value',
                    accessor: 'value_publisher_cabinet',
                },
                {
                    Header: 'Pub Cabinet %',
                    accessor: 'publisher_cabinet_percentage',
                },
            ];
        },
        []
    );

    const initialState = {
        sortBy: [
            {
                id: keyDate,
                desc: true
            }
        ]
    }

    return {
        columns, initialState
    };
}
