import React from "react";

import DataData from "../../../../components/DataData";
import {
    formulaECPA,
    formulaTbCost,
    roundCPM,
    roundPercent,
    tableColumnSum
} from "../../../../components/table/TableListFunctions";
import {
    compareCell,
    compareCellDate,
    compareCellMoney,
    compareFooter,
    compareFooterMoney,
    compareFooterROI
} from "../../../components/table/helpers";
import TableTdCompare from "../../../components/table/TableTdCompare";

export default function ExStatDashboardTableParams() {
    const keyDate = 'date';
    const keyManagerGroupID = 'manager_group';
    const keyManagerGroupName = 'manager_group_name';
    const keyManagerID = 'manager';
    const keyManagerName = 'manager_email';
    const keyAccountID = 'account';
    const keyAccountName = 'account_name';
    const keyPubAccountID = 'pub_account';
    const keyPubAccountName = 'pub_account_name';
    const keyZoneID = 'origin_zone_id';
    const keyZoneName = 'zone_name';
    const keyZoneTbID = 'origin_zone_tb_id';
    const keyZoneTbName = 'zone_tb_name';
    const keyCampaignID = 'origin_campaign_id';
    const keyCampaignName = 'campaign_name';
    const keyCountry = 'country';
    const keyType = 'type';
    const keyTypeName = 'type_name';

    const columns = React.useMemo(
        () => {
            return [
                {
                    Header: 'Date',
                    accessor: keyDate,
                    Cell: compareCellDate(keyDate),
                    Footer: <b>Total:</b>,
                },
                {
                    Header: 'Country',
                    accessor: keyCountry,
                    Footer: <b>Total:</b>,
                },
                {
                    Header: 'Manager Group ID',
                    accessor: keyManagerGroupID,
                },
                {
                    Header: 'Manager Group',
                    accessor: keyManagerGroupName,
                },
                {
                    Header: 'Manager ID',
                    accessor: keyManagerID,
                },
                {
                    Header: 'Manager',
                    accessor: keyManagerName,
                },
                {
                    Header: 'Traffic source ID',
                    accessor: keyAccountID,
                },
                {
                    Header: 'Traffic source',
                    accessor: keyAccountName,
                },
                {
                    Header: 'Aff. network ID',
                    accessor: keyPubAccountID,
                },
                {
                    Header: 'Affiliate network',
                    accessor: keyPubAccountName,
                },
                {
                    Header: 'Zone ID',
                    accessor: keyZoneID,
                },
                {
                    Header: 'Zone',
                    accessor: keyZoneName,
                },
                {
                    Header: 'Zone TB ID',
                    accessor: keyZoneTbID,
                },
                {
                    Header: 'Zone TB',
                    accessor: keyZoneTbName,
                },
                {
                    Header: 'Campaign ID',
                    accessor: keyCampaignID,
                },
                {
                    Header: 'Campaign',
                    accessor: keyCampaignName,
                },
                {
                    Header: 'Type',
                    accessor: keyType,
                },
                {
                    Header: 'Type Name',
                    Cell: ({row: {original: o}}) => DataData.typeName(o['type']),
                    accessor: keyTypeName,
                },
                {
                    Header: 'Impressions',
                    accessor: 'impressions',
                    className: 'text-right',
                    Cell: compareCell('impressions', x => x),
                    Footer: compareFooter('impressions', x => x),
                },
                {
                    Header: 'Conversions',
                    accessor: 'conversions',
                    className: 'text-right',
                    Cell: compareCell('conversions', x => x),
                    Footer: compareFooter('conversions', x => x),
                },
                {
                    Header: 'Cost, $',
                    accessor: 'cost',
                    className: 'text-right',
                    sortType: 'basic',
                    Cell: compareCellMoney('cost'),
                    Footer: compareFooterMoney('cost'),
                },
                {
                    Header: 'eCPA',
                    accessor: 'ecpa',
                    className: 'text-right',
                    sortType: 'basic',
                    Cell: compareCell('ecpa', roundCPM),
                    Footer: ({rows}) => {
                        const ecpa = formulaECPA(tableColumnSum(rows, 'cost'), tableColumnSum(rows, 'conversions'));
                        let prev_ecpa = undefined;
                        if (rows.length > 0 && 'prev_cost' in rows[0].original) {
                            prev_ecpa = formulaECPA(tableColumnSum(rows, 'prev_cost'), tableColumnSum(rows, 'prev_conversions'));
                        }
                        return <TableTdCompare
                            val1={ecpa}
                            val2={prev_ecpa}
                            roundFunction={roundCPM}
                        />
                    },
                },
                {
                    Header: 'Revenue, $',
                    accessor: 'revenue',
                    className: 'text-right',
                    sortType: 'basic',
                    Cell: compareCellMoney('revenue'),
                    Footer: compareFooterMoney('revenue'),
                },
                {
                    Header: 'Profit, $',
                    accessor: 'profit',
                    className: 'text-right',
                    sortType: 'basic',
                    Cell: compareCellMoney('profit'),
                    Footer: compareFooterMoney('profit'),
                },
                {
                    Header: 'ROI, %',
                    accessor: 'roi',
                    className: 'text-right',
                    sortType: 'basic',
                    Cell: compareCell('roi', roundPercent),
                    Footer: compareFooterROI('revenue', 'cost'),
                },
                // {
                //     Header: 'Revenue TB, $',
                //     accessor: 'revenue_tb',
                //     className: 'text-right',
                //     sortType: 'basic',
                //     Cell: compareCellMoney('revenue_tb'),
                //     Footer: compareFooterMoney('revenue_tb'),
                // },
                // {
                //     Header: 'Profit + TB, $',
                //     accessor: 'profit_tb',
                //     className: 'text-right',
                //     sortType: 'basic',
                //     Cell: compareCellMoney('profit_tb'),
                //     Footer: compareFooterMoney('profit_tb'),
                // },
                // {
                //     Header: 'TB / cost, %',
                //     accessor: 'tb_of_cost',
                //     className: 'text-right',
                //     sortType: 'basic',
                //     Cell: compareCell('tb_of_cost', roundPercent),
                //     Footer: ({rows}) => {
                //         const tb_of_cost = formulaTbCost(tableColumnSum(rows, 'revenue_tb'), tableColumnSum(rows, 'cost'));
                //         let prev_tb_of_cost = undefined;
                //         if (rows.length > 0 && 'prev_cost' in rows[0].original) {
                //             prev_tb_of_cost = formulaTbCost(tableColumnSum(rows, 'prev_revenue_tb'), tableColumnSum(rows, 'prev_cost'));
                //         }
                //         return <TableTdCompare val1={tb_of_cost} val2={prev_tb_of_cost} roundFunction={roundPercent}/>
                //     },
                // },
                // {
                //     Header: 'Total ROI, %',
                //     accessor: 'total_roi',
                //     className: 'text-right',
                //     sortType: 'basic',
                //     Cell: compareCell('total_roi',  roundPercent),
                //     Footer: compareFooterROI('revenue_total', 'cost'),
                // },
            ];
        },
        []
    );

    const initialState = {
        sortBy: [
            {
                id: keyDate,
                desc: true
            }
        ]
    }

    return {
        keyDate,
        keyManagerGroupID,
        keyManagerGroupName,
        keyManagerID,
        keyManagerName,
        keyAccountID,
        keyAccountName,
        keyZoneID,
        keyZoneName,
        keyCampaignID,
        keyCampaignName,
        keyCountry,
        keyType,
        keyTypeName,
        keyPubAccountID,
        keyPubAccountName,
        keyZoneTbID,
        keyZoneTbName,
        columns,
        initialState,
    };
}
