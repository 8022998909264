import {round} from "../Math";

export const tableColumnSum = (rows, key) => rows.reduce((sum, row) => Number(row.original[key] ?? 0) + sum, 0)

export const formulaProfit = (revenue, cost) => revenue - cost
export const formulaROI = (profit, cost) => {
    if (cost === 0) {
        return 0
    }

    return 100 * profit / cost
}

export const formulaTbCost = (revenue_tb, cost) => {
    if (cost === 0) {
        return 0
    }

    return 100 * revenue_tb / cost
}

export const formulaECPA = (cost, conversions) => {
    if (conversions === 0) {
        conversions = 1
    }

    return cost / conversions
}

export const roundMoney = (value) => {
    return round(value, 3).toFixed(3)

    // const abs  = Math.abs(value);
    // if (abs < 1) {
    //     return round(value, 3)
    // } else if (abs < 10) {
    //     return round(value, 1)
    // }
    //
    // return round(value, 0)
}

export const roundPercent = (value) => round(value, 2).toFixed(2)
export const roundCPM = (value) => round(value, 6).toFixed(6)