import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from "react-router-dom";

import Container from "../../components/Container";
import Card from "../../components/Сard";
import Table from "../../components/table/Table";
import TableParams from "./ListTableParams";
import {SelectDataFormatter} from "../../../components/select_data";
import {consoleLogResponse} from "../../../components/development";
import {useSelect} from "../../../components/input-hook";
import Filter from "../../../components/filter";
import LoadingWrapper from "../../components/LoadingWrapper";
import SelectMulti from "../../components/select/SelectMulti";
import ButtonFilter from "../../components/ButtonFilter";
import TableColumns from "../../components/table/TableColumns";
import BackendRequest from "../../../data/BackendRequest";

const DomainsList = ({user}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [isLoadedFilterData, setIsLoadedFilterData] = useState(false);

    const localStorageTableKey = 'domains';
    const tableParams = TableParams();

    const defaultHiddenColumns = [];
    const [tableUserHiddenColumns, setTableUserHiddenColumns] = useState();
    const [tableHiddenColumns, setTableHiddenColumns] = useState([]);
    useEffect(() => {
        setTableHiddenColumns(defaultHiddenColumns.concat(tableUserHiddenColumns))
    }, [tableUserHiddenColumns]); // eslint-disable-line react-hooks/exhaustive-deps

    const [filterButton, setFilterButton] = useState(0);

    const inputFilterPlan = useSelect([]);
    const inputFilterType = useSelect([]);
    const inputFilterStatus = useSelect([]);
    const inputFilterIsAllowMulti = useSelect([]);
    const inputFilterIsGSB = useSelect(["1"]);

    const [dataRequestParams, setDataRequestParams] = useState({});
    const [{data, isLoading}, setUrlParams] = BackendRequest('domain');

    useEffect(() => {
        const fetchData = async () => {
            const promises = [];
            Promise.all(promises).then(function (results) {
                consoleLogResponse(results);

                let filters = [
                    {
                        key: 'plan',
                        data: SelectDataFormatter.domainPlansForSelect(),
                        input: inputFilterPlan,
                    },
                    {
                        key: 'type',
                        data: SelectDataFormatter.domainTypesForSelect(),
                        input: inputFilterType,
                    },
                    {
                        key: 'status',
                        data: SelectDataFormatter.domainStatusForSelect(),
                        input: inputFilterStatus,
                    },
                    {
                        key: 'gsb',
                        data: SelectDataFormatter.bool(),
                        input: inputFilterIsGSB,
                    },
                    {
                        key: 'allow_multi',
                        data: SelectDataFormatter.bool(),
                        input: inputFilterIsAllowMulti,
                    },
                ];

                let params = Filter.collectParamsForFilter(location, filters)

                setDataRequestParams(params)

                setIsLoadedFilterData(true);
            });
        };

        setIsLoadedFilterData(false);
        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoadedFilterData) {
            setDataRequestParams({
                plan: inputFilterPlan.value,
                landing_type: inputFilterType.value,
                status: inputFilterStatus.value,
                allow_multi: inputFilterIsAllowMulti.value,
                gsb: inputFilterIsGSB.value,
            })
        }
    }, [
        inputFilterPlan.value,
        inputFilterType.value,
        inputFilterStatus.value,
        inputFilterIsAllowMulti.value,
        inputFilterIsGSB.value,
        filterButton
    ]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Object.keys(dataRequestParams).length > 0) {
            navigate(location.pathname + '?' + (new URLSearchParams(dataRequestParams)).toString());
            setUrlParams(dataRequestParams);
        }
    }, [dataRequestParams]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container title={"Domains"}>
            <LoadingWrapper isLoaded={isLoadedFilterData}>
                <Card>
                    <div className="row">
                        <div className="col-md-2">
                            <b>Select plan</b>
                            <SelectMulti {...inputFilterPlan.bind}/>
                        </div>
                        <div className="col-md-2">
                            <b>Select type</b>
                            <SelectMulti {...inputFilterType.bind}/>
                        </div>
                        <div className="col-md-2">
                            <b>Select status</b>
                            <SelectMulti {...inputFilterStatus.bind}/>
                        </div>
                        <div className="col-md-2">
                            <b>Allow multi</b>
                            <SelectMulti {...inputFilterIsAllowMulti.bind}/>
                        </div>
                        <div className="col-md-2">
                            <b>GSB</b>
                            <SelectMulti {...inputFilterIsGSB.bind}/>
                        </div>
                        <div className="col-md-2">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <ButtonFilter setFilterButton={v => setFilterButton(v)}/>
                        </div>
                        <div className="col-md-6">&nbsp;</div>
                        <div className="col-md-3 text-right">
                            <TableColumns
                                storageKey={localStorageTableKey}
                                columns={tableParams.columns}
                                setUserTableHiddenColumns={v => setTableUserHiddenColumns(v)}
                            />
                        </div>
                    </div>
                </Card>
            </LoadingWrapper>

            <LoadingWrapper isLoaded={isLoadedFilterData && !isLoading}>
                <Card>
                    <Table
                        data={data}
                        {...tableParams}
                        hiddenColumns={tableHiddenColumns}
                    />
                </Card>
            </LoadingWrapper>
        </Container>
    );
}

export default DomainsList;
