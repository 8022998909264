import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from "react-router-dom";

import Container from "../../components/Container";
import Card from "../../components/Сard";
import Table from "../../components/table/Table";
import TableParams from "./ZoneGalaksionListTableParams";
import {SelectDataFormatter} from "../../../components/select_data";
import {consoleLogResponse} from "../../../components/development";
import LoadingWrapper from "../../components/LoadingWrapper";
import ButtonFilter from "../../components/ButtonFilter";
import SelectMulti from "../../components/select/SelectMulti";
import {useSelect} from "../../../components/input-hook";
import Filter from "../../../components/filter";
import TableColumns from "../../components/table/TableColumns";
import {ObjectRemoveEmptyForUrl} from "../../../components/helpers";
import BackendRequest from "../../../data/BackendRequest";
import {AFFILIATES_ID} from "../../../components/affiliates";

const ZoneGalaksionList = ({user}) => {
    const location = useLocation();
    const locationPathname = location.pathname;

    const navigate = useNavigate();

    const [isLoadedFilterData, setIsLoadedFilterData] = useState(false);

    const localStorageTableKey = 'table_zone_list_galaksion';
    const [tableParams] = TableParams();

    const [tableGroupHiddenColumns] = useState([]);
    const [tableUserHiddenColumns, setUserTableHiddenColumns] = useState();
    const [tableHiddenColumns, setTableHiddenColumns] = useState([]);
    useEffect(() => {
        setTableHiddenColumns(tableGroupHiddenColumns.concat(tableUserHiddenColumns))
    }, [tableGroupHiddenColumns, tableUserHiddenColumns]); // eslint-disable-line react-hooks/exhaustive-deps

    const [filterButton, setFilterButton] = useState(0);

    const inputFilterAccount = useSelect([]);
    const inputFilterPubAccount = useSelect([]);
    const inputFilterDirection = useSelect([]);
    const inputFilterAvailable = useSelect([]);
    const inputFilterManager = useSelect([]);

    const [dataRequestParams, setDataRequestParams] = useState({});
    const [{data, isLoading}, setUrlParams] = BackendRequest('ex-stat/zone-galaksion');

    useEffect(() => {
        const fetchData = async () => {
            const promises = [];
            Promise.all(promises).then(function (results) {
                consoleLogResponse(results);
                const [] = results;

                let filters = [
                    {
                        key: 'accounts',
                        data: SelectDataFormatter.accounts(user.getTrafficSourceAccounts()),
                        input: inputFilterAccount,
                    },
                    {
                        key: 'pub_accounts',
                        data: SelectDataFormatter.accounts(user.getAffiliateAccounts().filter(v => AFFILIATES_ID.GALAKSION === (v.network_id + ''))),
                        input: inputFilterPubAccount,
                    },
                    {
                        key: 'direction',
                        data: SelectDataFormatter.directions(),
                        input: inputFilterDirection,
                    },
                    {
                        key: 'is_available',
                        data: SelectDataFormatter.bool(),
                        input: inputFilterAvailable,
                    },
                    {
                        key: 'managers',
                        data: SelectDataFormatter.managers(user.getManagers()),
                        input: inputFilterManager,
                    },
                ];

                let params = Filter.collectParamsForFilter(location, filters)

                setIsLoadedFilterData(true);
            });
        };

        if (!isLoadedFilterData) {
            fetchData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoadedFilterData) {
            setDataRequestParams({
                accounts: inputFilterAccount.value,
                pub_accounts: inputFilterPubAccount.value,
                direction: inputFilterDirection.value,
                is_available: inputFilterAvailable.value,
                managers: inputFilterManager.value,
            })
        }
    }, [
        isLoadedFilterData,
        inputFilterAccount.value,
        inputFilterPubAccount.value,
        inputFilterDirection.value,
        inputFilterAvailable.value,
        inputFilterManager.value,
        filterButton
    ]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Object.keys(dataRequestParams).length > 0) {
            navigate(locationPathname + '?' + (new URLSearchParams(ObjectRemoveEmptyForUrl(dataRequestParams))).toString());
            setUrlParams(dataRequestParams);
        }
    }, [dataRequestParams]);

    return (
        <Container title={"Galaksion Zones List"}>
            <LoadingWrapper isLoaded={isLoadedFilterData}>
                <Card>
                    <div className="row">
                        <div className="col-md-2">
                            <b>Affiliate network</b>
                            <SelectMulti {...inputFilterPubAccount.bind}/>
                        </div>
                        <div className="col-md-2">
                            <b>Direction</b>
                            <SelectMulti {...inputFilterDirection.bind}/>
                        </div>
                        <div className="col-md-2">
                            <b>Traffic source</b>
                            <SelectMulti {...inputFilterAccount.bind}/>
                        </div>
                        <div className="col-md-2">
                            <b>Manager</b>
                            <SelectMulti {...inputFilterManager.bind}/>
                        </div>
                        <div className="col-md-2">
                            <b>Is available</b>
                            <SelectMulti {...inputFilterAvailable.bind}/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <ButtonFilter setFilterButton={v => setFilterButton(v)} />
                        </div>
                        <div className="col-md-6">&nbsp;</div>
                        <div className="col-md-3 text-right">
                            <TableColumns
                                storageKey={localStorageTableKey}
                                columns={tableParams.columns}
                                setUserTableHiddenColumns={v => setUserTableHiddenColumns(v)}
                            />
                        </div>
                    </div>
                </Card>
            </LoadingWrapper>

            <LoadingWrapper isLoaded={isLoadedFilterData && !isLoading}>
                <Card>
                    <Table
                        data={data}
                        {...tableParams}
                        hiddenColumns={tableHiddenColumns}
                    />
                </Card>
            </LoadingWrapper>
        </Container>
    );
}

export default ZoneGalaksionList;
