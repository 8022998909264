import {useState, useEffect} from 'react';

import ajax from "./ajax";
import axios from "axios";

import {consoleLogResponse} from "../components/development";
import {PERMISSIONS} from "../components/permissions";

export default function DataExStatDashboard(user) {
    const [params, setParams] = useState({});
    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsError(false);
            setIsLoading(true);
            setData([]);

            try {
                if (Object.keys(params).length > 0) {
                    const requestConfig =  ajax.getBaseConfig();
                    const urlParams = (new URLSearchParams(params)).toString();

                    const emptyPromise = new Promise((resolve, reject) => resolve(undefined));

                    const promises = [
                        user.allowPermission(PERMISSIONS.DASHBOARD_MAIN)
                            ? axios.get(window.trackerBaseUrl + "ex-stat/dashboard?" + (new URLSearchParams(Object.assign({}, params, {"dashboard": 1}))).toString(), requestConfig)
                            : emptyPromise,
                        user.allowPermission(PERMISSIONS.DASHBOARD_ZEYDOO)
                            ? axios.get(window.trackerBaseUrl + "ex-stat/dashboard?" + (new URLSearchParams(Object.assign({}, params, {"dashboard": 4}))).toString(), requestConfig)
                            : emptyPromise,
                        user.allowPermission(PERMISSIONS.DASHBOARD_SMARTLINK)
                            ? axios.get(window.trackerBaseUrl + "ex-stat/dashboard2?" + urlParams, requestConfig)
                            : emptyPromise,
                        user.allowPermission(PERMISSIONS.DASHBOARD_CLICKADU)
                            ? axios.get(window.trackerBaseUrl + "ex-stat/dashboard3?" + urlParams, requestConfig)
                            : emptyPromise,

                    ];

                    Promise.all(promises).then(results => {
                        consoleLogResponse(results);
                        let data = {};

                        const empty = {
                            total_cost: 0,
                            total_revenue: 0,
                        };

                        results.filter(x => x !== undefined).forEach(dash => {
                            dash.data.forEach(v => {
                                if (!(v.date in data)) {
                                    data[v.date] = {...empty};
                                    data[v.date].date = v.date;
                                }

                                ['cost', 'cost_test'].forEach(key => {
                                    if (key in v) {
                                        data[v.date].total_cost += Number(v[key])
                                    }
                                });
                                ['revenue', 'revenue_tb'].forEach(key => {
                                    if (key in v) {
                                        data[v.date].total_revenue += Number(v[key])
                                    }
                                });
                            });
                        });

                        data = Object.values(data);

                        // setIsLoaded(true);
                        setData(data);
                    });
                }
            } catch (error) {
                console.log('error', error)
                setIsError(true);
            }

            setIsLoading(false);
        };

        fetchData();
    }, [params]);

    return [{ data, isLoading, isError }, setParams];
}
