import React from 'react';
import PropTypes from 'prop-types';

import Card from "./Сard";

export default function GuestContainer({children, header}) {
    return (
        <main className="d-flex w-100">
            <div className="container d-flex flex-column">
                <div className="row vh-100">
                    <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                        <div className="d-table-cell align-middle">
                                <div className="text-center mt-4">
                                    {
                                        false &&
                                        <h1 className="h2">Welcome back, Charles</h1>
                                    }
                                    <p className="lead">{header}</p>
                                </div>
                            <Card>
                                <div className="m-sm-4">
                                    {children}
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

GuestContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element.isRequired,
        PropTypes.string.isRequired
    ]),
    header: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string
    ]),
}

GuestContainer.defaultProps = {
    header: '',
}
