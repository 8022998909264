import React, {useEffect, useState} from 'react';
import * as Icon from "react-feather";
import {Link, useParams} from "react-router-dom";
import axios from "axios";

import ButtonSubmit from "../../../../components/ButtonSubmit";
import AlertSuccess from "../../../../components/AlertSuccess";
import {useCheckboxInput, useInput, useSelect} from "../../../../../components/input-hook";
import SelectSingle from "../../../../components/select/SelectSingle";
import {useCampaignClickaduUpdate, useCampaignRequest} from "../../../../../data/campaign";
import {getCampaignUpdateIntervalList} from "../../../../../data/static_data";
import {consoleLog, consoleLogResponse, Dump, isDebug} from "../../../../../components/development";
import AlertDanger from "../../../../components/AlertDanger";
import Card from "../../../../components/Сard";
import Container from "../../../../components/Container";
import LoadingWrapper from "../../../../components/LoadingWrapper";
import {SelectDataFormatter, UrlCreator} from "../../../../../components/select_data";
import ajax from "../../../../../data/ajax";
import SelectMulti from "../../../../components/select/SelectMulti";
import DivError from "../../../../components/form/DivError";
import DivFormGroupRow from "../../../../components/form/DivFormGroupRow";
import {round} from "../../../../../components/Math";
import DivFormGroupRowCheckBox from "../../../../components/form/DivFormGroupRowCheckBox";
import {ArrayUnique, strToArray} from "../../../../../components/helpers";

export default function ExStatCampaignClickaduEditForm({user}) {
    let {id} = useParams();

    const [placements, setPlacements] = useState([])

    const [campOriginId, setCampOriginId] = useState('');

    const [{data: profile, isLoading, error: dataError}] = useCampaignRequest(id);
    const [{
        data: updateProfile,
        setRequestData,
        isLoading: updateIsLoading,
        error: updateError,
        dataErrors
    }] = useCampaignClickaduUpdate(id);

    const inputCampName = useInput('');
    const inputCampUrl = useInput('');
    const inputCampBudgetDailyAmount = useInput('');
    const inputCampBudgetTotalAmount = useInput('');
    const inputCampUserFrequency = useInput('');
    const inputCampUserCapping = useInput('');
    const inputCampPrice = useInput('');
    const inputCampZones = useInput('');

    const inputCampFeed = useSelect();
    const inputCampTrafficVertical = useSelect();
    const inputCampConnection = useSelect();
    const inputCampProxy = useSelect();
    const inputUpdateInterval = useSelect();

    const inputCampOsTypes = useSelect([]);
    const inputCampOs = useSelect([]);
    const inputCampOsVersions = useSelect([]);
    const inputCampDeviceTypes = useSelect([]);
    const inputCampDeviceVendors = useSelect([]);
    const inputCampDeviceModels = useSelect([]);
    const inputCampMobileISP = useSelect([]);
    const inputCampBrowsers = useSelect([]);
    const inputCampBrowserLanguage = useSelect([]);
    const inputCampBrowserVersions = useSelect([]);

    const inputCampOsTypesIsExcluded = useCheckboxInput(false);
    const inputCampOsIsExcluded = useCheckboxInput(false);
    const inputCampOsVersionsIsExcluded = useCheckboxInput(false);
    const inputCampDeviceTypesIsExcluded = useCheckboxInput(false);
    const inputCampDeviceVendorsIsExcluded = useCheckboxInput(false);
    const inputCampDeviceModelsIsExcluded = useCheckboxInput(false);
    const inputCampMobileISPIsExcluded = useCheckboxInput(false);
    const inputCampBrowsersIsExcluded = useCheckboxInput(false);
    const inputCampBrowserLanguageIsExcluded = useCheckboxInput(false);
    const inputCampBrowserVersionsIsExcluded = useCheckboxInput(false);
    const inputCampZonesIsExcluded = useCheckboxInput(false);

    const setFromResponse = (result) => {
        console.log('result', result)

        if (result.clickadu_data) {
            inputCampName.setValue(result.clickadu_data.name);
            inputCampUrl.setValue(result.clickadu_data.targetUrl);
            inputCampFeed.setValue(result.clickadu_data.feed);
            inputCampTrafficVertical.setValue(result.clickadu_data.trafficVertical);
            inputCampBudgetDailyAmount.setValue(result.clickadu_data.dailyAmount);
            inputCampBudgetTotalAmount.setValue(result.clickadu_data.totalAmount);
            inputCampUserFrequency.setValue(result.clickadu_data.frequency);
            inputCampUserCapping.setValue(Math.floor(result.clickadu_data.capping / 3600));

            if (result.clickadu_data.targeting) {
                const t = result.clickadu_data.targeting;
                inputCampConnection.setValue(t.connection ? t.connection : 'all');
                if (t.osType) {
                    inputCampOsTypes.setValue(t.osType.list);
                    inputCampOsTypesIsExcluded.setValue(t.osType.isExcluded);
                }
                if (t.os) {
                    inputCampOs.setValue(t.os.list);
                    inputCampOsIsExcluded.setValue(t.os.isExcluded);
                }
                if (t.osVersion) {
                    inputCampOsVersions.setValue(t.osVersion.list);
                    inputCampOsVersionsIsExcluded.setValue(t.osVersion.isExcluded);
                }
                if (t.deviceType) {
                    inputCampDeviceTypes.setValue(t.deviceType.list);
                    inputCampDeviceTypesIsExcluded.setValue(t.deviceType.isExcluded);
                }
                if (t.vendor) {
                    inputCampDeviceVendors.setValue(t.vendor.list);
                    inputCampDeviceVendorsIsExcluded.setValue(t.vendor.isExcluded);
                }
                if (t.model) {
                    inputCampDeviceModels.setValue(t.model.list);
                    inputCampDeviceModelsIsExcluded.setValue(t.model.isExcluded);
                }
                if (t.mobileIsp) {
                    inputCampMobileISP.setValue(t.mobileIsp.list);
                    inputCampMobileISPIsExcluded.setValue(t.mobileIsp.isExcluded);
                }
                if (t.browser) {
                    inputCampBrowsers.setValue(t.browser.list);
                    inputCampBrowsersIsExcluded.setValue(t.browser.isExcluded);
                }
                if (t.language) {
                    inputCampBrowserLanguage.setValue(t.language.list);
                    inputCampBrowserLanguageIsExcluded.setValue(t.language.isExcluded);
                }
                if (t.browserVersion) {
                    inputCampBrowserVersions.setValue(t.browserVersion.list);
                    inputCampBrowserVersionsIsExcluded.setValue(t.browserVersion.isExcluded);
                }
                inputCampProxy.setValue('proxy' in t ? t.proxy : 'all');
                if (t.zone) {
                    inputCampZones.setValue(t.zone.list.sort().join(', '));
                    inputCampZonesIsExcluded.setValue(t.zone.isExcluded);
                }
            }
        }

        inputCampPrice.setValue(result.data && result.data.price ? result.data.price : '');
        inputUpdateInterval.setValue(result.data && result.data.update_time ? result.data.update_time : '');
        // setSourceZones(result.data && result.data.placement_targeting && result.data.placement_targeting.list ? result.data.placement_targeting.list.sort().join(', ') : '');
        // setSourceZonesIsExcluded(result.data && result.data.placement_targeting ? result.data.placement_targeting.isExcluded : true);

        if (!campOriginId) {
            setCampOriginId(result.origin_campaign_id);
        }
    }

    useEffect(() => {
        if (Object.keys(profile).length > 0) {
            const country = profile.data ? profile.data.country : '';
            console.log('country', country)

            const promises = [
                axios.get(UrlCreator.clickadu_targeting(), ajax.getBaseConfig()),
            ];
            Promise.all(promises).then(function (results) {
                consoleLogResponse(results);
                const d = results[0].data;

                const filterSelectable = a => {
                    return a.filter(v => v['isSelectable'] === 1);
                }

                inputCampFeed.setOptions(SelectDataFormatter.id_title(
                    ['main', 'adult', 'all'].map(i => {
                        return {id: i, title: i.charAt(0).toUpperCase() + i.slice(1)}
                    })
                ));
                inputCampTrafficVertical.setOptions(SelectDataFormatter.id_title(d['campaignTrafficVertical']));
                inputCampConnection.setOptions(SelectDataFormatter.id_title(d['connection']));
                inputCampProxy.setOptions(SelectDataFormatter.id_title([{id: 'all', title: 'All'}].concat(d['proxy'])));
                inputUpdateInterval.setOptions(getCampaignUpdateIntervalList());

                inputCampOsTypes.setOptions(SelectDataFormatter.id_title(
                    filterSelectable(d['osType'])
                ));
                inputCampOs.setOptions(SelectDataFormatter.id_title(
                    filterSelectable(d['os'])
                ));
                inputCampOsVersions.setOptions(SelectDataFormatter.id_id_title(
                    filterSelectable(d['osVersion'])
                        // .filter(v => v['parent'] == 'android')
                        .sort((a, b) => (a.title > b.title) ? 1 : -1)
                ));
                inputCampDeviceTypes.setOptions(SelectDataFormatter.id_title(
                    filterSelectable(d['deviceType'])
                ));
                inputCampDeviceVendors.setOptions(SelectDataFormatter.id_title(
                    filterSelectable(d['vendor'])
                ));
                inputCampDeviceModels.setOptions(SelectDataFormatter.id_title(
                    filterSelectable(d['model'])
                ));
                inputCampMobileISP.setOptions(SelectDataFormatter.id_title(
                    filterSelectable(d['mobileIsp'])
                        .filter(v => v['parent'] === country)
                        .sort((a, b) => (a.title > b.title) ? 1 : -1)
                ));
                inputCampBrowsers.setOptions(SelectDataFormatter.id_title(
                    filterSelectable(d['browser'])
                        .sort((a, b) => (a.title > b.title) ? 1 : -1)
                ));
                inputCampBrowserLanguage.setOptions(SelectDataFormatter.id_title(
                    filterSelectable(d['language'])
                        .sort((a, b) => (a.title > b.title) ? 1 : -1)
                ));
                inputCampBrowserVersions.setOptions(SelectDataFormatter.id_title(
                    filterSelectable(d['browserVersion'])
                        // .filter(v => v['title'].startsWith("chrome", 0))
                        .sort((a, b) => (a.title > b.title) ? 1 : -1)
                ));
            });

            setFromResponse(profile);

            let placements = {};
            if (profile.data && profile.data.placement_rates) {
                for (const [key, value] of Object.entries(profile.data.placement_rates)) {
                    placements[key + ""] = {
                        id: key + "",
                        price: value,
                    }
                }
            }

            consoleLog(placements)

            setPlacements(Object.values(placements));
        }
    }, [profile]);

    useEffect(() => {
        if (updateProfile) {
            setFromResponse(updateProfile);
        }
    }, [updateProfile]);

    // useEffect(() => {
    //     let errors = {
    //         data_update_time: '',
    //         data_price: '',
    //     };
    //     dataErrors.forEach(v => {
    //         if (v.field === 'data_price') {
    //             errors.data_price = v.message
    //         } else if (v.field === 'data_update_time') {
    //             errors.data_update_time = v.message
    //         }
    //     })
    //
    //     setErrorPrice(errors.data_price);
    //     setErrorUpdateInterval(errors.data_update_time);
    // }, [dataErrors]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            camp_name: inputCampName.value,
            camp_url: inputCampUrl.value,
            camp_feed: inputCampFeed.value,
            camp_traffic_vertical: inputCampTrafficVertical.value,
            camp_budget_daily_amount: inputCampBudgetDailyAmount.value,
            camp_budget_total_amount: inputCampBudgetTotalAmount.value,
            camp_user_frequency: inputCampUserFrequency.value,
            camp_user_capping: Math.floor(inputCampUserCapping.value) * 3600,

            camp_connection: inputCampConnection.value,
            camp_os_types: inputCampOsTypes.value,
            camp_os_types_is_excluded: inputCampOsTypesIsExcluded.value,
            camp_os: inputCampOs.value,
            camp_os_is_excluded: inputCampOsIsExcluded.value,
            camp_os_versions: inputCampOsVersions.value,
            camp_os_versions_is_excluded: inputCampOsVersionsIsExcluded.value,
            camp_device_types: inputCampDeviceTypes.value,
            camp_device_types_is_excluded: inputCampDeviceTypesIsExcluded.value,
            camp_device_vendors: inputCampDeviceVendors.value,
            camp_device_vendors_is_excluded: inputCampDeviceVendorsIsExcluded.value,
            camp_device_models: inputCampDeviceModels.value,
            camp_device_models_is_excluded: inputCampDeviceModelsIsExcluded.value,
            camp_mobile_isp: inputCampMobileISP.value,
            camp_mobile_isp_is_excluded: inputCampMobileISPIsExcluded.value,
            camp_browsers: inputCampBrowsers.value,
            camp_browsers_is_excluded: inputCampBrowsersIsExcluded.value,
            camp_browser_language: inputCampBrowserLanguage.value,
            camp_browser_language_is_excluded: inputCampBrowserLanguageIsExcluded.value,
            camp_browser_versions: inputCampBrowserVersions.value,
            camp_browser_versions_is_excluded: inputCampBrowserVersionsIsExcluded.value,
            camp_proxy: inputCampProxy.value,

            data_update_time: inputUpdateInterval.value,
            data_price: inputCampPrice.value,
            source_zones: ArrayUnique(strToArray(inputCampZones.value)),
            source_zones_exclude: inputCampZonesIsExcluded.value,
        };

        console.log('data', data);
        setRequestData(data);
    }

    return (
        <Container title={"Update Clickadu Campaign #" + campOriginId}>
            <Card>
                <LoadingWrapper isLoaded={!isLoading}>
                    <form onSubmit={handleSubmit}>
                        {
                            updateProfile && <AlertSuccess message="Data updated successfully"/>
                        }
                        {
                            dataErrors.length > 0 && <><AlertDanger message={JSON.stringify(dataErrors, null, 2)}/><Dump value={dataErrors}/></>
                        }
                        {
                            updateError && <><AlertDanger message={updateError.message ? updateError.message : updateError}/><Dump value={updateError}/></>
                        }

                        <DivFormGroupRow label="GEO">
                            <h2>{profile.data ? profile.data.country : ''}</h2>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Price" labelAttributes={{htmlFor: "inputCampPrice"}}>
                            <input
                                type="text"
                                id="inputCampPrice"
                                placeholder="Price for campaign"
                                className={"form-control " + (inputCampPrice.error.length > 0 ? "is-invalid" : '')}
                                {...inputCampPrice.bind}
                            />
                            <DivError error={inputCampPrice.error}/>
                        </DivFormGroupRow>

                        <hr/>

                        <DivFormGroupRow label="Name" labelAttributes={{htmlFor: "inputCampName"}}>
                            <input
                                type="text"
                                id="inputCampName"
                                className={"form-control " + (inputCampName.error.length > 0 ? "is-invalid" : '')}
                                {...inputCampName.bind}
                            />
                            <DivError error={inputCampName.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Url" labelAttributes={{htmlFor: "inputCampUrl"}}>
                            <input
                                type="text"
                                id="inputCampUrl"
                                className={"form-control " + (inputCampUrl.error.length > 0 ? "is-invalid" : '')}
                                {...inputCampUrl.bind}
                            />
                            <DivError error={inputCampUrl.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Feed New">
                            <SelectSingle {...inputCampFeed.bind}/>
                            <DivError error={inputCampFeed.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Vertical">
                            <SelectSingle {...inputCampTrafficVertical.bind}/>
                            <DivError error={inputCampTrafficVertical.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Budget daily (USD)" labelAttributes={{htmlFor: "inputCampBudgetDailyAmount"}}>
                            <input
                                type="text"
                                id="inputCampBudgetDailyAmount"
                                className={"form-control " + (inputCampBudgetDailyAmount.error.length > 0 ? "is-invalid" : '')}
                                {...inputCampBudgetDailyAmount.bind}
                            />
                            <DivError error={inputCampBudgetDailyAmount.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Budget total (USD)" labelAttributes={{htmlFor: "inputCampBudgetTotalAmount"}}>
                            <input
                                type="text"
                                id="inputCampBudgetTotalAmount"
                                className={"form-control " + (inputCampBudgetTotalAmount.error.length > 0 ? "is-invalid" : '')}
                                {...inputCampBudgetTotalAmount.bind}
                            />
                            <DivError error={inputCampBudgetTotalAmount.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="User Frequency" labelAttributes={{htmlFor: "inputCampUserFrequency"}}>
                            <input
                                type="text"
                                id="inputCampUserFrequency"
                                className={"form-control " + (inputCampUserFrequency.error.length > 0 ? "is-invalid" : '')}
                                {...inputCampUserFrequency.bind}
                            />
                            <DivError error={inputCampUserFrequency.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="User Capping (h)" labelAttributes={{htmlFor: "inputCampUserCapping"}}>
                            <input
                                type="text"
                                id="inputCampUserCapping"
                                className={"form-control " + (inputCampUserCapping.error.length > 0 ? "is-invalid" : '')}
                                {...inputCampUserCapping.bind}
                            />
                            <DivError error={inputCampUserCapping.error}/>
                        </DivFormGroupRow>

                        <hr/>

                        <DivFormGroupRow label="Connection">
                            <SelectSingle {...inputCampConnection.bind}/>
                            <DivError error={inputCampConnection.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Os Types">
                            <SelectMulti {...inputCampOsTypes.bind}/>
                            <DivError error={inputCampOsTypes.error}/>

                            <DivFormGroupRowCheckBox
                                bind={inputCampOsTypesIsExcluded.bind}
                                error={inputCampOsTypesIsExcluded.error}
                            />
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Os">
                            <SelectMulti {...inputCampOs.bind}/>
                            <DivError error={inputCampOs.error}/>

                            <DivFormGroupRowCheckBox
                                bind={inputCampOsIsExcluded.bind}
                                error={inputCampOsIsExcluded.error}
                            />
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Os Versions">
                            <SelectMulti {...inputCampOsVersions.bind}/>
                            <div>
                                <b>Presets: </b>
                                <a onClick={() => {
                                    inputCampOs.setValue(ArrayUnique(inputCampOs.value.concat(['android'])));

                                    let startIndex = 1;
                                    let list = [];
                                    const versions = inputCampOsVersions.options
                                        .filter(v => v['value'].startsWith("android", 0))
                                        .map(e => e.value);

                                    while (true) {
                                        if (versions.indexOf('android' + startIndex) !== -1) {
                                            list.push('android' + startIndex)
                                        } else {
                                            break;
                                        }
                                        startIndex++;
                                        if (startIndex > 1000) {
                                            break;
                                        }
                                    }

                                    inputCampOsVersions.setValue(list.slice(-7))

                                    // console.log('listBrowserVersions', listBrowserVersions)
                                    // console.log('versions', versions)
                                    // console.log('list', list)

                                    return false;
                                }}>Android latest 7</a>
                            </div>
                            <DivError error={inputCampOsVersions.error}/>

                            <DivFormGroupRowCheckBox
                                bind={inputCampOsVersionsIsExcluded.bind}
                                error={inputCampOsVersionsIsExcluded.error}
                            />
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Device Types">
                            <SelectMulti {...inputCampDeviceTypes.bind}/>
                            <DivError error={inputCampDeviceTypes.error}/>

                            <DivFormGroupRowCheckBox
                                bind={inputCampDeviceTypesIsExcluded.bind}
                                error={inputCampDeviceTypesIsExcluded.error}
                            />
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Device Vendors">
                            <SelectMulti {...inputCampDeviceVendors.bind}/>
                            <DivError error={inputCampDeviceVendors.error}/>

                            <DivFormGroupRowCheckBox
                                bind={inputCampDeviceVendorsIsExcluded.bind}
                                error={inputCampDeviceVendorsIsExcluded.error}
                            />
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Device Models">
                            <SelectMulti {...inputCampDeviceModels.bind}/>
                            <DivError error={inputCampDeviceModels.error}/>

                            <DivFormGroupRowCheckBox
                                bind={inputCampDeviceModelsIsExcluded.bind}
                                error={inputCampDeviceModelsIsExcluded.error}
                            />
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Mobile ISP">
                            <SelectMulti {...inputCampMobileISP.bind}/>
                            <DivError error={inputCampMobileISP.error}/>

                            <DivFormGroupRowCheckBox
                                bind={inputCampMobileISPIsExcluded.bind}
                                error={inputCampMobileISPIsExcluded.error}
                            />
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Browsers">
                            <SelectMulti {...inputCampBrowsers.bind}/>
                            <DivError error={inputCampBrowsers.error}/>

                            <DivFormGroupRowCheckBox
                                bind={inputCampBrowsersIsExcluded.bind}
                                error={inputCampBrowsersIsExcluded.error}
                            />
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Browser Language">
                            <SelectMulti {...inputCampBrowserLanguage.bind}/>
                            <DivError error={inputCampBrowserLanguage.error}/>

                            <DivFormGroupRowCheckBox
                                bind={inputCampBrowserLanguageIsExcluded.bind}
                                error={inputCampBrowserLanguageIsExcluded.error}
                            />
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Browser Versions">
                            <SelectMulti {...inputCampBrowserVersions.bind}/>
                            <div>
                                <b>Presets: </b>
                                <a onClick={() => {
                                    inputCampBrowsers.setValue(ArrayUnique(inputCampBrowsers.value.concat(['chrome'])));
                                    inputCampBrowserVersionsIsExcluded.setValue(false);

                                    // @TODO startIndex = 1 stop if not found 10 times
                                    let startIndex = 25;
                                    let list = [];
                                    const versions = inputCampBrowserVersions.options
                                        .filter(v => v['value'].startsWith("chrome", 0))
                                        .map(e => e.value);

                                    while (true) {
                                        if (versions.indexOf('chrome' + startIndex) !== -1) {
                                            list.push('chrome' + startIndex)
                                        } else {
                                            break;
                                        }
                                        startIndex++;
                                        if (startIndex > 1000) {
                                            break;
                                        }
                                    }
                                    inputCampBrowserVersions.setValue(list.slice(-20))

                                    return false;
                                }}>Chrome latest 20</a>
                                &nbsp;/&nbsp;
                                <a onClick={() => {
                                    inputCampBrowsers.setValue(ArrayUnique(inputCampBrowsers.value.concat(['chrome'])));
                                    inputCampBrowserVersionsIsExcluded.setValue(true);

                                    let startIndex = 0;
                                    let list = [];
                                    const versions = inputCampBrowserVersions.options
                                        .filter(v => v['value'].startsWith("chrome", 0))
                                        .map(e => e.value);

                                    while (true) {
                                        if (versions.indexOf('chrome' + startIndex) !== -1) {
                                            list.push('chrome' + startIndex)
                                        }
                                        startIndex++;
                                        if (startIndex > 105) {
                                            break;
                                        }
                                    }
                                    inputCampBrowserVersions.setValue(list);

                                    return false;
                                }}>Exclude Chrome before v106</a>
                            </div>
                            <DivError error={inputCampBrowserVersions.error}/>

                            <DivFormGroupRowCheckBox
                                bind={inputCampBrowserVersionsIsExcluded.bind}
                                error={inputCampBrowserVersionsIsExcluded.error}
                            />
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Proxy">
                            <SelectSingle {...inputCampProxy.bind}/>
                            <DivError error={inputCampProxy.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Source Zones" labelAttributes={{htmlFor: "inputSourceZones"}}>
                             <textarea
                                 id="inputSourceZones"
                                 placeholder="Source Zones"
                                 className={"form-control " + (inputCampZones.error.length > 0 ? "is-invalid" : '')}
                                 {...inputCampZones.bind}
                             ></textarea>
                            <DivError error={inputCampZones.error}/>

                            <DivFormGroupRowCheckBox
                                bind={inputCampZonesIsExcluded.bind}
                                error={inputCampZonesIsExcluded.error}
                            />
                        </DivFormGroupRow>

                        <hr/>

                        <DivFormGroupRow label="Update interval">
                            <SelectSingle {...inputUpdateInterval.bind}/>
                            <DivError error={inputUpdateInterval.error}/>
                        </DivFormGroupRow>

                        <div className="form-group">
                            <ButtonSubmit isLoading={updateIsLoading} text="Save changes"/>
                        </div>

                        <hr/>

                        <Dump value={placements}/>

                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th>Placement</th>
                                <th>Price</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {placements.map(item => (
                                //  className={item.price == price ? 'table-warning': ''}
                                <tr>
                                    <td>{item.id}</td>
                                    <td>{item.price}</td>
                                    <td className="table-action">
                                        <Link
                                            to={"/ex-stat/campaign/" + id + "/placement/" + item.id + "/edit"}><Icon.Settings/></Link>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                        <Dump value={profile}/>
                    </form>

                </LoadingWrapper>
            </Card>

            {isDebug() && <Card><Dump value={true}/></Card>}
        </Container>
    )
}

ExStatCampaignClickaduEditForm.propTypes = {}
