import React from 'react';
import PropTypes from 'prop-types';

import Alert from "./Alert";

export default function AlertSuccess({messagePrefix, message}) {
    return (
        <Alert className='alert-success' message={message} messagePrefix={messagePrefix}/>
    )
}

AlertSuccess.propTypes = {
    message: PropTypes.string.isRequired,
    messagePrefix: PropTypes.string,
}

AlertSuccess.defaultProps = {
    messagePrefix: "",
}
