import React, {useEffect, useState} from 'react'
import Select from "react-select";

import {selectStyles} from "./styles";
import {consoleLogError} from "../../../components/development";

export default function SelectSingle({value, updateValue, options = []}) {
    const [selectedOption, setSelectedOption] = useState([]);

    const handleTypeSelect = e => {
        if (e === null) {
            setSelectedOption([]);
            updateValue(null)
        } else {
            setSelectedOption(e.value);
            updateValue(e.value);
        }
    };

    useEffect(() => {
        // const allowedValues = options.filter(o => value === o.value);
        const allowedValues = options.filter(o => value == o.value); // tmp

        if (allowedValues.length > 0) {
            setSelectedOption(allowedValues.shift());
        } else if (value && options.length > 0) {
            consoleLogError('SelectSingle unknown value `' + value + '`; allowed: '+ options.map(e => e.value).join(', '))
        }
    }, [value, options]);

    return (
        options.length > 0 && <Select
            styles={selectStyles}
            options={options}
            isClearable={true}
            value={selectedOption}
            onChange={handleTypeSelect}
            placeholder={<div>Select ...</div>} // or placeholder={"Select ..."}
        />
    )
}
