import React from 'react';

import ajax from "../../data/ajax"
import Loading from "../components/Loading";

class Offer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
            item: null,
        }
    }

    componentDidMount() {
        const url = window.trackerBaseUrl + "offers/" + this.props.match.params.offerId + "?expand=groupName"; //fields=id,name

        fetch(url, {
            method: 'GET',
            headers: ajax.getHeaders(),
        })
            .then(res => res.json())
            .then(
                result => {
                    this.setState({
                        isLoaded: true,
                        item: result
                    })
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                error => {
                    this.setState({
                        isLoaded: true,
                        error
                    })
                }
            )
    }

    render() {
        const {error, isLoaded, item} = this.state
        if (error) {
            return <div>Ошибка: {error.message}</div>
        } else if (!isLoaded) {
            return <Loading />
        } else {
            return (
                <div className="container-fluid p-0">
                    <h1 className="h3 mb-3">#{item.id} - {item.name}</h1>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="card">
                                <img className="card-img-top" src="https://dummyimage.com/1200x800/fff/aaa" alt="avatar" />

                                <div className="card-body">
                                    <p className="card-text"><strong>Description:</strong> {item.description}</p>
                                    <p className="card-text"><strong>Our recommendations:</strong> {item.recommendation}</p>
                                    <a href="/index.html" className="btn btn-secondary">Preview ??</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-body">
                                    {/*<table id="datatables-offer-geo" className="table table-striped" style="width:100%">*/}
                                    <table id="datatables-offer-geo" className="table table-striped">
                                        <thead>
                                        <tr>
                                            <th>GEO</th>
                                            <th>Country Name</th>
                                            <th>Rate, $</th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>WW</td>
                                            <td>World wide</td>
                                            <td>{"$" + item.payout_float}</td>

                                        </tr>
{/*
                                        <tr>
                                            <td>BR</td>
                                            <td>Brazil</td>
                                            <td>$1.800</td>
                                        </tr>
*/}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <p className="card-text"><strong>Vertical:</strong> {item.groupName}</p>
                                    <p className="card-text"><strong>Flow:</strong> 1-Click ???</p>
                                    <p className="card-text text-danger"><strong>Restrictions:</strong> {item.restriction}</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <h1 className="h3 mb-3">Offer Link Settings</h1>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">

                                <div className="card-body">

                                    <table id="datatables-offer-links" className="table table-striped">
                                           {/*style="width:100%">*/}
                                        <thead>
                                        <tr>
                                            <th>Traffic Source Name</th>
                                            <th>Link URL</th>


                                        </tr>
                                        </thead>
                                        <tbody>
{/*                                        <tr>
                                            <td>Google1</td>
                                            <td>
                                                <code>https://trackerdomain.com/id?z=1304351&clickid=[CLICK_ID]&sub1=[sub1]&sub2=[sub2]&sub3=[sub3]</code>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>Facebook1</td>
                                            <td>
                                                <code>https://trackerdomain.com/id?z=1304353&clickid=[CLICK_ID]&sub1=[sub1]&sub2=[sub2]&sub3=[sub3]</code>
                                            </td>

                                        </tr>*/}


                                        </tbody>
                                    </table>


                                    <button type="button" className="btn btn-primary" data-toggle="modal"
                                            data-target="#defaultModalPrimary">Generate Link
                                    </button>
                                    <div className="modal fade" id="defaultModalPrimary" tabIndex="-1" role="dialog"
                                         aria-hidden="true">

                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Add traffic source</h5>
                                                    <button type="button" className="close" data-dismiss="modal"
                                                            aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body m-3">

                                                    <div className="form-group">
                                                        <label htmlFor="inputTS">Select Your Traffic Source</label>
                                                        <select id="inputTS" className="form-control">
                                                            <option selected>Choose...</option>
                                                            <option>...</option>
                                                        </select>

                                                    </div>
                                                    <p className="mb-0">If you need add a new traffic source, please <a
                                                        href="a_traffic_add.html">go to this page</a></p>

                                                </div>


                                                <div className="modal-footer">

                                                    <button type="button" className="btn btn-primary">Generate Link
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default Offer;
