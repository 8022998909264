const get = (table, column) => {
    let data = localStorage.getItem(table);
    if (data === null) {
        return true;
    }

    let parsed = JSON.parse(data);
    if (column in parsed) {
        return parsed[column];
    }

    return true;
}

const set = (table, column, value) => {
    let data = localStorage.getItem(table);
    let parsed = {};
    if (data !== null) {
        parsed = JSON.parse(data);
    }

    if (value === true) {
        delete parsed[column];
    } else {
        parsed[column] = value;
    }

    localStorage.setItem(table, JSON.stringify(parsed));
}

const TableColumnsStorage = {
    get,
    set
}

export default TableColumnsStorage;
