import React from "react";
import {Link} from "react-router-dom";
import * as Icon from "react-feather";

import {round} from "../../../../components/Math";

export default function ExStatDashboardTableParams() {
    const keyDate = 'date';
    const keyHour = 'hour';
    const keyManagerID = 'manager';
    const keyManagerName = 'manager_email';
    const keyAccountID = 'account';
    const keyAccountName = 'account_name';
    const keyZoneID = 'origin_zone_id';
    const keyZoneName = 'zone_name';
    const keyCampaignID = 'origin_campaign_id';
    const keyCampaignName = 'campaign_name';
    const keyRevenueTb = 'revenue_tb';
    const keyProfitTb = 'profit_tb';
    const keyTotalROI = 'total_roi';
    const keyTbOfCost = 'tb_of_cost';
    const keyROI = 'roi';
    const keyCountry = 'country';
    const keyPlacement = 'origin_placement_id';
    const keyCampaignPrice = 'campaign_price';
    const keyPlacementPrice = 'placement_price';
    const keyButtonCampaign = 'campaign_edit';
    const keyButtonPlacement = 'placement_edit';

    const columns = React.useMemo(
        () => [
            {
                Header: 'Date',
                accessor: keyDate,
                Footer: <b>Total:</b>,
            },
            {
                Header: 'Hour',
                accessor: keyHour,
            },
            {
                Header: 'Country',
                accessor: keyCountry,
                Footer: <b>Total:</b>,
            },
            {
                Header: 'Manager ID',
                accessor: keyManagerID,
                Footer: <b>Total:</b>,
            },
            {
                Header: 'Manager',
                accessor: keyManagerName,
            },
            {
                Header: 'Account ID',
                accessor: keyAccountID,
                Footer: <b>Total:</b>,
            },
            {
                Header: 'Account',
                accessor: keyAccountName,
            },
            {
                Header: 'Zone ID',
                accessor: keyZoneID,
                Footer: <b>Total:</b>,
            },
            {
                Header: 'Zone',
                accessor: keyZoneName,
            },
            {
                Header: 'Campaign ID',
                accessor: keyCampaignID,
                Footer: <b>Total:</b>,
            },
            {
                Header: 'Campaign',
                Cell: ({row}) => <div><span className={"fas fa-circle clickadu-status-"+row.original.clickadu_status} title={row.original.clickadu_status}></span> {row.original[keyCampaignName]}</div>,
                accessor: keyCampaignName,
            },
            {
                Header: 'Price',
                accessor: keyCampaignPrice,
            },
            {
                Header: 'Placement',
                accessor: keyPlacement,
                Footer: <b>Total:</b>,
            },
            {
                Header: 'Pl. price',
                accessor: keyPlacementPrice,
            },
            {
                Header: 'CPM',
                accessor: 'cpm',
                sortType: 'basic',
                Footer: info => {
                    const totalImpressions = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.impressions) + sum, 0),
                        [info.rows]
                    )

                    const totalCost = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.cost) + sum, 0),
                        [info.rows]
                    )

                    if (totalImpressions !== 0) {
                        return <>{round(1000 * totalCost / totalImpressions, 4)}</>
                    } else {
                        return <></>
                    }
                },
            },
            {
                Header: 'eCPM',
                accessor: 'ecpm_total',
                sortType: 'basic',
                Footer: info => {
                    const totalImpressions = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.impressions) + sum, 0),
                        [info.rows]
                    )

                    const totalRevenue = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.revenue) + Number(row.values.revenue_tb) + sum, 0),
                        [info.rows]
                    )

                    if (totalImpressions !== 0) {
                        return <>{round(1000 * totalRevenue / totalImpressions, 4)}</>
                    } else {
                        return <></>
                    }
                },
            },
            {
                Header: 'Impressions',
                accessor: 'impressions',
                Footer: info => {
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.impressions) + sum, 0),
                        [info.rows]
                    )

                    return <>{round(total, 4)}</>
                },
            },
            {
                Header: 'Conversions',
                accessor: 'conversions',
                Footer: info => {
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.conversions) + sum, 0),
                        [info.rows]
                    )

                    return <>{round(total, 4)}</>
                },
            },
            {
                Header: 'Cost, $',
                accessor: 'cost',
                Footer: info => {
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.cost) + sum, 0),
                        [info.rows]
                    )

                    return <>{round(total, 4)}</>
                },
            },
            {
                Header: 'Revenue, $',
                accessor: 'revenue',
                Footer: info => {
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.revenue) + sum, 0),
                        [info.rows]
                    )

                    return <>{round(total, 4)}</>
                },
            },
            {
                Header: 'Profit, $',
                accessor: 'profit',
                sortType: 'basic',
                Footer: info => {
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.revenue) - Number(row.values.cost) + sum, 0),
                        [info.rows]
                    )

                    return <>{round(total, 4)}</>
                },
            },
            {
                Header: 'ROI, %',
                accessor: keyROI,
                sortType: 'basic',
                Footer: info => {
                    const totalRevenue = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.revenue) + sum, 0),
                        [info.rows]
                    )

                    const totalCost = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.cost) + sum, 0),
                        [info.rows]
                    )

                    if (totalCost !== 0) {
                        return <>{round(100 * (totalRevenue - totalCost) / totalCost, 2)}</>
                    } else {
                        return <></>
                    }
                },
            },
            {
                Header: 'Revenue TB, $',
                accessor: keyRevenueTb,
                Footer: info => {
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.revenue_tb) + sum, 0),
                        [info.rows]
                    )

                    return <>{round(total, 4)}</>
                },
            },
            {
                Header: 'Profit + TB, $',
                accessor: keyProfitTb,
                sortType: 'basic',
                Footer: info => {
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.revenue) + Number(row.values.revenue_tb) - Number(row.values.cost) + sum, 0),
                        [info.rows]
                    )

                    return <>{round(total, 4)}</>
                },
            },
            {
                Header: 'TB / cost, %',
                accessor: keyTbOfCost,
                sortType: 'basic',
                Footer: info => {
                    const totalRevenueTb = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.revenue_tb) + sum, 0),
                        [info.rows]
                    )

                    const totalCost = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.cost) + sum, 0),
                        [info.rows]
                    )

                    if (totalCost !== 0) {
                        return <>{round(100 * totalRevenueTb / totalCost, 2)}</>
                    } else {
                        return <></>
                    }
                },
            },
            {
                Header: 'Total ROI, %',
                accessor: keyTotalROI,
                sortType: 'basic',
                Footer: info => {
                    const totalRevenue = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.revenue) + Number(row.values.revenue_tb) + sum, 0),
                        [info.rows]
                    )

                    const totalCost = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => Number(row.values.cost) + sum, 0),
                        [info.rows]
                    )

                    if (totalCost !== 0) {
                        return <>{round(100 * (totalRevenue - totalCost) / totalCost, 2)}</>
                    } else {
                        return <></>
                    }
                },
            },
            {
                accessor: keyButtonCampaign,
                Cell: ({row}) => <Link to={"/ex-stat/campaign/" + row.original.campaign + "/edit"}><Icon.Settings /></Link>,
                width: 100,
            },
            {
                accessor: keyButtonPlacement,
                Cell: ({row}) => <Link to={"/ex-stat/campaign/" + row.original.campaign + "/placement/" + row.original[keyPlacement] + "/edit"}><Icon.Settings /></Link>,
                width: 100,
            },
        ],
        []
    );

    const initialState = {
        sortBy: [
            {
                id: keyDate,
                desc: true
            }
        ]
    }

    return [{
        keyDate,
        keyHour,
        keyManagerID,
        keyManagerName,
        keyAccountID,
        keyAccountName,
        keyZoneID,
        keyZoneName,
        keyCampaignID,
        keyCampaignName,
        keyCountry,
        keyPlacement,
        keyPlacementPrice,
        keyRevenueTb,
        keyProfitTb,
        keyTotalROI,
        keyTbOfCost,
        keyButtonCampaign,
        keyButtonPlacement,
        columns,
        initialState,
    }];
}
