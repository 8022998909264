import React from 'react';

/**
 * @deprecated
 * @returns {JSX.Element}
 * @constructor
 */
function Help() {
    return (
        <div className="container-fluid p-0">
            <h1 className="h3 mb-3">Help</h1>

            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. At ultrices mi tempus imperdiet nulla malesuada. Lobortis mattis aliquam faucibus
                purus in massa tempor nec. Duis convallis convallis tellus id interdum. Lorem dolor sed viverra ipsum
                nunc aliquet bibendum enim. In cursus turpis massa tincidunt dui. Elit ullamcorper dignissim cras
                tincidunt. Et egestas quis ipsum suspendisse ultrices gravida. Mattis enim ut tellus elementum sagittis
                vitae et leo. Venenatis tellus in metus vulputate eu scelerisque. Purus semper eget duis at tellus at
                urna condimentum mattis. Suscipit adipiscing bibendum est ultricies integer quis auctor. Velit dignissim
                sodales ut eu sem integer vitae justo eget. Sed euismod nisi porta lorem. Aliquam sem fringilla ut morbi
                tincidunt augue. Felis bibendum ut tristique et egestas. Neque ornare aenean euismod elementum nisi. Hac
                habitasse platea dictumst quisque sagittis.
            </p>
            <p>
                Euismod lacinia at quis risus sed vulputate. Sit amet consectetur adipiscing elit ut aliquam purus.
                Aliquam sem et tortor consequat id porta. Egestas maecenas pharetra convallis posuere morbi leo urna
                molestie at. Velit ut tortor pretium viverra. Leo vel orci porta non. Dictum non consectetur a erat nam.
                Enim sit amet venenatis urna cursus eget nunc scelerisque viverra. Egestas dui id ornare arcu odio ut
                sem nulla pharetra. Odio morbi quis commodo odio aenean. Vitae ultricies leo integer malesuada. Dui
                faucibus in ornare quam viverra orci sagittis eu volutpat. Phasellus faucibus scelerisque eleifend donec
                pretium vulputate sapien nec sagittis. Aliquet bibendum enim facilisis gravida neque convallis a cras.
                Est ante in nibh mauris cursus. Malesuada nunc vel risus commodo viverra maecenas accumsan. In cursus
                turpis massa tincidunt dui ut ornare. Lacus sed turpis tincidunt id. Urna porttitor rhoncus dolor purus
                non enim. Libero enim sed faucibus turpis in eu mi bibendum.
            </p>
            <p>
                Mauris pharetra et ultrices neque. Enim tortor at auctor urna nunc id cursus metus. Mauris nunc congue
                nisi vitae suscipit tellus mauris. Hac habitasse platea dictumst quisque sagittis purus sit amet
                volutpat. Imperdiet massa tincidunt nunc pulvinar sapien et ligula ullamcorper. Risus in hendrerit
                gravida rutrum quisque non. Leo duis ut diam quam nulla porttitor massa id. Leo urna molestie at
                elementum eu. Tempor nec feugiat nisl pretium. Ac turpis egestas sed tempus urna et pharetra pharetra.
                Mi sit amet mauris commodo quis imperdiet massa tincidunt.
            </p>
            <p>
                Sapien et ligula ullamcorper malesuada. Eros in cursus turpis massa tincidunt dui ut. Senectus et netus
                et malesuada fames. Suspendisse faucibus interdum posuere lorem ipsum dolor sit. Ornare lectus sit amet
                est placerat in egestas. Posuere urna nec tincidunt praesent semper feugiat. Commodo viverra maecenas
                accumsan lacus. Elit sed vulputate mi sit amet mauris. Vitae auctor eu augue ut lectus arcu. Mi eget
                mauris pharetra et ultrices neque ornare.
            </p>
            <p>
                Lectus nulla at volutpat diam ut. Eget mi proin sed libero enim sed faucibus turpis. Id eu nisl nunc mi
                ipsum faucibus vitae. Tempus iaculis urna id volutpat lacus. Tincidunt praesent semper feugiat nibh sed
                pulvinar proin gravida hendrerit. Lacus sed viverra tellus in hac habitasse platea dictumst. Elit
                pellentesque habitant morbi tristique. Bibendum arcu vitae elementum curabitur. Pellentesque habitant
                morbi tristique senectus et netus. Aliquam malesuada bibendum arcu vitae elementum curabitur vitae nunc
                sed. Id donec ultrices tincidunt arcu. Et sollicitudin ac orci phasellus egestas tellus. Eget egestas
                purus viverra accumsan in nisl nisi. Proin fermentum leo vel orci porta. Sed euismod nisi porta lorem.
                Purus semper eget duis at tellus at urna.
            </p>
        </div>
    );
}

export default Help;
