import React, {useState} from 'react';

import Container from "../components/Container";
import TabPayments from "./Settings/TabPayments";
import TabPostback from "./Settings/TabPostback";
import TabAccount from "./Settings/TabAccount";
import TabPassword from "./Settings/TabPassword";

export default function Settings() {
    const [activeTabID, setActiveTabID] = useState('account');

    const menuItems = process.env.REACT_APP_TYPE === "lss"
        ? [
            {key: 'account', title: 'Profile Details'},
            // {key: 'password', title: 'Password'},
            // {key: 'payments', title: 'Payments Settings'},
            {key: 'postback', title: 'Postback'},
        ]
        : [
            {key: 'account', title: 'Profile Details'},
            {key: 'password', title: 'Password'},
            {key: 'payments', title: 'Payments Settings'},
            {key: 'postback', title: 'Postback'},
        ];

    return (
        <Container title={"Settings"}>
            <div className="row">
                <div className="col-md-3 col-xl-2">
                    <div className="card">
                        <div className="list-group list-group-flush" role="tablist">
                            {menuItems.map((item) => {
                                return (
                                    <a className={"list-group-item list-group-item-action" + (item.key === activeTabID ? " active" : "")}
                                       href={"#" + item.key}
                                       key={item.key}
                                       onClick={(e) => {
                                           e.preventDefault();
                                           setActiveTabID(item.key)
                                       }}
                                    >
                                        {item.title}
                                    </a>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className="col-md-9 col-xl-10">
                    <div className="tab-content">
                        <TabAccount tabActiveID={activeTabID}/>
                        {
                            process.env.REACT_APP_TYPE === "lss" &&
                            <React.Fragment>
                                <TabPassword tabActiveID={activeTabID}/>
                                <TabPayments tabActiveID={activeTabID}/>
                            </React.Fragment>
                        }
                        <TabPostback tabActiveID={activeTabID}/>
                    </div>
                </div>
            </div>
        </Container>
    )
}
