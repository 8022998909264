import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";

import Container from "../../components/Container";
import Card from "../../components/Сard";
import {isDebug, Dump, consoleLog} from "../../../components/development";
import LoadingWrapper from "../../components/LoadingWrapper";
import AlertSuccess from "../../components/AlertSuccess";
import ButtonSubmit from "../../components/ButtonSubmit";
import {useGetRequest, usePutRequest} from "../../../data/RequestData";
import AlertDanger from "../../components/AlertDanger";
import {useInput} from "../../../components/input-hook";
import ZoneInfo from "../../components/ZoneInfo";
import DivFormGroupRow from "../../components/form/DivFormGroupRow";
import DivError from "../../components/form/DivError";
import ZoneData from "../../../components/ZoneData";
import {mainGroups} from "../../../components/user_groups";
import {resolvePhpPostbackDomain, resolveServicePostbackDomain} from "../../../components/postback_url";

const PropushEditForm = ({user}) => {
    let {zone_id} = useParams();

    const [{data, isLoading, error}] = useGetRequest('ex-stat/zone/' + zone_id);
    const [{
        data: dataUpdate,
        isLoading: isLoadingUpdate,
        error: errorUpdate,
        dataErrors
    }, setRequestData] = usePutRequest('ex-stat/zone/' + zone_id);


    const inputTitle = useInput('');
    const inputPostbackUrl = useInput('');

    const [linkPhp, setLinkPhp] = useState('');
    const [linkService, setLinkService] = useState('');

    const setFromResponse = (result) => {
        consoleLog(result);

        inputTitle.setValue(result.title ?? '')
        inputPostbackUrl.setValue(result.postback_url ?? '')
    }

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            setFromResponse(data);

            setLinkPhp(
                "http://" + resolvePhpPostbackDomain(user) + "/" + data['origin_zone_id'] + ".php?"
                + "cnv_id={ymid}&payout={amount}&geo={geo}&data={var_3}"
                + (ZoneData.isPushDirection(data['direction_id']) ? "&scnt={sub_cnt}" : "")
                + (((data['origin_site_id'] + "") === "2180366") ? "&cnv_status={event_type}" : "")
            );
            setLinkService(
                "http://" + resolveServicePostbackDomain(user) + "/propushme?"
                + "zone_id=" + data['origin_zone_id'] + "&"
                + "cnv_id={ymid}&payout={amount}&geo={geo}&data={var_3}"
                + (ZoneData.isPushDirection(data['direction_id']) ? "&scnt={sub_cnt}" : "")
                + (((data['origin_site_id'] + "") === "2180366") ? "&cnv_status={event_type}" : "")
            );
        }
    }, [data]);


    useEffect(() => {
        if (dataUpdate) {
            setFromResponse(dataUpdate);
            window.scrollTo(0, 0);
        }
    }, [dataUpdate]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            title: inputTitle.value,
            postback_url: inputPostbackUrl.value,
        };
        setRequestData(data);
    }

    return (
        <Container title={"Edit propush info: " + data['origin_zone_id']}>
            <Card>
                <LoadingWrapper isLoaded={!isLoading} error={error}>
                    <form onSubmit={handleSubmit}>
                        {
                            errorUpdate && <AlertDanger messagePrefix={errorUpdate['name'] + ': '} message={errorUpdate['message']}/>
                        }
                        {
                            dataUpdate && <AlertSuccess message="Data updated successfully"/>
                        }

                        <DivFormGroupRow label="Title" labelAttributes={{htmlFor: "inputTitle"}}>
                            <input
                                type="text"
                                id="inputTitle"
                                placeholder="Title"
                                className={"form-control " + (inputTitle.error.length > 0 ? "is-invalid" : '')}
                                {...inputTitle.bind}
                            />
                            <DivError error={inputTitle.error}/>
                        </DivFormGroupRow>

                        <DivFormGroupRow label="Postback Url" labelAttributes={{htmlFor: "inputPostbackUrl"}}>
                            <input
                                type="text"
                                id="inputPostbackUrl"
                                placeholder="Postback url"
                                className={"form-control " + (inputPostbackUrl.error.length > 0 ? "is-invalid" : '')}
                                {...inputPostbackUrl.bind}
                            />
                            <DivError error={inputPostbackUrl.error}/>

                            <div>
                                <Link to="" onClick={e => {
                                    e.preventDefault();
                                    inputPostbackUrl.setValue(linkPhp)
                                }}>{"Apply php link: " + linkPhp}</Link>
                            </div>

                            {user.inGroups(mainGroups) && <div>
                                <Link to="" onClick={e => {
                                    e.preventDefault();
                                    inputPostbackUrl.setValue(linkService)
                                }}>{"Apply service link: " + linkService}</Link>
                            </div>}
                        </DivFormGroupRow>

                        <DivFormGroupRow>
                            <ButtonSubmit isLoading={false} text="Save changes"/>
                        </DivFormGroupRow>
                    </form>
                </LoadingWrapper>
            </Card>

            <ZoneInfo zone_id={zone_id} user={user}/>

            {isDebug() && <Card><Dump value={data}/></Card>}
        </Container>
    );
}

export default PropushEditForm;
