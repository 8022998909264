import React from "react";

import {round} from "../../../components/Math";

export default function Report1ListTableParams() {
    const keyCost = 'cost';
    const keyDate = 'date';
    const keyCampaignId = 'campaign_id';
    const keyCampaignOriginId = 'campaign_origin_id';
    const keyCampaignName = 'campaign_name';
    const keyNetworkId = 'network_id';
    const keyNetworkName = 'network_name';
    const keyAccountId = 'account_id';
    const keyAccountName = 'account_name';
    const keyManagerGroupID = 'manager_group_id';
    const keyManagerGroupName = 'manager_group_name';
    const keyManagerId = 'manager_id';
    const keyManagerName = 'manager_email';
    const keyDomain = 'domain';
    const keyDomainMain = 'main_domain';
    const keyPath = 'path';
    const keyPathL = 'l_path';
    const keyPathReal = 'real_path';

    const columns = React.useMemo(
        () => [
            {
                Header: 'Cost',
                accessor: keyCost,
                sortType: 'basic',
                Cell: ({row}) => <>{round(row.original[keyCost], 1)}</>,
            },
            {
                Header: 'Date',
                accessor: keyDate,
            },
            {
                Header: 'Campaign Id',
                accessor: keyCampaignId,
            },
            {
                Header: 'Campaign Origin Id',
                accessor: keyCampaignOriginId,
            },
            {
                Header: 'Campaign Name',
                accessor: keyCampaignName,
            },
            {
                Header: 'Network Id',
                accessor: keyNetworkId,
            },
            {
                Header: 'Network Name',
                accessor: keyNetworkName,
            },
            {
                Header: 'Traffic source ID',
                accessor: keyAccountId,
            },
            {
                Header: 'Traffic source',
                accessor: keyAccountName,
            },
            {
                Header: 'Manager Group ID',
                accessor: keyManagerGroupID,
            },
            {
                Header: 'Manager Group',
                accessor: keyManagerGroupName,
            },
            {
                Header: 'Manager Id',
                accessor: keyManagerId,
            },
            {
                Header: 'Manager Email',
                accessor: keyManagerName,
            },
            {
                Header: 'Full Domain',
                accessor: keyDomain,
            },
            {
                Header: 'Domain',
                accessor: keyDomainMain,
                Cell: ({row}) => {
                    if (row.original[keyDomainMain] === undefined) {
                        return '';
                    }
                    return row.original[keyDomainMain].length === 0 ? '' : <>{row.original[keyDomainMain]} ({row.original['domain_plan']})</>
                },
            },
            {
                Header: 'Path',
                accessor: keyPath,
            },
            {
                Header: 'Redirect path',
                accessor: keyPathL,
            },
            {
                Header: 'Real path',
                accessor: keyPathReal,
            },
        ],
        []
    );

    const initialState = {
        sortBy: [
            {
                id: keyCost,
                desc: true
            }
        ]
    }

    return [{
        keyCost,
        keyDate,
        keyCampaignId,
        keyCampaignOriginId,
        keyCampaignName,
        keyNetworkId,
        keyNetworkName,
        keyAccountId,
        keyAccountName,
        keyManagerGroupID,
        keyManagerGroupName,
        keyManagerId,
        keyManagerName,
        keyDomain,
        keyDomainMain,
        keyPath,
        keyPathL,
        keyPathReal,
        columns,
        initialState
    }];
}
