import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import Tab from "./../../Settings/Tab";
import ButtonSubmit from "../../../components/ButtonSubmit";
import AlertSuccess from "../../../components/AlertSuccess";
import {useInput} from "../../../../components/input-hook";
import {useCampaignRequest} from "../../../../data/campaign";
import {Dump, consoleLog} from "../../../../components/development";
import AlertDanger from "../../../components/AlertDanger";
import {useCampaignPlacementUpdate} from "../../../../data/campaign-placement";

export default function ExStatPlacementEditFormTabCustomSettings({tabActiveID, id, campaign_id}) {
    const [{data: profile, isLoading, error: dataError}] = useCampaignRequest(campaign_id);
    const [{
        data: updateProfile,
        setRequestData,
        isLoading: updateIsLoading,
        error: updateError,
        dataErrors
    }] = useCampaignPlacementUpdate(campaign_id, id);

    const {
        value: price,
        setValue: setPrice,
        error: errorPrice,
        setError: setErrorPrice,
        bind: bindPrice
    } = useInput('');

    const setFromResponse = (result) => {
        consoleLog('Result:', result);

        if (result.data && result.data.placement_rates && (id in result.data.placement_rates)) {
            setPrice(result.data.placement_rates[id]);
        }
    }

    useEffect(() => {
        setFromResponse(profile);
    }, [profile]);

    useEffect(() => {
        if (updateProfile) {
            setFromResponse(updateProfile);
        }
    }, [updateProfile]);

    useEffect(() => {
        let errors = {
            data_placement_price: '',
        };
        dataErrors.forEach(v => {
            if (v.field === 'data_placement_price') {
                errors.data_placement_price = v.message
            }
        });

        setErrorPrice(errors.data_placement_price);
    }, [dataErrors]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            data_placement_price: price,
        };
        setRequestData(data);
    }

    return (
        <Tab tabID="custom_settings" tabActiveID={tabActiveID} isLoading={isLoading} title="Custom Settings">
            <form onSubmit={handleSubmit}>
                {
                    updateProfile && <AlertSuccess message="Data updated successfully"/>
                }
                {
                    dataError && <><AlertDanger message={dataError.message}/><Dump value={dataError} /></>
                }
                {
                    updateError && <><AlertDanger message={updateError.message}/><Dump value={updateError} /></>
                }

                <div className="form-group">
                    <label htmlFor="inputPrice">Price</label>
                    <input type="text" id="inputPrice"
                           placeholder="Price for placement"
                           className={"form-control " + (errorPrice.length > 0 ? "is-invalid" : '')}
                           {...bindPrice}/>
                    <div className="invalid-feedback">{errorPrice}</div>
                </div>

                <div className="form-group">
                    <ButtonSubmit isLoading={updateIsLoading} text="Save changes"/>
                </div>

                <Dump value={profile} />
            </form>
        </Tab>
    )
}

ExStatPlacementEditFormTabCustomSettings.propTypes = {
    tabActiveID: PropTypes.string.isRequired,
}
