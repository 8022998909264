import React, {useEffect} from 'react'
import {useTable, useSortBy} from 'react-table'

import {ArrayUnique} from "../../../components/helpers";

// https://codesandbox.io/s/magical-yalow-g22yz
// https://stackoverflow.com/questions/62152372/changing-column-width-in-react-table

// Create a default prop getter
const defaultPropGetter = () => ({});

export default function Table({columns, data, initialState, hiddenColumns}) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
        setHiddenColumns,
        getHeaderProps = defaultPropGetter,
        getColumnProps = defaultPropGetter,
        getRowProps = defaultPropGetter,
        getCellProps = defaultPropGetter
    } = useTable(
        {
            columns,
            data,
            initialState,
        },
        useSortBy)

    useEffect(() => {
        setHiddenColumns(ArrayUnique(hiddenColumns))
    }, [hiddenColumns]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <table {...getTableProps()} className="table table-striped dataTable">
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps([
                            {
                                className: column.className,
                                style: column.style
                            },
                            getColumnProps(column),
                            getHeaderProps(column),
                            column.getSortByToggleProps()
                        ])}>
                            {column.render('Header')}
                            <span>
                            {column.isSorted
                                ? column.isSortedDesc
                                    ? ' 🔽'
                                    : ' 🔼'
                                : ''}
                            </span>
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
                prepareRow(row)
                return (
                    <tr {...row.getRowProps(getRowProps(row))}>
                        {row.cells.map(cell => {
                            return <td {...cell.getCellProps([
                                {
                                    className: cell.column.className,
                                    style: cell.column.style
                                },
                                getColumnProps(cell.column),
                                getCellProps(cell)
                            ])}>{cell.render('Cell')}</td>
                        })}
                    </tr>
                )
            })}
            </tbody>
            <tfoot>
            {footerGroups.map(group => (
                <tr {...group.getFooterGroupProps()} className="table-info">
                    {group.headers.map(column => (
                        <td {...column.getFooterProps([
                            {
                                className: column.className,
                                style: column.style
                            },
                        ])}>{column.render('Footer')}</td>
                    ))}
                </tr>
            ))}
            </tfoot>
        </table>
    )
}

Table.defaultProps = {
    columns: [],
    data: [],
    initialState: {},
    hiddenColumns: [],
}
