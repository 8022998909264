import React, {useEffect, useState} from 'react'
import Select from "react-select";

import {selectStyles} from "./styles";
import {consoleLogError} from "../../../components/development";

export default function SelectMulti({value, updateValue, options}) {
    const [selectedOption, setSelectedOption] = useState([]);

    const handleTypeSelect = e => {
        if (e === null) {
            setSelectedOption([]);
            updateValue([])
        } else {
            setSelectedOption(e);
            updateValue(e.map(i => i.value));
        }
    };

    useEffect(() => {
        const allowedValues = options.filter(o => value.indexOf(o.value) !== -1);
        if (allowedValues.length > 0) {
            setSelectedOption(allowedValues);
        }
        if (Array.isArray(value) && allowedValues.length !== value.length && options.length > 0) {
            consoleLogError('SelectMulti unknown value `' + value.filter(o => options.map(e => e.value).indexOf(o) === -1).join(', ') + '`; allowed: ' + options.map(e => e.value).join(', '))
        }
    }, [value, options]);

    return (
        options.length > 0 && <Select
            styles={selectStyles}
            options={options}
            isMulti={true}
            isClearable={true}
            value={selectedOption}
            onChange={handleTypeSelect}
            placeholder={<div>Select ...</div>} // or placeholder={"Select ..."}
        />
    )
}
