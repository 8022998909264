import React from 'react';

/**
 * @deprecated
 * @returns {JSX.Element}
 * @constructor
 */
function Support() {
    return (
        <div className="container-fluid p-0">
            <h1 className="h3 mb-3">Support</h1>

            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Vel fringilla est ullamcorper eget nulla facilisi. Diam in arcu cursus euismod quis
                viverra nibh cras. Enim diam vulputate ut pharetra sit. Amet est placerat in egestas erat imperdiet.
                Vitae justo eget magna fermentum iaculis eu non. Neque ornare aenean euismod elementum nisi quis
                eleifend. Porta non pulvinar neque laoreet. Accumsan in nisl nisi scelerisque eu ultrices vitae auctor
                eu. Eget duis at tellus at urna condimentum mattis. Eget magna fermentum iaculis eu.
            </p>
            <p>
                Adipiscing commodo elit at imperdiet dui. Netus et malesuada fames ac turpis egestas integer eget
                aliquet. Semper auctor neque vitae tempus. Aliquet enim tortor at auctor. Id donec ultrices tincidunt
                arcu non sodales neque sodales ut. Habitant morbi tristique senectus et netus et malesuada. Et netus et
                malesuada fames ac turpis egestas. Arcu dictum varius duis at consectetur lorem. Consectetur adipiscing
                elit pellentesque habitant morbi tristique. Nunc eget lorem dolor sed.
            </p>
            <p>
                Facilisis sed odio morbi quis commodo odio. Nunc eget lorem dolor sed viverra. Aliquet nec ullamcorper
                sit amet risus nullam. Velit dignissim sodales ut eu sem integer vitae. Nunc mi ipsum faucibus vitae
                aliquet nec ullamcorper sit. Nunc congue nisi vitae suscipit tellus mauris. Quis auctor elit sed
                vulputate mi sit amet mauris. Dolor sit amet consectetur adipiscing elit ut aliquam purus sit. In hac
                habitasse platea dictumst vestibulum rhoncus est pellentesque. Amet tellus cras adipiscing enim.
            </p>
            <p>
                Imperdiet massa tincidunt nunc pulvinar sapien et ligula ullamcorper malesuada. Proin fermentum leo vel
                orci porta non pulvinar neque. At tellus at urna condimentum mattis. Ridiculus mus mauris vitae
                ultricies. Semper risus in hendrerit gravida rutrum. Ut faucibus pulvinar elementum integer. Consectetur
                adipiscing elit pellentesque habitant morbi tristique senectus et. Sed enim ut sem viverra aliquet eget
                sit amet. Egestas maecenas pharetra convallis posuere morbi. Sed odio morbi quis commodo odio. Quam
                pellentesque nec nam aliquam sem et. Lectus urna duis convallis convallis tellus id. Morbi tristique
                senectus et netus. Ultrices in iaculis nunc sed augue.
            </p>
        </div>
    );
}

export default Support;
