import {mainGroups} from "./user_groups";

export const resolvePhpPostbackDomain = (user) => {
    return user.inGroups(mainGroups) ? "trackkingpro.com" : "usepostback.com";
}

export const resolveServicePostbackDomain = (user) => {
    return "p.a2track.com";
}

export const isServicePostbackUrl = (url, user) => {
    const u = url.replace(/(^\w+:|^)\/\//, '')
    return u.indexOf(resolveServicePostbackDomain(user), u) === 0;
}

export const postbackUrlIsGood = (url, user, zoneId) => {
    const u = url.replace(/(^\w+:|^)\/\//, '')
    if (u.indexOf(resolveServicePostbackDomain(user), u) === 0) {
        return true;
    }
    if (u.indexOf(resolvePhpPostbackDomain(user) + "/" + zoneId + ".php?", u) === 0) {
        return true;
    }
    return false;
}
