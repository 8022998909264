import {useState, useEffect} from 'react';

import ajax from "../../../../data/ajax";
import axios from "axios";

export default function DataNftCollection() {
    const [params, setParams] = useState({});
    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsError(false);
            setIsLoading(true);

            try {
                let url = window.trackerBaseUrl + "nft/collection?";

                if (Object.keys(params).length > 0) {
                    if ("state" in params && params.state !== '') {
                        url += "&state=" + params.state
                    }

                    const result = await axios.get(url, {headers: ajax.getHeaders()});

                    setData(result.data);
                }
            } catch (error) {
                setIsError(true);
            }

            setIsLoading(false);
        };

        fetchData();
    }, [params]);

    return [{ data, isLoading, isError }, setParams];
}
