import React, {useState, useEffect} from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import moment from 'moment';

import Container from "../../components/Container";
import Card from "../../components/Сard";
import Table from "../../components/table/Table";
import DateRangePicker from "../../components/DateRangePicker";
import TableParams from "./DataValidationSummaryListTableParams";
import {consoleLogResponse} from "../../../components/development";
import LoadingWrapper from "../../components/LoadingWrapper";
import ButtonFilter from "../../components/ButtonFilter";
import {round} from "../../../components/Math";
import {ObjectRemoveEmptyForUrl} from "../../../components/helpers";
import BackendRequest from "../../../data/BackendRequest";
import {backendRequestHandleFunction} from "./functions";

const DataValidationSummaryList = ({user}) => {
    const location = useLocation();
    const locationPathname = location.pathname;

    const navigate = useNavigate();

    const [dataGrouped, setDataGrouped] = useState([]);

    const [isLoadedFilterData, setIsLoadedFilterData] = useState(false);

    const tableParams = TableParams();

    const [calendarDates, setCalendarDates] = useState({
        start: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
    });

    const [filterButton, setFilterButton] = useState(0);

    const [dataRequestParams, setDataRequestParams] = useState({});
    const [{data, isLoading}, setUrlParams] = BackendRequest('data_validation', backendRequestHandleFunction);

    useEffect(() => {
        const fetchData = async () => {
            const promises = [
            ];
            Promise.all(promises).then(function (results) {
                consoleLogResponse(results);

                let params = {};

                const searchParamsObj = new URLSearchParams(location.search)
                const searchParams = Object.fromEntries(searchParamsObj);

                params.date_from = ("date_from" in searchParams) ? searchParams.date_from : calendarDates.start;
                params.date_to = ("date_to" in searchParams) ? searchParams.date_to : calendarDates.end;
                setCalendarDates({
                    start: params.date_from,
                    end: params.date_to,
                });

                params.latest = 1;
                setDataRequestParams(params)

                setIsLoadedFilterData(true);
            });
        };

        setIsLoadedFilterData(false);
        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoadedFilterData) {
            setDataRequestParams({
                date_from: calendarDates.start,
                date_to: calendarDates.end,
                latest: 1,
            })
        }
    }, [
        calendarDates,
        filterButton
    ]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Object.keys(dataRequestParams).length > 0) {
            navigate(locationPathname + '?' + (new URLSearchParams(ObjectRemoveEmptyForUrl(dataRequestParams))).toString());
            setUrlParams(dataRequestParams);
        }
    }, [dataRequestParams]);

    useEffect(() => {
        let dd = [];
        dd = data.map(e => {
            return {
                date: e.date,
                value_source: e.value_source,
                value_cabinet: e.value_cabinet,
                type: e.type,
                account_id: e.account_id,
            };
        })
        console.log('dd', dd)

        let d = {}

        data.forEach(e => {
            const date = e['date'];
            if (!d.hasOwnProperty(date)) {
                d[date] = {
                    date: date,
                    value_advertiser_source: 0,
                    value_advertiser_cabinet: 0,
                    value_publisher_source: 0,
                    value_publisher_cabinet: 0
                }
            }

            if (e['type'] === 'advertiser') {
                d[date]['value_advertiser_source'] = d[date]['value_advertiser_source'] + e['value_source'];
                d[date]['value_advertiser_cabinet'] = d[date]['value_advertiser_cabinet'] + e['value_cabinet'];
            }
            if (e['type'] === 'publisher') {
                d[date]['value_publisher_source'] += e['value_source'];
                d[date]['value_publisher_cabinet'] += e['value_cabinet'];
            }
        })

        d = Object.values(d).map(e => {
            e.value_advertiser_source = round(e.value_advertiser_source, 2);
            e.value_advertiser_cabinet = round(e.value_advertiser_cabinet, 2);
            e.advertiser_cabinet_percentage = e.value_advertiser_source > 0 ? round(100 * e.value_advertiser_cabinet / e.value_advertiser_source, 2) : '-';

            e.value_publisher_source = round(e.value_publisher_source, 2);
            e.value_publisher_cabinet = round(e.value_publisher_cabinet, 2);
            e.publisher_cabinet_percentage = e.value_publisher_source > 0 ? round(100 * e.value_publisher_cabinet / e.value_publisher_source, 2) : '-';

            e.roi_source = e.value_advertiser_source === 0 ? null : round((e.value_publisher_source - e.value_advertiser_source) / e.value_advertiser_source * 100, 2);

            return e;
        })
        console.log('data', data)
        console.log('d', d)

        setDataGrouped(d)
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container title={"Data validation Summary"}>
            <LoadingWrapper isLoaded={isLoadedFilterData}>
                <Card>
                    <div className="row">
                        <div className="col-md-3">
                            <b>Date</b>
                            <DateRangePicker
                                dates={calendarDates}
                                updateDates={v => setCalendarDates(v)}
                            />
                        </div>
                        <div className="col-md-9">
                            &nbsp;
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <ButtonFilter setFilterButton={v => setFilterButton(v)}/>
                        </div>
                        <div className="col-md-6">&nbsp;</div>
                        <div className="col-md-3 text-right">&nbsp;</div>
                    </div>
                </Card>
            </LoadingWrapper>

            <LoadingWrapper isLoaded={isLoadedFilterData && !isLoading}>
                <Card>
                    <Table
                        data={dataGrouped}
                        {...tableParams}
                        hiddenColumns={[]}
                    />
                </Card>
            </LoadingWrapper>
        </Container>
    );
}

export default DataValidationSummaryList;
