import {useState, useEffect} from 'react';

import ajax from "./ajax";
import axios from "axios";

/**
 * @deprecated see BackendRequest
 */
export default function DataLss() {
    const [params, setParams] = useState({});
    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsError(false);
            setIsLoading(true);

            try {
                let url = window.trackerBaseUrl + "stat/lss?";

                if ("group" in params) {
                    url += "group_by=" + params.group

                    if ("date_from" in params) {
                        url += "&date_from=" + params.date_from
                    }
                    if ("date_to" in params) {
                        url += "&date_to=" + params.date_to
                    }
                    if ("country" in params) {
                        url += "&country=" + params.country
                    }
                    if ("lss_zone" in params) {
                        url += "&lss_zone=" + params.lss_zone
                    }

                    const result = await axios.get(url, ajax.getBaseConfig());

                    // setIsLoaded(true);
                    setData(result.data);
                }
            } catch (error) {
                setIsError(true);
            }

            setIsLoading(false);
        };

        fetchData();
    }, [params]);

    return [{ data, isLoading, isError }, setParams];
}
