import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {Dump} from "../../../components/development";
import Card from "../../components/Сard";
import SelectMulti from "../../components/select/SelectMulti";
import Table from "../../components/table/Table";
import Container from "../../components/Container";
import TableParams from "./ReportBalancesListTableParams";
import {SelectDataFormatter} from "../../../components/select_data";
import Filter from "../../../components/filter";
import {useSelect} from "../../../components/input-hook";

const ReportBalancesList = ({user}) => {
    const location = useLocation();
    const locationPathname = location.pathname;

    const navigate = useNavigate();

    const [data, setData] = useState([]);

    const [isLoadedFilterData, setIsLoadedFilterData] = useState(false);

    const [tableParams] = TableParams(user);

    const tableHiddenColumns = [];
    if (!user.allowManagers()) {
        tableHiddenColumns.push(tableParams.keyManagerName);
    }

    const inputFilterNetwork = useSelect([]);
    const inputFilterManager = useSelect([]);

    useEffect(() => {
        if (!isLoadedFilterData) {
            let filters = [
                {
                    key: 'networks',
                    data: SelectDataFormatter.networks(user.getTrafficSourceNetworks()),
                    input: inputFilterNetwork,
                },
            ];
            if (user.allowManagers()) {
                filters.push({
                    key: 'managers',
                    data: SelectDataFormatter.managers(user.getManagers()),
                    input: inputFilterManager,
                });
            }

            Filter.collectParamsForFilter(location, filters)

            setIsLoadedFilterData(true);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoadedFilterData) {
            const d = []
            let ts = user.getTrafficSourceAccounts();

            if (inputFilterNetwork.value.length > 0) {
                ts = ts.filter(a => inputFilterNetwork.value.includes(a.network_id + ''))
            }

            if (inputFilterManager.value.length > 0) {
                ts = ts.filter(a => inputFilterManager.value.includes(a.manager_id + ''))
            }

            ts.forEach(a => {
                let managerName, networkName;
                networkName = a.network_id
                managerName = a.manager_id;
                const m = user.getManagerById(a.manager_id)
                if (m !== undefined) {
                    managerName = (m.first_name.length > 0 ? m.first_name + " / " : "") + m.email
                }
                const n = user.getTrafficSourceNetworkById(a.network_id)
                if (n !== undefined) {
                    networkName = n.name;
                }
                d.push({
                    id: a.id,
                    name: a.name,
                    network_id: a.network_id,
                    network_name: networkName,
                    manager_id: a.manager_id,
                    manager_name: managerName,
                    balance: Number.parseFloat(a.balance),
                })
            })
            setData(d);

            console.log(d)
        }
    }, [
        isLoadedFilterData,
        inputFilterNetwork.value,
        inputFilterManager.value,
    ]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container title={"Report traffic source balances"}>
            <Card>
                <div className="row">
                    <div className="col-md-2">
                        <b>Network</b>
                        <SelectMulti {...inputFilterNetwork.bind}/>
                    </div>
                    {user.allowManagers() && <div className="col-md-2">
                        <b>Select manager</b>
                        <SelectMulti {...inputFilterManager.bind}/>
                    </div>}
                </div>
            </Card>

            <Card>
                <Table
                    data={data}
                    {...tableParams}
                    hiddenColumns={tableHiddenColumns}
                />
            </Card>
        </Container>
    );
}

export default ReportBalancesList;
