import React, {useEffect, useState} from 'react'
import Select from "react-select";

import {selectStyles} from "./styles";

/**
 * @TODO remove
 * @deprecated
 */
export default function SelectCountry2({value, updateValue, options, defaultLabel}) {
    const cv = {value: value, label: defaultLabel};
    let hasEmpty = false;
    options.forEach((e) => {
        if (e.value === value) {
            cv.label = e.label;
        }
        if (e.value === "") {
            hasEmpty = true;
        }
    })
    if (!hasEmpty && cv.value !== "") {
        options.unshift({value : "", label : defaultLabel});
    }

    const [currentVal, setCurrentVal] = useState(cv);

    useEffect(() => {
        updateValue(currentVal.value);
    }, [currentVal]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Select
            styles={selectStyles}
            name={"filter_country"}
            options={options}
            // isClearable={true}
            value={currentVal}
            onChange={e => setCurrentVal(e)}
        />
    )
}
