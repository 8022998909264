/**
 * @param range
 * @returns {string[]|*[]}
 */
export const strToArray = function (range) {
    if (typeof range === 'undefined') {
        return [];
    }

    if (!Array.isArray(range)) {
        range = range + '';
        range = range.trim();
        if (range.length === 0) {
            return [];
        }
        range = range.split(',').map(e => e.trim())
    }

    return range;
}

export const filterAllowedValues = function (range, availableValues) {
    return ArrayIntersection(strToArray(range), availableValues);
}

// https://stackoverflow.com/questions/1187518/how-to-get-the-difference-between-two-arrays-in-javascript
export const ArrayIntersection = function (array1, array2) {
    return array1.filter(v => array2.includes(v));
    // return array1.filter(v => array2.indexOf(v) !== -1);
}

export const ArrayUnique = function (array) {
    return array.filter((value, index, origin) => origin.indexOf(value) === index);
}

export const ArrayRemoveValues = function (array, ...args) {
    for (const arg of args) {
        let i = array.indexOf(arg)
        if (i >= 0) {
            array.splice(array.indexOf(arg), 1);
        }
    }
    return array;
}

export const ObjectRemoveEmptyForUrl = function (obj) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => {
        if (v === null || v === '') {
            return false
        }

        return !Array.isArray(v) || v.length > 0
    }));
}
