import React from 'react';

function Payments() {
    return (
        <div className="container-fluid p-0">

            <h1 className="h3 mb-3">@TODO Finance</h1>

            <div className="row">
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col mt-0">
                                    <h5 className="card-title text-warning">Current Balance</h5>
                                </div>

                            </div>

                            <h1 className="display-5 mt-1 mb-3 text-warning">$21.300</h1>

                            <div className="mb-0">
                                <span className="text-muted">Your unpaid earnings (Ready to Pay + Hold)</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col mt-0">
                                    <h5 className="card-title text-success">Ready to Pay</h5>
                                </div>
                            </div>

                            <h1 className="display-5 mt-1 mb-3 text-success">$4.300</h1>

                            <div className="mb-0">
                                <span className="text-muted">Will be paid on the next payments date</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col mt-0">
                                    <h5 className="card-title text-info">Total Payments</h5>
                                </div>
                            </div>

                            <h1 className="display-5 mt-1 mb-3 text-info">$54.300</h1>

                            <div className="mb-0">
                                <span className="text-muted">Your total earning for the all time</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tab">
                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" href="#tab-1" data-toggle="tab"  role="tab">Payments</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#tab-2" data-toggle="tab" role="tab">Earnings</a>
                    </li>
                </ul>

                <div className="tab-content">
                    <div className="tab-pane active" id="tab-1" role="tabpanel">
                        <table id="datatables-payments-1" className="table table-striped"
                               // style="width:100%"
                        >
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Transaction ID</th>
                                <th>Payment Type</th>
                                <th>Amount</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>30.07.2020</td>
                                <td>10001</td>
                                <td>Webmoney</td>
                                <td>$2 400</td>
                                <td><span className="badge badge-success">Released</span></td>
                            </tr>
                            <tr>
                                <td>20.07.2020</td>
                                <td>10001</td>
                                <td>Webmoney</td>
                                <td>$2 400</td>
                                <td><span className="badge badge-danger">Declined</span></td>
                            </tr>
                            <tr>
                                <td>15.07.2020</td>
                                <td>10001</td>
                                <td>Webmoney</td>
                                <td>$2 400</td>
                                <td><span className="badge badge-warning">Hold</span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="tab-pane" id="tab-2" role="tabpanel">
                        <table id="datatables-payments-2" className="table table-striped"
                               // style="width:100%"
                        >
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Corrections</th>
                                <th>Total Earning</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>30.07.2020</td>
                                <td>- $400</td>
                                <td>$2 400</td>
                                <td><span className="badge badge-success">Released</span></td>
                            </tr>
                            <tr>
                                <td>29.07.2020</td>
                                <td></td>
                                <td>$1 400</td>
                                <td><span className="badge badge-danger">Declined</span></td>
                            </tr>
                            <tr>
                                <td>28.07.2020</td>
                                <td>+ $200</td>
                                <td>$1 400</td>
                                <td><span className="badge badge-warning">Hold</span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Payments;
