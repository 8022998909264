import React from 'react';
import PropTypes from 'prop-types';

export default function ButtonFilter({setFilterButton}) {
    return <button type="submit" className="btn btn-primary border" onClick={e => setFilterButton(Date.now())}>Show Results</button>
}

ButtonFilter.propTypes = {
    isLoading: PropTypes.bool,
    className: PropTypes.string,
    text: PropTypes.string,
    disabled: PropTypes.bool,
}

ButtonFilter.defaultProps = {
    isLoading: false,
    className: "btn-primary",
    text: "Submit",
    disabled: false,
}
