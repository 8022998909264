import React from "react";

import ListData from "../../../components/ListData";
import DomainData from "../../../components/DomainData";

export default function DataValidationListTableParams() {
    const keyDate = 'domain';

    const columns = React.useMemo(
        () => {
            return [
                {
                    Header: 'Name',
                    accessor: keyDate,
                },
                {
                    Header: 'Plan',
                    accessor: 'plan',
                    Cell: ({row}) => DomainData.getPlan(row.original['plan']),
                },
                {
                    Header: 'Type',
                    accessor: 'type_landing',
                    Cell: ({row}) => DomainData.getType(row.original['type_landing']),
                },
                {
                    Header: 'To',
                    accessor: 'redirect_to_domain',
                },
                {
                    Header: 'Status',
                    accessor: 'status',
                    Cell: ({row}) => DomainData.getStatus(row.original['status']),
                },
                {
                    Header: 'Allow Multi',
                    accessor: 'allow_multi',
                    Cell: ({row}) => ListData.boolName(row.original['allow_multi']),
                },
                {
                    Header: 'GSB',
                    accessor: 'gsb',
                    Cell: ({row}) => ListData.boolName(row.original['gsb']),
                },
                {
                    Header: 'Description',
                    accessor: 'description',
                },
            ];
        },
        []
    );

    const initialState = {
        sortBy: [
            {
                id: keyDate,
                desc: true
            }
        ]
    }

    return {
        columns, initialState
    };
}
