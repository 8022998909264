import React from 'react';
import {Line} from 'react-chartjs-2';

import ajax from "../../data/ajax"
import Loading from "../components/Loading";
import {consoleLog} from "../../components/development";

class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            yesterday: {
                conversions: 0,
                visitors: 0,
                conversionRate: 0,
                earnings: 0,
            },
            today: {
                conversions: 0,
                visitors: 0,
                conversionRate: 0,
                earnings: 0,
            },
            chart: {
                axe: ["5/07", "6/07"],
                values: [4590, 5690],
            },
        }
    }

    componentDidMount() {
        const url = window.trackerBaseUrl + "stat/trends?date_group=2&date_range=6";

        const dateFormat = function (d) {
            var mm = d.getMonth() + 1; // getMonth() is zero-based
            var dd = d.getDate();

            return [d.getFullYear(),
                (mm > 9 ? '' : '0') + mm,
                (dd > 9 ? '' : '0') + dd
            ].join('-') + " 00:00:00";
        };

        fetch(url, {
            method: 'GET',
            headers: ajax.getHeaders(),
        })
            .then(res => res.json())
            .then(
                result => {
                    let currentDate = new Date();
                    const today = dateFormat(currentDate);

                    currentDate.setTime(currentDate.getTime() - 24 * 60 * 60 * 1000);
                    const yesterday = dateFormat(currentDate);

                    result.forEach(value => {
                        if (value.date_interval === today) {
                            this.setState({
                                today: {
                                    conversions: value.leads,
                                    visitors: value.clicks,
                                    conversionRate: value.leads / value.clicks,
                                    earnings: value.revenue,
                                },
                            })
                        }
                        if (value.date_interval === yesterday) {
                            this.setState({
                                yesterday: {
                                    conversions: value.leads,
                                    visitors: value.clicks,
                                    conversionRate: value.leads / value.clicks,
                                    earnings: value.revenue,
                                },
                            })
                        }
                    });

                    let axe = [];
                    let values = [];

                    const days = 14;
                    currentDate = new Date();
                    currentDate.setTime(currentDate.getTime() - days * 24 * 60 * 60 * 1000);
                    for (var i = 0; i < days; i++) {
                        currentDate.setTime(currentDate.getTime() + 24 * 60 * 60 * 1000);
                        const cf = dateFormat(currentDate);

                        let v = 0
                        result.forEach(value => {
                            if (value.date_interval === cf) {
                                v = value.revenue
                            }
                        });
                        values.push(v)

                        const mm = currentDate.getMonth() + 1; // getMonth() is zero-based
                        const dd = currentDate.getDate();
                        axe.push([
                            (dd > 9 ? '' : '0') + dd,
                            (mm > 9 ? '' : '0') + mm
                        ].join('/'))
                    }

                    this.setState({
                        isLoaded: true,
                        chart: {
                            axe: axe,
                            values: values,
                        }
                    })
                },
                error => {
                    this.setState({
                        isLoaded: true,
                        error: error,
                    })
                }
            )
    }

    render() {
        const {error, isLoaded, yesterday, today} = this.state
        if (error) {
            return <div>Ошибка: {error.message}</div>
        } else if (!isLoaded) {
            return <Loading/>
        } else {
            return (
                <div className="container-fluid p-0">
                    <h1 className="h3 mb-3">Dashboard</h1>

                    <div className="row">
                        <div className="col-xl-6 col-xxl-5 d-flex">
                            <div className="w-100">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <Card
                                            title="Conversions"
                                            value={today.conversions}
                                            prevValue={yesterday.conversions}
                                        />
                                        <Card
                                            title="Visitors"
                                            value={today.visitors}
                                            prevValue={yesterday.visitors}
                                        />

                                    </div>
                                    <div className="col-sm-6">
                                        <Card
                                            title="Conv Rate"
                                            value={today.conversionRate}
                                            prevValue={yesterday.conversionRate}
                                            postfix="%"
                                        />
                                        <Card
                                            title="Earnings"
                                            value={today.earnings}
                                            prevValue={yesterday.earnings}
                                            prefix="$"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-xxl-7">
                            <div className="card flex-fill w-100">
                                <div className="card-header">
                                    <h5 className="card-title mb-0">Last 14 Days Trends</h5>
                                </div>
                                <div className="card-body py-3">
                                    <div className="chart chart-sm">
                                        <Chart axe={this.state.chart.axe} values={this.state.chart.values}/>
                                        {/*<canvas id="chartjs-dashboard-line"></canvas>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h1 className="h3 mb-3">Latest News</h1>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title mb-0">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit ...
                                    </h5>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Amet massa vitae tortor condimentum
                                        lacinia. Interdum consectetur libero id faucibus nisl. Eget dolor morbi non arcu
                                        risus quis varius quam quisque. Eleifend donec pretium vulputate sapien nec
                                        sagittis aliquam malesuada bibendum. Augue eget arcu dictum varius duis. Leo vel
                                        orci porta non pulvinar. Quis ipsum suspendisse ultrices gravida dictum fusce
                                        ut. Sed blandit libero volutpat sed cras ornare. Placerat duis ultricies lacus
                                        sed. Aliquam eleifend mi in nulla posuere sollicitudin aliquam. Commodo quis
                                        imperdiet massa tincidunt nunc pulvinar sapien. Ut ornare lectus sit amet est
                                        placerat in egestas erat. Dis parturient montes nascetur ridiculus mus mauris.
                                        Purus viverra accumsan in nisl nisi scelerisque eu ultrices vitae. Consectetur
                                        adipiscing elit ut aliquam purus sit. Nisl nisi scelerisque eu ultrices vitae.
                                        Pellentesque habitant morbi tristique senectus et. Praesent elementum facilisis
                                        leo vel fringilla.
                                    </p>
                                </div>
                                <div className="card-body">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

function Card(props) {
    const labelClass = props.value > props.prevValue ? "text-success" : "text-danger"

    const percent = 100 * props.value / props.prevValue - 100

    return <div className="card">
        <div className="card-body">
            <h5 className="card-title mb-4">{props.title}</h5>
            <h1 className="display-5 mt-1 mb-3">{props.prefix}{props.value}{props.postfix}</h1>
            {props.prevValue > 0 &&
            <div className="mb-1">
                    <span className={labelClass}>
                        <i className="mdi mdi-arrow-bottom-right"></i> {percent + "%"}
                    </span>
                <span className="text-muted"> since yesterday</span>
            </div>
            }
        </div>
    </div>;
}

class Chart extends React.Component {
    render() {
        consoleLog("Chart props");
        consoleLog(this.props);

        const data = (canvas) => {
            const ctx = canvas.getContext("2d")
            const gradient = ctx.createLinearGradient(0, 0, 0, 225);
            gradient.addColorStop(0, 'rgba(215, 227, 244, 1)');
            gradient.addColorStop(1, 'rgba(215, 227, 244, 0)');

            return {
                labels: this.props.axe,
                datasets: [{
                    label: "Revenue ($)",
                    fill: true,
                    backgroundColor: gradient,
                    borderColor: "#3B7DDD", //window.theme.primary,
                    data: this.props.values,
                }]
            }
        }

        const options = {
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            tooltips: {
                intersect: false
            },
            hover: {
                intersect: true
            },
            plugins: {
                filler: {
                    propagate: false
                }
            },
            scales: {
                xAxes: [{
                    reverse: true,
                    gridLines: {
                        color: "rgba(0,0,0,0.0)"
                    }
                }],
                yAxes: [{
                    ticks: {
                        stepSize: 1000
                    },
                    display: true,
                    borderDash: [3, 3],
                    gridLines: {
                        color: "rgba(0,0,0,0.0)"
                    }
                }]
            }
        };

        return (<Line data={data} options={options}/>)
    }
}

export default Dashboard;
